<template>
  <div>
    <div class="collection-page-comment">
      You don't have any Qualifying NFTs just yet
    </div>
    <div class="collection-page-refresh-buttons">
      <a
        class="collection-page-refresh-opensea"
        target="_blank"
        href="https://opensea.io/collection/bullsandapes-genesis"
      >Check Opensea offers</a>
    </div>
  </div>
</template>
<script>import { defineComponent } from "vue-demi";

export default defineComponent({})
</script>