import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useMethBankStore } from "@/store/meth-bank";
import { useWeb3Store } from "@/store/web3";
import { useUtilitiesInteractionsContract } from "@/web3/contracts";

export const claimMethBankMethOffChain = async () => {
  try {
    const wallet = useWeb3Store().collectionWallet;
    const { amount, signature, timeOut } = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/wallet/bank-withdraw",
      method: "POST",
      data: {
        wallet, // wallet from which bank the withdraw is
        sender: useWeb3Store().walletAddress, // wallet sending the transaction (can be source or controller)
        network: Config.network.name,
      },
    });
    const contract = useUtilitiesInteractionsContract(true);
    await useToastTransaction(
      contract.methods.withdrawFromBank(amount, timeOut, signature),
      {
        text: "Claiming Meth",
        successText: `Congrats! Your wallet now has +${amount} METH`,
      }
    );
    useMethBankStore().fetchMethBank(true)
    useWeb3Store().update()
    return true;
  } catch (err) {
    captureError(err);
  }
};
