<template>
    <div class="collection-card-actions">
        <a
            v-for="(action) in actions"
            :key="action.name"
            v-tooltip="action.tooltip || false"
            class="collection-card-action"
            :class="{ disabled: action.disabled }"
            href="javascript:void(0)"
            @click.prevent="onActionClick(action)"
        >{{ action.name }}</a>
        <slot v-if="!isSelectionMode" />
    </div>
</template>
<script>
import { computed, defineComponent } from "vue-demi";

export default defineComponent({
    props: {
        items: {
            type: Array,
            default: () => []
        },
        isOffChain: Boolean,
        isSelectionMode: Boolean,
        showSell: Boolean 
    },
    setup(props, { emit }) {
        const actions = computed(() => {
            if (props.isSelectionMode) return [
                {
                    name: "Select",
                    event: "select"
                }
            ]
            const items = [...props.items]
            if (props.isOffChain) {
                items.push({
                    name: "Mint",
                    event: "mint"
                })
            }
            if(props.showSell) {
                items.push({
                    name: "Sell",
                    event: 'sell'
                })
            }
            return items
        })
        const onActionClick = (action) => {
            if (action.disabled) return
            emit(action.event)
        }

        return { actions, onActionClick }
    },
})
</script>
<style lang="scss">
.collection-card-actions {
    .collection-card-action {
        display: block;
        padding: 10px 15px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        //border-top: 1px solid rgba(255, 255, 255, 0.3);
        color: #fff;
        text-decoration: none;
        transition: 0.2s;
        margin: 10px 0;
        border-radius: 5px;
        background-color: var(--gray-light);
        text-transform: uppercase;
        text-align: center;
        &:hover {
            background-color: transparent;
        }
        &.disabled {
            cursor: default;
            opacity: 0.5;
            background-color: var(--gray-light);
        }
    }
}
</style>