<template>
  <div
    class="collection-card-level"
    :class="'level-' + level"
  >
    <div class="collection-card-level-level-row">
      <div class="collection-card-level-level">
        LEVEL {{ level }}
      </div>
      <div
        v-if="showSelected"
        class="collection-card-level-selected"
      >
        <span v-if="isSelected">
          <ICheckMark width="16" />
        </span>
        <span v-else>
          <ICross width="12" />
        </span>
      </div>
      <div
        class="collection-card-level-harvested"
        :class="{ harvested: isHarvested }"
      >
        <template v-if="isHarvested">
          <ICheckMark width="16" />Harvested Today
        </template>
        <template v-else>
          <ICross width="12" />Not Harvested Today
        </template>
      </div>
    </div>
    <!-- <div class="collection-card-level-generate-amount">
      <img src="../../assets/img/meth.png" width="14" /><span>
        Generating
        <b>{{ harvestPerDay }}</b> Harvestable METH/day
      </span>
    </div>-->
    <div class="collection-card-level-days-left">
      <IUpgrade width="20" />
      <CollectionCardLevelProgress
        v-if="willHaveNewLevel"
        :days-left="upgradeDaysLeft"
        :level="level"
      />
      <!-- <b>{{ upgradeDaysLeft }}</b>
        consecutive harvest
        {{ upgradeDaysLeft > 1 ? "days" : "day" }} away from
      <b>Level {{ level + 1 }}</b>-->
      <span v-else>
        <b>Max Level</b>
      </span>
    </div>
    <!-- <div class="collection-card-level-days-max" v-else>
      You have reached maximum level
    </div>-->
  </div>
</template>

<script>
import ICheckMark from "@/assets/icons/checkmark.svg?inline";
import ICross from "@/assets/icons/cross.svg?inline";
import IUpgrade from "@/assets/icons/upgrade.svg?inline";
import CollectionCardLevelProgress from "./CollectionCardLevelProgress.vue";
export default {
  components: {
    CollectionCardLevelProgress,
    ICross,
    ICheckMark,
    IUpgrade,
  },
  props: {
    harvestInfo: Object,
    isSelected: Boolean,
    showSelected: Boolean
  },
  computed: {
    level() {
      return this.harvestInfo.level;
    },
    harvestPerDay() {
      return this.harvestInfo.harvestPerDay;
    },
    isHarvested() {
      return this.harvestInfo.isHarvestedToday;
    },
    upgradeDaysLeft() {
      return this.harvestInfo.daysLeft;
    },
    willHaveNewLevel() {
      return this.level < 3;
    },
  },
};
</script>

<style lang="scss">
.collection-card-level {
  margin-bottom: 10px;
  position: relative;
  transition: 0.3s;

  &-level-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &-level {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  &-generate-amount {
    text-align: left;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      margin-right: 5px;
      flex-shrink: 0;
    }
    b {
      font-weight: bold;
    }
  }
  &-harvested {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-self: center;
    border: 1px solid;
    font-weight: bold;
    border-color: rgba(234, 78, 78, 0.6);
    color: #ea4e4e;

    svg {
      margin-right: 5px;
      padding: 2px;
      border-radius: 50%;
      // border: 1px solid rgb(255, 255, 255);
    }
    &.harvested {
      border-color: rgba(81, 183, 81, 0.6);
      color: #51b751;
      svg {
        padding: 0;
      }
    }
  }
  &-days-left {
    color: var(--primary);
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 14px;
    svg {
      flex-shrink: 0;
      margin-right: 5px;
    }
    b {
      font-weight: bold;
    }
  }
  &-progress {
    height: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    width: 100%;
    position: relative;
    &-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      &:hover + .collection-card-level-progress-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
    &-outer-wrapper {
      width: 100%;
      position: relative;
    }
    &-markers {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      span {
        flex: 1;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        &:last-child {
          border: none;
        }
      }
    }
    &-active {
      // position: relative;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      clip-path: inset(0px var(--progress) 0 0);
      margin-top: -1px;
      margin-left: -1px;
      background-color: #00da0f;
      border-radius: 10px;
      // border-bottom-left-radius: 10px;
      z-index: 10;
    }
    &-level {
      white-space: nowrap;
      font-weight: bold;
      font-size: 14px;
      margin-left: 3px;
    }
    &-info-icon {
      color: rgba(255, 255, 255, 0.3);
      margin-left: 4px;
    }
    &-tooltip {
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      transform: translateY(-100%);
      padding: 8px;
      border-radius: 5px;
      background-color: var(--bg);
      color: #fff;
      font-size: 12px;
      opacity: 0;
      border: 1px solid rgba(255, 255, 255, 0.2);
      visibility: hidden;
      transition: 0.3s;
    }
  }
  &.level-1 {
    .collection-card-level-level {
      color: var(--level-1);
    }
  }
  &.level-2 {
    .collection-card-level-level {
      color: var(--level-2);
    }
  }
  &.level-3 {
    .collection-card-level-level {
      color: var(--level-3);
    }
  }
}
</style>