<template>
  <div class="collection-resurrect-list">
    <div class="collection-resurrect-list-search">
      <!-- <label class="collection-resurrect-list-search-label">Search By Id</label> -->
      <input
        v-model="searchIdModel"
        type="text"
        class="collection-resurrect-list-search-input"
        placeholder="Search by ID"
      >
      <ISearch width="14" />
    </div>
    <div class="collection-resurrect-list-items">
      <div
        v-for="item in items"
        :key="item.id"
        class="collection-resurrect-list-item"
      >
        <CollectionResurrectCard
          :token="item"
          :active="activeItem.id == item.id"
          @click="selectItem(item)"
        />
      </div>
    </div>
    <div class="collection-resurrect-list-pagination">
      <APagination
        v-if="!searchIdModel"
        v-model:page="pageModel"
        :page-size="pageSize"
        :total-items="totalItems"
      />
    </div>
  </div>
</template>
<script>
import CollectionResurrectCard from "./CollectionResurrectTokenCard.vue";
import APagination from "../../APagination.vue";
import ISearch from "../../../assets/icons/search.svg?inline";
import { useBapApi } from "@/api/useBapApi";
export default {
  components: { CollectionResurrectCard, APagination, ISearch },
  props: {
    activeItem: {
      type: Object,
      default: () => ({})
    },
    totalItems: Number,
    items: {
      type: Array,
      default: () => [],
    },
    page: Number,
    pageSize: Number,
    searchId: String
  },
  emits: ["update:activeItem", 'update:page', 'update:searchId'],
  data() {
    return {
    };
  },
  computed: {
    pageModel: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    searchIdModel: {
      get() {
        return this.searchId
      },
      set(val) {
        this.$emit('update:searchId', val)
      }
    }
  },
  
  
  methods: {
    
   
    selectItem(item) {
      this.$emit("update:activeItem", item);
    },
  },
};
</script>
