// @DEPRECATED
// deprecated since no more incubators to mint

import { useBapApi } from "@/api/useBapApi";
import { useToast } from "@/composables/useToast";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { useOrchestratorContract } from "@/web3/contracts";



export const breedBulls = async function (bulls) {
  const web3Store = useWeb3Store();
  const walletAddress = web3Store.walletAddress;
  const contract = useOrchestratorContract(true);

  const { success, result } = await useBapApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/mint/sign/incubator",
    method: "POST",
    data: {
      wallet: walletAddress,
      bull1: bulls[0].id,
      bull2: bulls[1].id,
    },
  });
  if(!success) {
    useToast().error({text: "Something went wrong, please try again"})
    return
  }

  const signature = result.signature;

  const response = await useToastTransaction(
    contract.methods.buyIncubator(signature, bulls[0].id, bulls[1].id),
    { text: "Generating Incubator" }
  );
  useWalletCollectionStore().fetchCollection(true)
  useWeb3Store().update()
  return response;
};
