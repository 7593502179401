import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import {  useAssetsInteractionsContract } from "@/web3/contracts";
import { teenGeneratedAnimation } from "./teenGeneratedAnimation";

export const generateTeen = async function () {
  return;
  const contract = useAssetsInteractionsContract(true);

  const claimResponse = await useToastTransaction(
    contract.methods.generateTeenBull(),
    { text: "Generate Teen" }
  );
  console.log(claimResponse);
  const teenId = parseInt(claimResponse.events[3].raw.topics[3]);

  await teenGeneratedAnimation(
    teenId,
    claimResponse.transactionHash,
    "Teen"
  );
  useWalletCollectionStore().fetchCollection(true);
  useWeb3Store().update();
  return teenId;
};
