<template>
  <div
    v-if="userConnected"
    class="collection-topbar"
  >
    <MethInfo />
    <HarvestMeth />
    <WalletInfo />
  </div>
</template>

<script>
import WalletInfo from "./WalletInfo.vue";
import MethInfo from "./MethInfo.vue";
import HarvestMeth from "./HarvestMeth.vue";
import { useWeb3Store } from "@/store/web3";

export default {

  components: { WalletInfo, MethInfo, HarvestMeth },
  data() {
    return {};
  },
  computed: {
    userConnected() {
      return useWeb3Store().isConnected
    },

  },
};
</script>
