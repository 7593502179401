import { useHRApi } from "@/api/useHRApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";
import { ref } from "vue";
import { Config } from "@/config";
import { useMethBankStore } from "@/store/meth-bank";
import { getHarvestTokens } from "./get-harvest-tokens";
import { buildTokensToHarvest } from "./build-tokens-to-harvest";
import { useHarvestLimitStore } from "@/store/harvest-limit";
import { captureError } from "@/helpers/captureError";
export const harvestMeth = async () => {
  try {
    const web3Store = useWeb3Store();
    const walletAddress = web3Store.collectionWallet;
    const { botbTokens, partnersTokens, allTeenIds, teenIds, methMakerIds } =
      await buildTokensToHarvest();
    const { open: openModal } = useModal();
    const modalProps = ref({
      totalAssets: 0,
      pendingHarvests: 0,
      isHarvested: false,
    });
    console.log(partnersTokens);
    useHarvestLimitStore().saveCollectionSnapshot();
    openModal({
      name: ModalName.HarvestModal,
      closeOnOverlay: false,
      props: modalProps,
    });
   
    const harvestData = await useHRApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/v1/bap/harvest-meth",
      method: "POST",
      data: {
        wallet_address: walletAddress,
        meth_maker_ids: methMakerIds,
        teen_ids: teenIds,
        all_teen_ids: allTeenIds.filter((id) => id !== "0"),
        network: Config.network.name,
        partner_tokens:
          Config.network.name === "mainnet"
            ? { ...botbTokens, ...partnersTokens }
            : undefined,
        version: Config.mode !== "production" ? 2 : undefined,
      },
    });
    const totalAssets = harvestData.harvest_ids.length;

    const poll = async () => {
      try {
        const data = await useHRApi().exec({
          url: `/v1/bap/harvest-status`,
          params: {
            wallet_address: walletAddress,
          },
        });
        const pendingHarvests = data.pending_harvests;
        console.log("polling", data);

        modalProps.value = {
          harvestTotal: totalAssets,
          harvestPending: pendingHarvests,
        };

        if (pendingHarvests <= 0) {
          return;
        }
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        await poll();
      } catch (err) {
        console.log("error while polling", err);
      }
    };
    await poll();
    modalProps.value = { ...modalProps.value, isHarvested: true };
    await Promise.all([
      useWalletCollectionStore().fetchCollection(true),
      useMethBankStore().fetchMethBank(),
    ]);
  } catch (err) {
    captureError(err)
    useModal().close();
  }
};
