import { harvestMeth } from "@/functions/harvestMeth";
import { ref } from "vue";

const isHarvesting = ref(false);
export const useHarvestMeth = () => {
  const harvest = async () => {
    isHarvesting.value = true;
    await harvestMeth();
    isHarvesting.value = false;
  };
  return {
    harvest,
    isHarvesting,
  };
};
