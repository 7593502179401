<template>
  <div>
    <img src="@/assets/img/spinner-clear.svg">
    <h3>Loading your collection...</h3>
  </div>
</template> 
<script>
import { defineComponent } from "vue-demi";

export default defineComponent({})
</script>