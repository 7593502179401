<template>
  <div class="collection-page-sidebar">
    <!-- <div class="collection-page-sidebar-title">Filter</div> -->
    <div class="collection-page-sidebar-items">
      <a
        href="#"
        class="collection-page-sidebar-group-item collection-page-sidebar-group-item-all"
        :class="{ active: activeItem == 'all' }"
        @click="selectItem('all')"
      >All</a>

      <div class="collection-page-sidebar-group">
        <div class="collection-page-sidebar-group-title">
          BAP Ecosystem
        </div>
        <div class="collection-page-sidebar-group-items">
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.GOD }"
            @click="selectItem(TokenType.GOD)"
          >Gods</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.GENESIS }"
            @click="selectItem(TokenType.GENESIS)"
          >Bulls</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.TEEN }"
            @click="selectItem(TokenType.TEEN)"
          >Teens</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == 'utilities' }"
            @click="selectItem('utilities')"
          >Utilities</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.APE }"
            @click="selectItem(TokenType.APE)"
          >Apes</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            @click="openApeTraits"
          >Ape Traits</a>
        </div>
      </div>
      <div class="collection-page-sidebar-group">
        <div class="collection-page-sidebar-group-title">
          BOTB Ecosystem
        </div>
        <div class="collection-page-sidebar-group-items">
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.BOTBOG }"
            @click="selectItem(TokenType.BOTBOG)"
          >BOTB OG</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.BOTBEVO }"
            @click="selectItem(TokenType.BOTBEVO)"
          >BOTB EVO</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.BOTBBEARS }"
            @click="selectItem(TokenType.BOTBBEARS)"
          >BOTB Bears</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == TokenType.BOTB_SPECIAL }"
            @click="selectItem(TokenType.BOTB_SPECIAL)"
          >BOTB Ext</a>
          <a
            href="#"
            class="collection-page-sidebar-group-item"
            :class="{ active: activeItem == 'botb_sets' }"
            @click="selectItem('botb_sets')"
          >SETS</a>
        </div>
      </div>

      <div
        v-if="partnerCollections.length > 0"
        class="collection-page-sidebar-group"
      >
        <div class="collection-page-sidebar-group-title">
          Community Tokenization
        </div>
        <div class="collection-page-sidebar-group-items-wrapper">
          <div class="collection-page-sidebar-group-items">
            <a
              v-for="projectName in partnerCollections"
              :key="projectName"
              href="#"
              class="collection-page-sidebar-group-item"
              :class="{ active: activeItem == TokenType.PARTNER && activePartner === projectName }"
              @click.prevent="selectPartner(projectName)"
            >{{ projectName }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>import { TokenType } from "@/config/token-types"
import { CollectionLayouts, useCollectionLayout } from "./useCollectionLayout"


export default {
  props: {
    activeItem: String,
    activePartner: String,
    partnerCollections: Array
  },
  data: () => ({ TokenType }),
  methods: {
    selectItem(value) {
      this.$emit('update:activeItem', value)
    },
    selectPartner(value) {
      this.selectItem(TokenType.PARTNER)
      this.$emit('update:activePartner', value)
    },
    openApeTraits() {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.apeTraits)
    }
  }
}
</script>