<template>
  <CollectionCard>
    <CollectionCardImage
      :on-chain-count="item.onChainCount"
      :off-chain-count="item.offChainCount"
      :image="item.image"
    >
      <template #back>
        <div class="collection-card-utility-description">{{ item.description }}</div>
      </template>
    </CollectionCardImage>
    <CollectionCardHeader :name="item.name" />

    <CollectionCardActions
      @send="sendItem"
      :items="actions"
      @mint="initAction('mint')"
      @moveOffChain="initAction('move-off-chain')"
      v-if="!isMinting"
    />
    <CollectionCardSelectCount
      @cancel="cancel"
      :max="actionType === 'mint' ? offChainCount : onChainCount"
      :is-loading="isLoading"
      :approve-text="actionType === 'mint' ? 'Mint' : 'Approve'"
      @approve="approveAction"
      v-if="isMinting"
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline"

import IOffChain from "@/assets/icons/off-chain.svg?inline"

import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import utilitiesMetadata from "@/config/utilities-metadata";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { mintOffChainUtilities } from "@/functions/mintOffChainUtilities";
import { moveUtilitiesOffChain } from "@/functions/moveUtilitiesOffChain";
import CollectionCardSelectCount from "../CollectionCard/CollectionCardSelectCount.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";

export default defineComponent({
  components: { CollectionCardActions, CollectionCardSelectCount, CollectionCardImage, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {
    const offChainCount = computed(() => props.item.offChainCount)
    const onChainCount = computed(() => props.item.onChainCount)

    const actions = computed(() => {
      const items = []
      if (offChainCount.value > 0) {
        items.push(
          {
            name: "Mint",
            event: "mint"
          }
        )
      }
      if (onChainCount.value > 0) {
        items.push(
          {
            name: "Move off-chain",
            event: "moveOffChain"
          }
        )
      }
      if (offChainCount.value > 0) {
        items.push({
          name: "Transfer",
          event: "send"
        })
      }

      return items
    })
    const sendItem = () => {
      useModal().open({
        name: ModalName.SendUtilityModal,
        props: {
          item: props.item, maxAmount: props.item.offChainCount
        }
      })
    }
    const isLoading = ref(false)
    const metadata = computed(() => utilitiesMetadata[3])
    const actionType = ref('')
    const isMinting = computed(() => !!actionType.value)
    const initAction = async (type) => {
      actionType.value = type
    }

    const cancel = () => {
      actionType.value = ''
    }

    const approveAction = async (count) => {
      isLoading.value = true
      const data = [
        {
          id: props.item.id,
          amount: count
        }
      ]
      let success;
      if (actionType.value === 'mint') {
        success = await mintOffChainUtilities(data)
      } else {
        success = await moveUtilitiesOffChain(data)
      }
      isLoading.value = false
      if (success) {
        actionType.value = ''
      }
      setTimeout(() => {
        useWalletCollectionStore().fetchCollection(true)
      }, 500)

    }
    return { metadata, sendItem, isLoading, onChainCount, actionType, approveAction, cancel, actions, initAction, isMinting, offChainCount }
  }
})
</script>
<style lang="scss">
.collection-card-incubator-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>