<template>
  <div class="collection-utils-analytics">
    <div class="collection-utils-analytics__title">
      God Boxes Opened
    </div>
    <div class="collection-utils-analytics__value">
      {{ totalChestOpen }}
    </div>
    <div class="collection-utils-analytics__list">
      <div class="collection-utils-analytics__list-item">
        <img
          :src="utilitiesMetadata[40].image"
          width="15"
        >
        <span>{{ breedReplenishmentTotal }}</span>
        <div class="collection-utils-analytics__list-item-tooltip">
          Replenishment
        </div>
      </div>

      <div class="collection-utils-analytics__list-item">
        <img
          :src="utilitiesMetadata[30].image"
          width="15"
        >
        <span>{{ teenResurrectionTotal }}</span>
        <div class="collection-utils-analytics__list-item-tooltip">
          Teen Resurrection
        </div>
      </div>
      <div class="collection-utils-analytics__list-item">
        <img
          :src="utilitiesMetadata[20].image"
          width="15"
        >
        <span>{{ methMakerTotal }}</span>
        <div class="collection-utils-analytics__list-item-tooltip">
          Meth Machine
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useHRApi } from "@/api/useHRApi";
import { defineComponent } from "vue-demi";
import { Config } from "../../../config/index";
import utilitiesMetadata from "../../../config/utilities-metadata";

export default defineComponent({
  components: {
  },
  props: {
  },
  data() {
    return {
      stats: {},
      utilitiesMetadata,
    };
  },
  computed: {
    teenResurrectionTotal() {
      return this.stats.resurrection && this.stats.resurrection.total;
    },
    breedReplenishmentTotal() {
      return this.stats.breed_replenish && this.stats.breed_replenish.total;
    },
    methMakerTotal() {
      return this.stats.meth_maker && this.stats.meth_maker.total;
    },
    totalChestOpen() {
      return this.stats.total;
    },
  },
  created() {
    this.fetchChestStats();
  },
  methods: {
    async fetchChestStats() {
      const data = await useHRApi({ toast: { error: true } }).exec({
        url: `/v1/bap/chest-stats`,
        params: {
          // TEMP
          network: Config.network.name === 'sepolia' ? 'goerli' : Config.network.name
        }
      }
      );
      if (!data) return
      this.stats = data.stats;

    },
  },
})
</script>
<style lang="scss">
.collection-utils-analytics {
  &__title {
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 8px;
    font-weight: bold;
  }
  &__value {
    font-weight: bold;
    font-size: 18px;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 2550, 0.3);
    &-item {
      display: flex;
      align-items: center;
      padding: 0 5px;
      position: relative;
      // color: rgba(255, 255, 255, 0.6);
      img {
        border-radius: 50%;
        margin-right: 5px;
        flex-shrink: 0;
        width: 18px;
      }
      &-tooltip {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 100%);
        background-color: var(--bg);
        padding: 5px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-size: 12px;
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
      }
      &:hover .collection-utils-analytics__list-item-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
</style>