<template>
  <div class="collection-ape-traits">
    <div class="collection-ape-traits__buttons">
      <a v-if="hasOffChainTraits" v-tooltip="{ content: 'Mint all offchain traits', distance: 10 }"
        class="collection-ape-traits__mint-all" href="javascript:void(0)" :class="{ disabled: mintAllLoading }"
        @click.prevent="mintAllOffchain">
        Mint All Traits
        <img v-if="mintAllLoading" src="@/assets/img/spinner-clear.svg" width="16" class="loader">
      </a>
      <LootBoxButton class="collection-ape-traits__lootbox" />

      <TraitShopButton class="collection-ape-traits__trait-shop" />
    </div>
    <h2>Ape Traits</h2>

    <div class="collection-ape-traits-filters">
      <div v-for="(item, idx) in filterItems " :key="idx" class="collection-ape-traits-filters__item"
        :class="{ active: genderFilter === item.value }" @click.prevent="selectFilter(item.value)">
        {{ item.name }}
      </div>
    </div>
    <div v-if="!isLoading && traitsItems.length" class="collection-ape-traits__items">
      <div v-for="item in traitsItems" :key="item.id" class="collection-ape-traits__item">
        <CollectionApeTrait :item="item" :is-selection-mode="isSelectMode" :selection-mode="selectionMode"
          :selected-count="selectedTraits.find(trait => trait.id === item.id)?.count"
          :is-selected="selectedTraits.findIndex(trait => trait.id === item.id) > -1" @zoom="onZoom"
          @refresh="fetchTraitOrders" @mint="onMintTrait(item)" @moveOffChain="onMoveOffChain(item)"
          @select="selectTrait(item, $event)" />
      </div>
      <InfiniteLoading @infinite="loadMoreItems" />
    </div>
    <div v-else-if="!isLoading && !traitsItems.length" class="collection-ape-traits__empty">
      You don't have any Traits yet
    </div>
    <div v-else class="collection-ape-traits__loading">
      <img src="@/assets/img/spinner-clear.svg" class="spinner" width="80">
    </div>
    <Transition name="t-collection-ape-traits-zoom">
      <div v-if="showZoom" class="collection-ape-traits-zoom" @click.prevent="hideZoom">
        <div class="collection-ape-traits-zoom__image">
          <img :src="zoomImage" alt="zoom-image">
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import { useActionBar } from "@/components/AActionBar/useActionBar"
import { mintVirtualTraits } from "@/functions/mintVirtualTraits"
import { resizeImageCF } from "@/helpers/resize-image-cloudflare"
import { useWalletCollectionStore } from "@/store/wallet-collection"
import { computed, defineComponent, onBeforeUnmount, ref } from "vue"
import CollectionApeTrait from "./CollectionApeTrait.vue"
import InfiniteLoading from "v3-infinite-loading";
import TraitShopButton from "@/components/TraitShop/TraitShopButton.vue"
import { moveTraitsOffChain } from "@/functions/moveTraitsOffChain"
import LootBoxButton from "@/components/LootBox/LootBoxButton.vue"
import { traitMarketplaceGetList } from "@/functions/trait-marketplace/trait-marketplace-get-list"
import { captureError } from "@/helpers/captureError"
import { Config } from "@/config"
import { useWeb3Store } from "@/store/web3"
import { useApesStorageApi } from "@/api/useApesStorageApi"

export default defineComponent({
  components: { CollectionApeTrait, LootBoxButton, TraitShopButton, InfiniteLoading },
  setup() {
    const walletCollection = useWalletCollectionStore()
    const isLoading = ref(false)
    const genderFilter = ref(0)
    const orders = ref([])
    const buyOrders = ref([])
    const fetchTraitOrders = async () => {
      let [sellOrders, buyOffers] = await Promise.all([
        traitMarketplaceGetList(),
        useApesStorageApi({
          toast: { error: () => "Couldn't get wallet's sale orders" },
        }).exec({
          url: "/trading/list",
          params: {
            isUtility: false,
            buyOrder: true,
            network: Config.network.name,
          },
        })])
      buyOffers = buyOffers.filter(offer => offer.wallet.toLowerCase() !== useWeb3Store().walletAddress.toLowerCase())
      buyOrders.value = buyOffers
      orders.value = sellOrders
    }
    const init = async () => {
      isLoading.value = true
      await walletCollection.fetchApeTraits()
      await fetchTraitOrders()
      isLoading.value = false
    }
    init()
    const items = computed(() => {
      return walletCollection.apeTraits.map(trait => {
        const buyOrder = buyOrders.value.filter(order => order.trait_id == trait.id)
          .reduce((biggest, cur) => {
            if (!biggest) return cur
            if (cur?.price > biggest?.price) return cur
            return biggest
          }, undefined)
        return {
          ...trait,
          sellOrder: orders.value.find(order => order.trait_id == trait.id),
          buyOrder
        }
      })
    })
    const filterItems = [
      {
        name: "All",
        value: 0
      },
      {
        name: "Male",
        value: 1,
      },
      {
        name: "Female",
        value: 2
      },
      {
        name: "Unisex",
        value: 3
      },
    ]
    const selectFilter = (value) => {
      genderFilter.value = value
    }
    const showZoom = ref(false)
    const zoomImage = ref('')
    const page = ref(1)
    const perPage = 30
    const onZoom = image => {
      zoomImage.value = image
      showZoom.value = true
    }
    const hideZoom = () => {
      showZoom.value = false
    }
    const loadMoreItems = () => {
      page.value++
    }
    const filteredItems = computed(() => {
      return items.value
        .filter(item => {
          return genderFilter.value ? item.genderId === genderFilter.value : true
        })
    }
      // .slice(0, perPage * page.value)
      // .map(item => ({
      //   ...item,
      //   femalePreviewImageSrc: item.femalePreviewImageSrc ? resizeImageCF(item.femalePreviewImageSrc, 512) : null,
      //   malePreviewImageSrc: item.malePreviewImageSrc ? resizeImageCF(item.malePreviewImageSrc, 512) : null,
      // }))
    )
    const selectionMode = ref('')
    const isSelectMode = computed(() => !!selectionMode.value)
    const traitsItems = computed(() => {
      if (isSelectMode.value) {
        return filteredItems.value.filter(item => selectionMode.value === 'mint' ? item.offChain > 0 : item.balance > 0)
      }
      return filteredItems.value.slice(0, perPage * page.value)
    })
    const selectedTraits = ref([])
    const mintTraits = () => {

      return mintVirtualTraits(selectedTraits.value)
    }
    const moveOffChain = () => {
      return moveTraitsOffChain(selectedTraits.value)

    }
    const showActionBar = () => {
      useActionBar().show({
        text: computed(() => {
          return `Trait${selectedTraits.value.length > 1 ? 's' : ''} ${selectedTraits.value.reduce((str, { id, count }, index) => str += `${index > 0 ? ', ' : ''}#${id} x${count}`, '')} ${selectedTraits.value.length > 1 ? 'are' : 'is'} selected to ${selectionMode.value === 'mint' ? 'Mint' : 'Move offchain'}`
        }),
        canApprove: computed(() => selectedTraits.value.length > 0),
        approveText: computed(() => selectionMode.value === 'mint' ? 'Mint' : "Move Offchain"),
        approve: async () => {
          // approve
          useActionBar().isLoading.value = true
          try {
            if (selectionMode.value === 'mint') {

              await mintTraits()
            } else {
              await moveOffChain()
            }
            useActionBar().hide()
            selectionMode.value = ''
            selectedTraits.value = []
            init()
          } catch (err) {
            captureError(err)
          }
          useActionBar().isLoading.value = false

        },

        cancel: () => {
          useActionBar().hide()
          selectedTraits.value = []
          selectionMode.value = ''
        }
      })
    }
    const onMintTrait = (trait) => {
      console.log(trait)
      selectedTraits.value = []
      selectionMode.value = 'mint'
      showActionBar()
      selectTrait(trait, 1)
    }
    const onMoveOffChain = (trait) => {
      console.log(trait)
      selectedTraits.value = []
      selectionMode.value = 'offchain'
      showActionBar()
      selectTrait(trait, 1)
    }


    const selectTrait = (trait, count) => {
      const traitIdx = selectedTraits.value.findIndex(t => t.id == trait.id)
      if (count < 1) {
        selectedTraits.value.splice(traitIdx, 1)
        return
      }
      if (traitIdx > -1) {
        selectedTraits.value[traitIdx].count = count
      } else {
        selectedTraits.value.push({ id: trait.id, count })
      }
    }
    const mintAllLoading = ref(false)
    const mintAllOffchain = async () => {
      if (mintAllLoading.value) return
      mintAllLoading.value = true
      const itemsToMint = items.value.filter(item => item.offChain > 0).map(item => ({ id: item.id, count: item.offChain }))
      try {
        await mintVirtualTraits(itemsToMint)
      } catch (err) {
        console.error(err)
      }
      mintAllLoading.value = false
      init()
    }
    const hasOffChainTraits = computed(() => items.value.filter(item => item.offChain > 0).length > 0)
    onBeforeUnmount(() => {
      useActionBar().hide()
    })
    return {
      items, isLoading, buyOrders, fetchTraitOrders, onMoveOffChain, selectionMode, mintAllLoading, traitsItems, hasOffChainTraits, mintAllOffchain, showZoom, mintTraits, selectedTraits, isSelectMode, selectTrait, onMintTrait, loadMoreItems, zoomImage, genderFilter, hideZoom, onZoom, selectFilter, filterItems, filteredItems
    }
  }
})
</script>
<style lang="scss">
.collection-ape-traits {
  width: 90%;
  margin: 0 auto;
  position: relative;

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-top: 40px;
  }

  &__empty {
    padding: 30px 0;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__item {
    width: calc(100% / 5);
    padding: 15px 10px;
    box-sizing: border-box;
  }

  &__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;

    transform: translateY(-50px);
  }

  &__trait-shop {
    margin-left: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__mint-all {
    padding: 8px 16px;
    border: 1px solid var(--primary);
    color: var(--primary);
    border-radius: 5px;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.2s;
    margin-right: 8px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }

  &-filters {
    display: flex;
    justify-content: center;

    &__item {
      width: 100px;
      padding: 5px;
      border: 1px solid var(--border);
      border-left: none;
      box-sizing: border-box;
      cursor: pointer;

      &.active {
        background-color: var(--primary);
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:first-child {
        border-left: 1px solid var(--border);

        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }

  &__loading {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-zoom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 30;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.2s;

    &__image {
      img {
        transition: 0.2s;
        object-fit: contain;
        width: 90%;
        margin-top: 30px;
        max-width: 800px;
        max-height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .t-collection-ape-traits-zoom-enter-from,
  .t-collection-ape-traits-zoom-leave-to {
    img {
      transform: translate(-50%, -50%) scale(0.1);
    }

    opacity: 0;
  }

  @media screen and (max-width: 1450px) {
    &__item {
      width: calc(100% / 4);
    }
  }

  @media screen and (max-width: 992px) {
    &__item {
      width: calc(100% / 3);
    }
  }

  @media screen and (max-width: 768px) {
    &__item {
      width: calc(100% / 2);
    }

    &__buttons {
      transform: none;
      position: relative;
      margin-top: 30px;
      flex-direction: column;
    }

    &__mint-all {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &__trait-shop {
      margin-top: 15px;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 576px) {
    &__item {
      width: 100%;
    }

    &__lootbox {}
  }
}
</style>