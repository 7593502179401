<template>
  <div
    class="collection-resurrect-preview-card"
    :class="{ empty: !hasData }"
    @click="changeItem"
  >
    <div class="collection-resurrect-preview-card-title">
      {{ title }}
    </div>
    <div class="collection-resurrect-preview-card-image">
      <img
        v-if="hasData"
        :src="image"
        width="512"
        height="512"
      >
      <div
        v-else
        class="collection-resurrect-preview-card-image-placeholder"
      >
        Select Teen
      </div>
    </div>
    <div class="collection-resurrect-preview-card-name">
      <template v-if="hasData">
        <span class="rank">RANK {{ item.rank }}</span>
        <span>#{{ item.id }}</span>
      </template>
      <!-- <div v-else>Select Item</div> -->
    </div>
    <div class="collection-resurrect-preview-card-actions">
      <a
        class="collection-resurrect-preview-card-button"
        href="#"
        @click.prevent.stop="changeItem"
      >
        {{ hasData ? "Change Item" : "Select Teen" }}
      </a>
    </div>
  </div>
</template>
<script>
import { getTeenImageStaging } from "../../../helpers/utils";
import { Config } from "../../../config";
export default {
  props: {
    title: String,
    item: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["changeItem"],
  computed: {
    hasData() {
      return !!this.item;
    },
    image() {
      return Config.network.name === "sepolia"
        ? getTeenImageStaging(this.item.id)
        : this.item.image;
    },
  },
  methods: {
    changeItem() {
      this.$emit("changeItem");
    },
  },
};
</script>
