import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useBapApi } from "@/api/useBapApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { useUtilitiesInteractionsContract } from "@/web3/contracts";

export const replenishBreeding = async function (bull, utilityId) {
  const confirmed = await useConfirmModal({
    title: "Info",
    text: `Notice: 0-breed METH per day calculation will change.<br>Confirm transaction to proceed with replenishment.
    <br><br>Otherwise, reject transaction and close window. 
    <br> Claim your METH first  prior to replenishing breedings`,
    confirmText: "Continue",
  });
  if (!confirmed) return;

  const web3Store = useWeb3Store();
  const walletAddress = web3Store.walletAddress;
  const contract = useUtilitiesInteractionsContract(true);

  const signature  = await useApesStorageApi({
    throw: true,
    log: false,
    toast: { error: true },
  }).exec({
    url: "/godBox/replenish-signature",
    params: {
      wallet: walletAddress,
      tokenId: bull.id,
      utilityId: utilityId,
      network: Config.network.name,
    },
  });

  const response = await useToastTransaction(
    contract.methods.replenishBreedings(utilityId, bull.id, signature),
    {
      text: "Replenish Breeding",
      successText:
        "Breed Replenishment Transaction is complete. <br> Your Bull has 3 breedings now",
    }
  );
  useWalletCollectionStore().fetchCollection(true);
  useWeb3Store().update();
  return response;
};
