<template>
  <div class="collection-card-meth-info">
    <img
      src="@/assets/img/meth.png"
      width="14"
    >
    <div
      v-tooltip="methTooltip"
      class="collection-card-meth-info-text"
    >
      <span>
        Generating
        <b>{{ totalMeth }}</b>
        {{ isHarvestable ? "Harvestable" : "" }} METH/day
      </span>
      <IInfo
        v-if="apeBonusMeth"
        width="10"
      />
    </div>
  </div>
</template>

<script>import { computed, defineComponent } from "vue-demi";
import IInfo from "@/assets/img/info.svg?inline"
export default defineComponent({
  components: { IInfo },
  props: {
    meth: Number,
    apeBonusMeth: Number,
    isHarvestable: Boolean,
    tokenName: String
  },
  setup(props) {
    const totalMeth = computed(() => props.meth + (props.apeBonusMeth || 0))
    const methTooltip = computed(() => {
      if (props.apeBonusMeth)
        return {
          content: `${props.meth} METH ${props.tokenName} + ${props.apeBonusMeth} METH Ape attachment`,
          popperClass: 'collection-card-meth-info-tooltip'
        }
      return false
    })
    return { totalMeth, methTooltip }
  },
})
</script>