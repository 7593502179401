<template>
  <CollectionCard>
    <CollectionCardImage
      :image="item.image"
      :on-chain-count="item.onChain"
      :off-chain-count="item.offChain"
      v-bind="$attrs"
    >
      <template #back>
        <div class="collection-card-utility-description" v-if="item.description">{{ item.description }}</div>
      </template>
    </CollectionCardImage>
    <CollectionCardHeader name="BOTB Extended" />

    <CollectionCardActions
      :items="actions"
      :is-off-chain="item.offChain > 0"
      v-bind="$attrs"
      @mint="startMinting"
      v-if="!isMinting"
    />
    <CollectionCardSelectCount
      :max="item.offChain"
      :is-loading="isLoading"
      @cancel="cancelMinting"
      :approve-text="'Mint'"
      @approve="mint"
      v-else
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import { TokenType } from "@/config/token-types";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWeb3Store } from "@/store/web3";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCardSelectCount from "../CollectionCard/CollectionCardSelectCount.vue";
import { botbSpecialMint } from "@/functions/botb-special/botbSpecialMint";



export default defineComponent({
  components: { CollectionCardActions, CollectionCardImage, CollectionCardSelectCount, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {
    const walletCollectionStore = useWalletCollectionStore()
    const actions = computed(() => {
      return [
        

      ]
    })
    const isMinting = ref(false)
    const isLoading = ref(false)
    const startMinting = () => {
      isMinting.value = true
    }
    const cancelMinting = () => {
      isMinting.value = false
    }

   
    const mint = async (amount) => {
      // to do mint
      isLoading.value = true
      const success = await botbSpecialMint(amount)
      if(success) {
        isMinting.value = false
      }
      isLoading.value = false

    }
    return { TokenType, isMinting, isLoading, cancelMinting, startMinting, mint, actions }
  }
})
</script>
<style lang="scss">
</style>