<template>
    <div class="collection-meth-generation">
        <IInfo width="30" />
        <div class="collection-meth-generation-content">
            <div class="collection-meth-generation-text">
                See how meth generation works
            </div>

            <div class="collection-meth-generation-links">
                <a href="#" @click.prevent="showModal('og-bull')">Og Bull/God Bull</a>
                <a href="#" @click.prevent="showModal('teen')">Teen/BOTB</a>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useModal } from '@/composables/useModal';
import { ModalName } from '../Modal/modal-name';
import IInfo from "@/assets/img/info.svg?inline"
const showModal = (type) => {
    useModal().open({ name: ModalName.ImageModal, props: { image: `/meth-${type}.jpg` } })
}
</script>
<style lang="scss">
.collection-meth-generation {
    margin-right: auto;
    display: flex;
    align-items: center;
    text-align: left;

    svg {
        margin-right: 10px;
    }

    &-text {
        font-size: 18px;
        font-weight: bold;
    }

    &-links {

        display: flex;
        margin-top: 5px;

        a {
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: none;
            margin: 0 5px;
            color: var(--primary);

            &:hover {
                text-decoration: underline;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
    }
}
</style>