import { TokenType } from "@/config/token-types";
import { useWalletCollectionStore } from "@/store/wallet-collection";

export const breedSelection = (token, selection) => {
  const collectionStore = useWalletCollectionStore();
  if (token.type !== TokenType.GENESIS) return false;
  if (token.breedingsLeft <= 0) return false;
  if (
    collectionStore.genesis.findIndex(
      (bull) => bull.guild === token.guild && bull.id != token.id && bull.breedingsLeft > 0
    ) < 0
  )
    return false;
  if (!selection.length) {
    return true;
  }
  return selection[0].guild === token.guild;
};
