<template>
  <div class="collection-resurrect-preview">
    <div class="collection-resurrect-preview-row">
      <CollectionResurrectPreviewCard
        :item="sacrifice"
        title="Select Teen to Sacrifice"
        @changeItem="changeItem('sacrifice')"
      />
      <div class="collection-resurrect-preview-arrow">
        <IArrowRight width="40" />
      </div>
      <CollectionResurrectPreviewCard
        :item="resurrect"
        title="Select Teen to Resurrect"
        @changeItem="changeItem('resurrect')"
      />
    </div>
    <div class="collection-resurrect-preview-actions">
      <!-- <button class="collection-resurrect-preview-button">Cancel</button> -->
      <a
        href="#"
        class="collection-resurrect-preview-button collection-resurrect-preview-button-cancel"
        @click.prevent="$emit('cancel')"
      >
        <!-- <IArrowLeft width="20" /> -->
        Cancel
      </a>
      <button
        class="collection-resurrect-preview-button collection-resurrect-preview-button-approve"
        :class="{ disabled }"
        @click="approve"
      >
        Approve
      </button>
    </div>
  </div>
</template>
<script>
import CollectionResurrectPreviewCard from "./CollectionResurrectPreviewCard.vue";
import IArrowRight from "../../../assets/icons/arrow-right-long.svg?inline";
// import IArrowLeft from "../../../assets/icons/arrow-left-long.svg?inline";

export default {
  components: { CollectionResurrectPreviewCard, IArrowRight,  },
  props: {
    resurrect: Object,
    sacrifice: Object,
  },
  computed: {
    disabled() {
      return !this.resurrect || !this.sacrifice;
    },
  },
  methods: {
    changeItem(item) {
      this.$emit("changeItem", item);
    },
    approve() {
      if (this.disabled) return;
      this.$emit("approve");
    },
  },
};
</script>
