import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { useUtilitiesInteractionsContract } from "@/web3/contracts";

// items - [{id, amount}]
export const mintOffChainUtilities = async (items) => {
  try {
    const wallet = useWeb3Store().walletAddress;
    const utilitiesIds = items.map((item) => item.id);
    const amounts = items.map((item) => item.amount);
    const { signature, timeOut } = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/wallet/mint-utilities",
      method: "POST",
      data: {
        wallet,
        utilities: utilitiesIds, // array of ids
        amounts, // array of amounts
        network: Config.network.name,
      },
    });
    const contract = useUtilitiesInteractionsContract(true);
    const result = await useToastTransaction(
      contract.methods.mintUtilities(utilitiesIds, amounts, timeOut, signature),
      {
        text: `Minting utilit${
          utilitiesIds.length > 1 || amounts[0] > 1 ? "ies" : "y"
        }`,
      }
    );
    return result;
  } catch (err) {
    captureError(err);
  }
};
