<template>
  <CollectionCard
    class="collection-card-meth-machine"
    :is-off-chain="item.isOffChain"
    v-bind="$attrs"
  >
    <CollectionCardImage :on-chain-count="item.onChainCount" :off-chain-count="item.offChainCount" :image="item.image" :is-off-chain="item.isOffChain" v-bind="$attrs">
      <template #back>
        <div class="collection-card-utility-description">{{ item.description }}</div>
      </template>
    </CollectionCardImage>
    <CollectionCardHeader :name="item.name" :guild="item.guild" />

    <div
      v-if="methMachineActiveTeens <= 0 || item.isOffChain"
      class="collection-card-meth-machine-alert"
      :class="{
        ['error']: !isMethMachineFirst || !item.onChainCount,
        ['info']: isMethMachineFirst && methMachineActiveTeens <= 0 && item.onChainCount,
      }"
    >
      <div class="collection-card-meth-machine-alert-icon">
        <IInfo width="20" />
      </div>
      <span v-if="!isMethMachineFirst && !item.onChainCount">
        There is another METH Machine of the same Guild in the Your wallet.
        Hence, this METH machine will not generate any Harvestable METH tokens
      </span>
      <span v-else-if="methMachineActiveTeens <= 0 && item.onChainCount">
        There are no Teens which belong to the same Guild as METH machine.
        Breed your OG Bulls or get Teens of the same guild from the secondary
        so that this METH machine can generate harvestable METH
        tokens
      </span>
      <span v-if="!item.onChainCount">This METH machine is currently disabled. Mint it to make it work</span>
    </div>
    <div class="collection-card-meth-machine-active-teens">
      Active Teens:
      <b>{{ methMachineActiveTeens }}</b>
    </div>
    <CollectionCardMethInfo :meth="methGenerate" :is-harvestable="true" />
    <CollectionCardActions
      :items="actions"
      :is-off-chain="item.isOffChain"
      v-bind="$attrs"
      @mint="initAction('mint')"
      @moveOffChain="initAction('move-off-chain')"
      @send="sendItem"
      v-if="!isMinting"
    />
    <CollectionCardSelectCount
      @cancel="cancel"
      :max="actionType === 'mint' ? offChainCount : onChainCount"
      :is-loading="isLoading"
      :approve-text="actionType === 'mint' ? 'Mint' : 'Approve'"
      @approve="approveAction"
      v-if="isMinting"
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardMethInfo from "../CollectionCard/CollectionCardMethInfo.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import IInfo from "@/assets/img/info.svg?inline"
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { mintOffChainUtilities } from "@/functions/mintOffChainUtilities";
import { moveUtilitiesOffChain } from "@/functions/moveUtilitiesOffChain";
import CollectionCardSelectCount from "../CollectionCard/CollectionCardSelectCount.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
export default defineComponent({
  components: { IInfo, CollectionCardMethInfo, CollectionCardSelectCount, CollectionCardActions, CollectionCardImage, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {
    const walletCollectionStore = useWalletCollectionStore()

    const offChainCount = computed(() => props.item.offChainCount)
    const onChainCount = computed(() => props.item.onChainCount)

    const isMethMachineFirst = computed(() => {
      if (props.item.isOffChain) return false
      const itemIdx = walletCollectionStore.onChainMethMachines.findIndex(
        (maker) => maker.guild === props.item.guild
      );
      const objIdx = walletCollectionStore.onChainMethMachines.indexOf(props.item);
      return itemIdx === objIdx;
    })
    const methMachineActiveTeens = computed(() => {
      if (!isMethMachineFirst.value) return 0;
      const items = walletCollectionStore.teens.filter(
        (teen) => teen.guild == props.item.guild && !teen.isResurrected
      );
      return items.length;
    })
    const methGenerate = computed(() => {
      return methMachineActiveTeens.value * 5
    })
    const actions = computed(() => {
      const items = []
      if (props.item.offChainCount > 0) {
        items.push(
          {
            name: "Mint",
            event: "mint"
          }
        )
      }
      if (props.item.offChainCount > 0) {
        items.push({
          name: "Transfer",
          event: "send"
        })
      }
      if (props.item.onChainCount > 0) {
        items.push(
          {
            name: "Move off-chain",
            event: "moveOffChain"
          }
        )
      }
      return items
    })

    const sendItem = () => {
      useModal().open({
        name: ModalName.SendUtilityModal,
        props: {
          item: props.item, maxAmount: props.item.offChainCount
        }
      })
    }

    const isLoading = ref(false)
    const actionType = ref('')
    const isMinting = computed(() => !!actionType.value)
    
    const initAction = async (type) => {
      actionType.value = type
    }
    const cancel = () => {
      actionType.value = ''
    }

    const approveAction = async (count) => {
      isLoading.value = true
      const data = [
          {
              id: props.item.id,
              amount: count
          }
      ]
      let success;
      if (actionType.value === 'mint') {
          success = await mintOffChainUtilities(data)
      } else {
          success = await moveUtilitiesOffChain(data)
      }
      isLoading.value = false
      if (success) {
          actionType.value = ''
      }
      setTimeout(() => {
          useWalletCollectionStore().fetchCollection(true)
      }, 500)
    }

    return {
      sendItem,
      actions,
      methGenerate,
      isMethMachineFirst,
      methMachineActiveTeens,
      offChainCount,
      onChainCount,
      isMinting,
      actionType,
      isLoading,
      initAction,
      cancel,
      approveAction
    }
  }
})
</script>
<style lang="scss">
.collection-card-meth-machine {
  &-active-teens {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    b {
      color: var(--primary);
      font-weight: bold;
    }
  }
  &-alert {
    font-size: 14px;
    padding: 5px 0;
    &-icon {
      display: flex;
      justify-content: center;
      margin-bottom: -15px;
      position: relative;
      z-index: 2;
      svg {
        background-color: var(--bg);
        padding: 5px;
      }
    }
    span {
      display: inline-block;
      padding: 8px;
      padding-top: 12px;
      border: 1px solid;
      border-radius: 5px;
    }
    &.error {
      color: #ff0000;

      span {
        border-color: rgba(255, 0, 0, 0.5);
      }
    }
    &.info {
      color: #98d8ff;

      span {
        border-color: rgba(152, 216, 255, 0.5);
      }
    }
  }
}
</style>