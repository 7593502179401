<template>
  <div
    class="collection-tokens-set"
    :class="{ max: hasASet }"
  >
    <div class="collection-tokens-set-title">
      BOTB SET #{{ og.id }}
    </div>
    <div class="collection-tokens-set-earn">
      Earning
      <b>{{ earningMeth }}</b> BONUS harvestable METH per day
    </div>
    <div class="collection-tokens-set-label-left-wrapper">
      <div
        class="collection-tokens-set-label collection-tokens-set-label-left"
        :class="{ active: hasASet }"
      >
        <IFire
          class="collection-tokens-set-label-icon"
          width="16"
        />
        SET Bonus {{ hasASet ? 'Active' : 'Inactive' }}
      </div>
      <div
        v-if="!hasASet"
        class="collection-tokens-set-label-tooltip"
      >
        Becomes active when a
        <br>full set is collected
      </div>
    </div>
    <!--<div class="collection-tokens-set-label-right-wrapper">
      <div
        class="collection-tokens-set-label collection-tokens-set-label-right"
        :class="{ active: isBonusDistributed }"
      >
        <ICheckmark
          v-if="isBonusDistributed"
          key="checkmark"
          class="collection-tokens-set-label-icon"
          width="18"
        />
        <ICross
          v-else
          key="cross"
          class="collection-tokens-set-label-icon"
          width="12"
        />
        SET Bonus {{ isBonusDistributed ? '' : 'not' }} distributed
        <IInfo
          class="collection-tokens-set-label-info"
          width="12"
        />
      </div>
      <div
        v-if="!isBonusDistributed"
        class="collection-tokens-set-label-tooltip"
      >
        Check BAP announcements
        <br>when bonus distribution is activated
      </div>
    </div>-->
    <div class="collection-tokens-set-row">
      <div class="collection-tokens-set-item">
        <CollectionCardAny :item="item.OG" />
        <div class="collection-tokens-set-item-info">
          BOTB OG
        </div>
      </div>
      <div class="collection-tokens-set-item">
        <CollectionCardAny :item="item.EVO" />
        <div class="collection-tokens-set-item-info">
          BOTB EVO
        </div>
      </div>
      <div class="collection-tokens-set-item">
        <CollectionCardAny :item="item.Bears" />
        <div class="collection-tokens-set-item-info">
          BOTB BEAR
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IFire from "@/assets/icons/fire.svg?inline"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
import ICross from "@/assets/icons/cross.svg?inline"
import IInfo from "@/assets/img/info.svg?inline"
import CollectionCardAny from "../CollectionCards/CollectionCardAny.vue"
export default {
    components: {
        CollectionCardAny,
        IFire, ICheckmark, ICross, IInfo


    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
        fliped: {
            og: false,
            evo: false,
            bear: false,
        },
        isBonusDistributed: false
    }),
    computed: {

        hasASet() {
            return (this.og.harvestInfo && this.og.harvestInfo.level) >= 0
                && (this.evo.harvestInfo && this.evo.harvestInfo.level) >= 0
                && (this.bear.harvestInfo && this.bear.harvestInfo.level) >= 0
        },
        og() {
            const { OG } = this.item
            return OG || {}
        },
        evo() {
            const { EVO } = this.item
            return EVO || {}
        },
        bear() {
            const { Bears } = this.item
            return Bears || {}
        },
        earningMeth() {
            if (this.hasASet) {
                return 3
            }
            return 0
        }
    }
}
</script>
<style lang="scss">
.collection-tokens-set {
    padding: 15px;
    box-sizing: border-box;

    border: 1px solid rgba(255, 255, 255, 0.3);
    max-width: 980px;
    margin: 15px auto;
    border-radius: 5px;
    position: relative;
    &.max {
        border-color: #a347ed;
    }
    &-title {
        font-weight: bold;
        font-size: 22px;
    }
    &-earn {
        margin-top: 8px;
        color: rgba(255, 255, 255, 0.8);
        b {
            color: var(--primary);
            font-weight: bold;
        }
    }
    &-row {
        display: flex;

        margin-top: 15px;
    }
    &-labels {
        display: flex;
        align-items: center;
    }
    &-label {
        display: flex;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.3);

        padding: 0 8px;
        border-radius: 5px;
        font-size: 14px;
        opacity: 0.5;
        text-transform: uppercase;
        height: 32px;
        &:hover + &-tooltip {
            opacity: 1;
        }
        &-tooltip {
            font-size: 12px;
            opacity: 0.5;
            margin-top: 3px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            transform: translateY(100%);
            padding: 8px 10px;
            border-radius: 5px;
            background-color: var(--gray-light);
            opacity: 0;
            pointer-events: none;
            transition: 0.3s;
        }
        &-left {
            &-wrapper {
                display: flex;
                position: absolute;
                text-align: left;
                top: 20px;
                left: 40px;
            }
        }
        &-right {
            &-wrapper {
                display: flex;
                position: absolute;
                text-align: left;
                top: 20px;
                right: 40px;
            }
            &.active {
                color: #01b101 !important;
            }
        }
        &-icon {
            margin-right: 5px;
        }
        &-info {
            margin-left: 5px;
        }
        &.active {
            border: 1px solid currentColor;
            color: #a347ed;
            opacity: 1;
        }
    }
    &-item {
        flex: 1;
        margin: 10px;
        &-info {
            text-transform: uppercase;
            font-weight: bold;
            opacity: 0.5;
        }
    }
    @media screen and (max-width: 1020px) {
        width: calc(100% - 30px);
        margin: 15px;
        &-row {
            flex-direction: column;
            align-items: center;
        }
        &-label-tooltip {
            max-width: 300px;
            left: 50%;
            transform: translate(-50%, 100%);
            z-index: 20;
        }
        &-label-left-wrapper,
        &-label-right-wrapper {
            margin-top: 10px;
            position: relative;
            justify-content: center;
            top: 0;
            left: 0;
        }
    }
}
</style>