<template>
  <div class="collection-topbar-harvest-meth">
    <div v-if="!isLoading" class="collection-topbar-harvest-meth-button-wrapper">
      <button
        class="collection-topbar-harvest-meth-button"
        :class="{ disabled: harvestDisabled }"
        @click="initHarvestMeth"
      >
        <span v-if="showTimer">{{ timerText }} till next Harvest</span>
        <span v-else>
          <span v-if="isHarvesting" class="collection-topbar-harvest-meth-button-loading">
            <span>Harvesting...</span>
            <img class="loader" src="../../assets/img/spinner-clear.svg" width="20">
          </span>
          <span v-else>
            <span>Harvest ALL METH</span>
            <span
              v-if="harvestableMeth"
              class="collection-topbar-harvest-meth-button-harvestable"
            >+{{ harvestableMeth }}</span>
          </span>
        </span>
      </button>
      <div class="collection-topbar-harvest-meth__info">
From Harvestable tokens to METH Bank
</div>
    </div>
    <div class="collection-topbar-harvest-meth-texts">
      <div class="collection-topbar-harvest-meth-title">
        METH BANK
        <a
          v-tooltip="{ content: 'Deposit On-Chain METH', distance: 10, }"
          href="#"
          class="collection-topbar-harvest-meth-deposit"
          @click.prevent="openDepositMeth"
        >
          <IPlus width="14" />
        </a>
        <a
          v-tooltip="{ content: 'Send METH', distance: 10, }"
          href="#"
          class="collection-topbar-harvest-meth-send"
          @click.prevent="openSendMeth"
        >
          <ISend width="12" />
        </a>
      </div>

      <div class="collection-topbar-harvest-meth-balance">
{{ formattedHarvestedBalance }} METH
</div>
    </div>
    <div v-if="!isLoading" class="collection-topbar-harvest-meth-button-wrapper">
      <button
        class="collection-topbar-harvest-meth-button"
        :class="{ disabled: claimDisabled }"
        @click.prevent="claimTeenMeth"
      >
        <span v-if="showClaimTimer">{{ claimTimerText }} till next Claim</span>
        <span v-else>Claim Harvested METH</span>
      </button>
      <div class="collection-topbar-harvest-meth__info">
        From METH Bank to on-chain wallet
</div>
    </div>
  </div>
</template>

<script>
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, ref, toRef, watch } from "vue";
import { useTimer } from "vue-timer-hook";
import { useHarvestMeth } from "@/composables/useHarvestMeth";
import IInfo from "@/assets/img/info.svg?inline"
import ISend from "@/assets/icons/send.svg?inline"
import IPlus from "@/assets/icons/plus.svg?inline"

import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import { useModal } from "@/composables/useModal";
import { ModalName } from "../Modal/modal-name";
import { claimMethBankMethOffChain } from "@/functions/claimMethBankMethOffChain";
export default {
  components: {
    IInfo,
    ISend,
    IPlus
  },
  setup() {
    const walletCollectionStore = useWalletCollectionStore()
    const methBankStore = useMethBankStore()
    const claimTeenMeth = async () => {
      await claimMethBankMethOffChain()

    }
    const { isHarvesting, harvest: harvestMeth } = useHarvestMeth()
    const initHarvestMeth = async () => {
      if (harvestDisabled.value) return;
      await harvestMeth()

    }
    const isLoading = computed(() => {
      return walletCollectionStore.isLoading
    })
    const isHarvestedToday = computed(() => methBankStore.isHarvestedToday)
    const timer = useTimer(methBankStore.nextHarvestDate, true)
    const timerText = computed(() => {
      const { hours, seconds, minutes } = timer
      return `${hours.value < 10 ? "0" + hours.value : hours.value}:${minutes.value < 10 ? "0" + minutes.value : minutes.value}`;
    })
    const showTimer = computed(() => {
      const teenWithoutHarvest = walletCollectionStore.teens?.some(teen => !teen.isBurned && !teen.isResurrected && !teen?.harvestInfo?.isHarvestedToday)
      return !timer.isExpired.value && isHarvestedToday.value && !teenWithoutHarvest
    })

    const harvestedBalance = computed(() => methBankStore.balance)
    const formattedHarvestedBalance = computed(() => formatNumberSpaces(harvestedBalance.value))
    const harvestableMeth = computed(() => {
      return methBankStore.harvestableMeth
    })
    const harvestDisabled = computed(() => {
      return (
        showTimer.value ||
        !harvestableMeth.value ||
        isHarvesting.value
      );
    })
    const claimTimer = useTimer(methBankStore.nextClaimDate, true)
    const showClaimTimer = computed(() => {
      return !claimTimer.isExpired.value && !methBankStore.canClaimNow
    })

    const claimDisabled = computed(() => {
      return methBankStore.balance <= 0 || showClaimTimer.value;
    })
    const claimTimerText = computed(() => {
      const { hours, seconds, minutes } = claimTimer
      return `${hours.value < 10 ? "0" + hours.value : hours.value}:${minutes.value < 10 ? "0" + minutes.value : minutes.value}`;
    })
    watch(toRef(methBankStore, 'nextHarvestDate'), () => {
      timer.restart(methBankStore.nextHarvestDate)
    })
    watch(toRef(methBankStore, 'nextClaimDate'), () => {
      claimTimer.restart(methBankStore.nextClaimDate)
    })

    const openSendMeth = () => {
      useModal().open({
        name: ModalName.SendMethModal,
        props: {
          onChain: false
        }
      })
    }
    const openDepositMeth = () => {
      useModal().open({
        name: ModalName.DepositMethBankModal,
      })
    }

    return { isHarvesting, openSendMeth, openDepositMeth, showClaimTimer, formattedHarvestedBalance, claimTimerText, initHarvestMeth, isLoading, isHarvestedToday, timerText, showTimer, claimDisabled, harvestedBalance, harvestDisabled, claimTeenMeth, harvestableMeth }
  },

};
</script>
