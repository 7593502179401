<template>
  <CollectionCard class="collection-card-bull">
    <CollectionCardImage
      :image="item.image"
      :claimable-meth="item.claimableMeth"
      :traits="item.traits"
      :attached-token="attachedApe"
      :attached-token-meth="apeBonusMeth"
      v-bind="$attrs"
    />
    <CollectionCardHeader
      :id="item.id"
      :guild="item.guild"
      :rank="item.rank"
      :claimable-meth="item.claimableMeth"
    />
    <div v-if="!item.isCreator" class="collection-card-breedings-left">
      Breedings Left:
      <b>{{ item.breedingsLeft }}</b>
    </div>
    <CollectionCardMethInfo
      token-name="Bull"
      :meth="methPerDay"
      :ape-bonus-meth="apeBonusMeth"
    />
    <GodBullPowerProgress
      v-if="item.isCreator"
      :days-left="powerDaysLeft"
      :recharge-per-day="powerPerDay"
      :hours-left="powerHoursLeft"
      :progress="item.creatorPower"
    />
    <!-- <CollectionCardTraits /> -->
    <CollectionCardActions
      :items="actions"
      v-bind="$attrs"
      @breed="onBreed"
      @claimMeth="onClaimMeth"
      @claimEnergyAtom="onClaimEnergyAtom"
    >
      <VMenu v-if="!item.isCreator" class="collection-card-bull-tooltip-wrapper">
        <a
          class="collection-card-action disabled"
          href="#"
          @click.prevent
        >Breed</a>
        <template #popper>
          <div class="collection-card-bull-breed-tooltip">
            All Incubators have been minted.
            <br>Please check
            <a
              href="https://opensea.io/assets/ethereum/0x2923c3e5a0f10bc02d8c90287b2af45cd579dec4/1"
              target="_blank"
            >secondary</a> if you’re looking for an incubator
          </div>
        </template>
      </VMenu>
    </CollectionCardActions>
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import CollectionCardTraits from "../CollectionCard/CollectionCardTraits.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardMethInfo from "../CollectionCard/CollectionCardMethInfo.vue";
import GodBullPowerProgress from "../GodBullPowerProgress.vue";
import { useWeb3Store } from "@/store/web3";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { breedSelection } from "@/helpers/selections/breedSelection"
import { claimMethSelectionOptions } from "@/helpers/selections/claimMethSelection"
import { breedBulls } from "@/functions/breedBulls";
import { getAttachedApe } from "@/helpers/get-attached-ape";
import { TokenType } from "@/config/token-types";
import { getApeBonus } from "@/helpers/get-ape-bonus";
import { useConfirmModal } from "@/composables/useConfirmModal";
import { claimEnergyAtom } from "@/functions/claimEnergyAtom"
import moment from "moment";
import { getMethGeneration } from "@/helpers/get-meth-generation";

export default defineComponent({
  components: { CollectionCardMethInfo, CollectionCardImage, GodBullPowerProgress, CollectionCard, CollectionCardHeader, CollectionCardTraits, CollectionCardActions },
  props: {
    item: Object,

  },
  setup(props) {
    const isLoading = ref(false)
    const collectionStore = useWalletCollectionStore()
    const actions = computed(() => {
      return [
        {
          name: "Claim Meth",
          event: "claimMeth",
          disabled: props.item.claimableMeth <= 0
        },
        ...(props.item.isCreator ? [{
          name: "Claim Energy Atom",
          event: "claimEnergyAtom",
          disabled: props.item.creatorPower < 100 || isLoading.value,
        }]: [])
      ]
    })
    const methPerDay = computed(() => {
      return getMethGeneration(props.item)
    })
    const onBreed = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, {
        filter: breedSelection,
        defaultSelection: [props.item],
        actionText: (selection) => {
          return `Bulls ${selection[0] ? selection[0].id : '?'} and ${selection[1] ? selection[1].id : '?'} are selected to breed an Incubator <br>
            This operation will cost 600 METH`
        },
        onApprove: async (bulls) => {
          await breedBulls(bulls)
        },
        canApprove: (selection) => {
          return selection.length > 1
        },
        selectLimit: 2
      })
    }
    const attachedApe = computed(() => {
      return getAttachedApe(props.item)
    })
    const onClaimMeth = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, claimMethSelectionOptions(props.item))
    }

    const onClaimEnergyAtom = async () => {   
      isLoading.value = true
      try {
        const confirmed = await useConfirmModal({
          title: "Claiming Energy Atom...",
          text: "Energy Atom will be sent to your bank and power will be reset to 0",
          confirmText: "Claim Energy Atom",
        })
        if (!confirmed) {
          isLoading.value = false
          return
        }
        await claimEnergyAtom(props.item.id)
      } catch (err) {
        console.error(err)
      }
      isLoading.value = false
    }

    const apeBonusMeth = computed(() => {
      if (!attachedApe.value) return 0
      return getApeBonus(props.item)
    })

    const powerHoursLeft = computed(() => {
      const daysToReach100 = 7
      const powerPerHour =  100 / daysToReach100 / 24;

      const fullPowerDate = moment().add(
        Math.ceil((100 - props.item.creatorPower) / powerPerHour),
        "hours"
      ).toDate();

      return Math.ceil(moment(fullPowerDate).diff(moment(), 'hours'))
    })

    const powerDaysLeft = computed(() => {
      return Math.ceil(powerHoursLeft.value / 24)
    })

    const powerPerDay = computed(() => {
      const daysToReach100 = 7
      const powerPerHour =  100 / daysToReach100 / 24;

      return (powerPerHour * 24).toFixed(2)
    })

   

  // fullPowerDate: fullPowerDate.toDate(),
    return { actions, apeBonusMeth, methPerDay, attachedApe,powerHoursLeft, powerDaysLeft, powerPerDay, onBreed, onClaimMeth, onClaimEnergyAtom }
  },
})
</script>
<style lang="scss">
.collection-card-bull {
  &-breed-tooltip {
    background-color: var(--gray);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 20;
    font-size: 14px;
    max-width: 250px;
    color: #fff;
    text-align: center;
    a {
      color: var(--primary);
    }
  }
  &-tooltip-wrapper .v-popper__inner {
    background-color: none;
    color: #fff;
    border: none;
    box-shadow: none;
  }
}
</style>