import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { signMessage } from "@/helpers/sign-message";
import { useToast } from "@/composables/useToast";

// items - [{id, amount}]
export const claimEnergyAtom = async (id) => {
  try {
    const wallet = useWeb3Store().walletAddress;

    const signature = await signMessage("I'm Claiming Energy Atom");
    if (!signature) return;

    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/wallet/claim-energy-atom",
      method: "POST",
      data: {
        wallet,
        id,     
        signature,
        network: Config.network.name,
      },
    });

    useToast().success({
      title: res.message || `You successfully claimed energy atom`,
    });
    useWalletCollectionStore().fetchCollection(true);
    return res;
  } catch (err) {
    captureError(err);
  }
};
