<template>
  <div class="harvest-dashboard">
    <div class="harvest-dashboard-header">
      <h2>Harvest Dashboard</h2>
    </div>
    <HarvestDashboardBanner />
    <HarvestDashboardContent />
    <div class="harvest-dashboard-row">
      <HarvestDashboardStats
        class="harvest-dashboard-col"
        :consecutive-days="consecutiveDays"
        :cumulative-days="cumulativeDays"
      />
      <HarvestDashboardLeaderboard
        class="harvest-dashboard-col"
        :leaderboard="leaderboard"
      />
    </div>
  </div>
</template>
<script>
import IArrowLeft from "../../../assets/icons/arrow-left-long.svg?inline";
import HarvestDashboardStats from "./HarvestDashboardStats.vue"
import HarvestDashboardLeaderboard from "./HarvestDashboardLeaderboard"
import HarvestDashboardContent from "./HarvestDashboardContent"
import HarvestDashboardBanner from "./HarvestDashboardBanner.vue";
import axios from "axios"
import { useWeb3Store } from "@/store/web3";
import { useHRApi } from "@/api/useHRApi";
// import { Config } from "../../../config/"
export default {
    components: { IArrowLeft, HarvestDashboardStats, HarvestDashboardLeaderboard, HarvestDashboardContent, HarvestDashboardBanner },
    props: {


    },
    data: () => ({
        leaderboard: {}
    }),
    computed: {
        walletAddress() {
            return useWeb3Store().collectionWallet
        },
        walletData() {
            let data = {}
            Object.keys(this.leaderboard).map(wallet => {
                if (wallet.toLowerCase() === this.walletAddress.toLowerCase()) {
                    data = this.leaderboard[wallet]
                }
            })
            return data
        },
        cumulativeDays() {
            return this.walletData.cumulative_days || 0
        },
        consecutiveDays() {
            return this.walletData.consecutive_days || 0
        },
    },
    async created() {
        await this.fetchLeaderboard()
    },
    methods: {
        async fetchLeaderboard() {
            const data = await useHRApi({ toast: { error: () => "Couldn't load leaderboard" } }).exec({
                url: '/v1/bap/harvest-leaderboard',
                params: {
                    network: 'mainnet'
                }
            })
            if (!data) return
            const leaderboard = data.rankings
            this.leaderboard = leaderboard
        }
    }
}
</script>