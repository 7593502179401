<template>
  <div class="collection-page-top-actions">
    <a
      class="collection-page-harvest-dashboard-button collection-page-top-actions-button"
      href="#"
      @click.prevent="openDashboard"
    >
      <IDashboard width="20" />Open Harvest Dashboard
    </a>
    <a
      class="collection-page-harvest-dashboard-button collection-page-top-actions-button"
      href="#"
      @click.prevent="openMethActivity"
    >METH Activity</a>
    <a
      class="collection-page-harvest-dashboard-button collection-page-top-actions-button"
      href="#"
      @click.prevent="openCommunityActivity"
    >Community Activity</a>
    <CollectionSafeClaimSwitcher />
  </div>
</template>
<script>

import { computed, defineComponent } from "vue";
import CollectionSafeClaimSwitcher from "./CollectionSafeClaimSwitcher.vue";
import IDashboard from "@/assets/icons/dashboard.svg?inline"
import { useCollectionLayout, CollectionLayouts } from "../useCollectionLayout";
import { useRouter } from "vue-router";
export default defineComponent({
  components: { CollectionSafeClaimSwitcher, IDashboard },

  setup() {



    const openMethActivity = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.methActivity)
    }
    const openDashboard = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.harvestDashboard)
    }
    const router = useRouter()
    const openCommunityActivity = () => {
      router.push({ name: "CommunityActivity" })
    }
    return { openDashboard, openCommunityActivity, openMethActivity }
  },
})
</script>