<template>
  <div
    class="open-chest-roulette"
    :class="{ run: isRunning }"
  >
    <div
      ref="rowWrapper"
      class="open-chest-row-wrapper"
    >
      <span class="open-chest-ticker" />
      <OpenChestRow
        :items="rowItems"
        :style="rowStyle"
        :highlight-win="isEnd"
        @transitionend.once="onAnimationEnd"
      />
    </div>
  </div>
</template>
<script>
import OpenChestRow from "./OpenChestRow.vue";
import sound from "../../assets/audio/spin.wav";
import utilitiesMetadata from "../../config/utilities-metadata";
export default {
  components: { OpenChestRow },
  props: {
    winningId: Number,
  },
  data() {
    return {
      state: "",
      isRunning: false,
      isEnd: false,
      spinningAudio: new Audio(sound),
      items: [
        utilitiesMetadata[20],
        utilitiesMetadata[30],
        utilitiesMetadata[40],
      ],
      rowItems: [],
    };
  },
  computed: {
    rowStyle() {
      if (!this.isRunning) return;
      const translate = -10530 + this.randomNum(-120, 120);
      return { transform: `translateX(${translate}px)` };
    },
  },
  mounted() {
    this.mixItems();
    setTimeout(() => {
      // this.initObservable()
      this.run();
    }, 2500);
  },
  methods: {
    initObservable() {
      const elements = [...document.querySelectorAll(".open-chest-card")];
      const wrapper = this.$refs.rowWrapper;
      const wrapperRect = wrapper.getBoundingClientRect();
      const wrapperOffsetLeft = wrapperRect.left;
      const width = wrapper.offsetWidth;
      const centerCoords = width / 2;
      let prevCenterIdx = 0;
      let centerIdx = 0;

      const observer = () => {
        if (!this.isRunning || this.isEnd) return;
        prevCenterIdx = centerIdx;
        centerIdx = elements.findIndex((el) => {
          const rect = el.getBoundingClientRect();
          const offsetLeft = rect.left - wrapperOffsetLeft;
          if (offsetLeft >= centerCoords) return true;
          return false;
        });
        if (prevCenterIdx !== centerIdx) {
          this.playSound();
        }
        requestAnimationFrame(observer);
      };
      requestAnimationFrame(observer);
    },
    async playSound() {
      const audio = new Audio(sound);
      try {
        await audio.play();
      } catch (err) {
        console.log('error in playing audio', err)
      }
    },
    onAnimationEnd() {
      this.isEnd = true;
      setTimeout(() => {
        this.$emit("end");
      }, 1000);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    run() {
      this.isRunning = true;
      this.initObservable();
    },
    mixItems() {
      const row = [];
      const total = 100;
      for (let i = 0; i < total; i++) {
        const radom = Math.random();
        let itemId = 20;
        if (radom < 0.1) {
          itemId = 20;
        } else if (radom < 0.25) {
          itemId = 30;        
        } else if (radom < 0.4) {
          itemId = 1109;
        } else {
          itemId = 40;
        }
        const item = { id: itemId, ...utilitiesMetadata[itemId] };
        row.push(item);
      }
      //   Wining item
      row[90] = { id: this.winningId, ...utilitiesMetadata[this.winningId] };
      this.rowItems = row;
    },
  }
};
</script>
