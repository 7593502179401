<template>
  <div class="collection-page-daily-meth">
    <div class="collection-page-daily-meth-title">
      Daily Meth Generation
    </div>

    <div class="collection-page-daily-meth-value">
      <span>{{ methGeneration }} METH/Day</span>
    </div>
  </div>
</template>
<script>
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, defineComponent } from "vue";
export default defineComponent({
  setup() {
    const methGeneration = computed(() => {
      const hasCompass = ['north', 'west', 'south', 'east']
        .map(guild => useWalletCollectionStore().genesis.findIndex(bull => bull.guild?.toLowerCase() === guild) > -1)
        .every(Boolean)
      const compassBonus = hasCompass ? 40 : 0
      return useMethBankStore().harvestableMeth + useWalletCollectionStore().dailyMethGeneration + compassBonus
    })

    return {
      methGeneration
    }
  },
})
</script>
<style lang="scss"></style>