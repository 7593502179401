<template>
  <div class="harvest-dashboard-banner">
    Don't forget to Harvest daily to earn
    <a
      :href="$router.resolve({ name: 'Achievements' }).path"
      target="_blank"
    >Achievement Flags</a>
  </div>
</template>
<script>
export default {

}
</script>