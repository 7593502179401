<template>
  <div class="open-chest-row">
    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="open-chest-card"
      :class="{
        'highlight': idx === 90 && highlightWin,
        'teen-resurrection': item.id === 30,
        'breeding-recharge': item.id === 40,
        'meth-maker': item.id === 20,
      }"
    >
      <img
        :src="item.image"
        alt="prize"
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: Array,
    highlightWin: Boolean
  },
  computed: {},
};
</script>
