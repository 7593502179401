<template>
  <div class="collection-page-actions-wrapper">
    <!-- <div class="collection-page-actions-title">Actions:</div> -->
    <div class="collection-page-actions">
      <CollectionMethGeneration />
      <div class="collection-page-sort">
        <CollectionSort :value="sort" @update:value="$emit('update:sort', $event)" />
      </div>
      <!-- <button
        class="collection-page-flip-all"
        @click.prevent="$emit('toggleFlipAll')"
      >
        {{ isFlipedAll ? "Collapse All Cards" : "Flip All Cards" }}
      </button> -->
    </div>
  </div>
</template>
<script>
import CollectionMethGeneration from "../CollectionMethGeneration.vue";
import CollectionSort from "./CollectionSort.vue";
export default {
  components: { CollectionSort, CollectionMethGeneration },
  props: {
    isFlipedAll: Boolean,
    sort: String,
  },
};
</script>
