<template>
  <div class="harvest-dashboard-leaderboard">
    <h3>Leaderboard</h3>
    <div class="harvest-dashboard-leaderboard-table-wrapper">
      <table class="harvest-dashboard-leaderboard-table">
        <thead>
          <tr class="harvest-dashboard-leaderboard-table-header">
            <td class="harvest-dashboard-leaderboard-table-col">
              Rank
            </td>
            <td class="harvest-dashboard-leaderboard-table-col">
              Wallet
            </td>
            <td
              class="harvest-dashboard-leaderboard-table-col harvest-dashboard-leaderboard-table-col-sortable"
              @click="toggleSort('cumulativeDays')"
            >
              <span>
                <ISort
                  v-if="sort === 'cumulativeDays'"
                  class="harvest-dashboard-leaderboard-table-sort"
                  width="14"
                  :class="{ 'asc': sortDirection === 'asc', 'desc': sortDirection === 'desc' }"
                />Cumulative Harvest days
              </span>
            </td>
            <td
              class="harvest-dashboard-leaderboard-table-col harvest-dashboard-leaderboard-table-col-sortable"
              @click="toggleSort('consecutiveDays')"
            >
              <span>
                <ISort
                  v-if="sort === 'consecutiveDays'"
                  class="harvest-dashboard-leaderboard-table-sort"
                  width="14"
                  :class="{ 'asc': sortDirection === 'asc', 'desc': sortDirection === 'desc' }"
                />Continuous Harvest days
              </span>
            </td>
          </tr>
        </thead>
        <tr
          v-for="(row, idx) in rows"
          :key="idx"
          class="harvest-dashboard-leaderboard-table-row"
          :class="{ user: row.isUser }"
        >
          <td class="harvest-dashboard-leaderboard-table-col harvest-dashboard-leaderboard-table-col-rank">
            {{ row.rank }}
            <span
              v-if="row.change != 0 && row.change != null"
              class="harvest-dashboard-leaderboard-table-col-change"
              :class="{ up: row.change > 0, down: row.change < 0 }"
            >
              <ITriangle width="8" />
              {{ abs(row.change) }}
            </span>
          </td>
          <td class="harvest-dashboard-leaderboard-table-col">
            {{ row.wallet }}
          </td>
          <td class="harvest-dashboard-leaderboard-table-col">
            {{ row.cumulativeDays }}
          </td>
          <td class="harvest-dashboard-leaderboard-table-col">
            {{ row.consecutiveDays }}
          </td>
        </tr>
      </table>
    </div>
    <APagination
      v-model:page="page"
      :page-size="pageSize"
      :total-items="totalItems"
    />
  </div>
</template>
<script>
import APagination from "../../APagination"

import ISort from "../../../assets/icons/sort_arrows.svg?inline"
import ITriangle from "../../../assets/icons/triangle-top.svg?inline"
import { useWeb3Store } from "@/store/web3"
export default {
    components: { APagination, ISort, ITriangle },
    props: {

        leaderboard: Object
    },
    data: () => ({

        page: 1,
        pageSize: 15,
        sort: 'cumulativeDays',
        sortDirection: 'asc'

    }),
    computed: {

        wallet() {
            return useWeb3Store().collectionWallet
        },
        totalItems() {
            return this.tableRows.length
        },
        tableRows() {
            const items = []
            const tableData = this.leaderboard
            Object.keys(tableData).forEach((wallet) => {
                const walletData = tableData[wallet]
                items.push({
                    isUser: wallet.toLowerCase() === this.wallet.toLowerCase(),
                    wallet,
                    consecutiveDays: walletData.consecutive_days,
                    cumulativeDays: walletData.cumulative_days,
                    change: walletData.change
                })
            })
            return items
        },

        rows() {

            let rows = [...this.tableRows]
            if (this.sort === 'cumulativeDays') {
                rows.sort((a, b) => {
                    return this.sortDirection === 'asc' ? b.cumulativeDays - a.cumulativeDays : a.cumulativeDays - b.cumulativeDays
                })
            }
            else if (this.sort === 'consecutiveDays') {
                rows.sort((a, b) => {
                    return this.sortDirection === 'asc' ? b.consecutiveDays - a.consecutiveDays : a.consecutiveDays - b.consecutiveDays
                })
            }

            rows = rows.map((row, idx) => ({ ...row, rank: idx + 1 }))

            const pagedRows = rows.slice(
                (this.page - 1) * this.pageSize,
                this.page * this.pageSize
            );
            if (this.page === 1 && pagedRows.findIndex(row => row.isUser) < 0) {
                const userRow = rows.find(row => row.isUser)
                if (userRow) {
                    pagedRows.push(userRow)
                }
            }
            return pagedRows

        }
    },
    methods: {
        abs(num) {
            return Math.abs(num)
        },
        toggleSort(col) {
            if (this.sort !== col) {
                this.sort = col
                this.sortDirection = 'asc'
            }
            else {
                if (this.sortDirection == 'asc') {
                    this.sortDirection = 'desc'
                } else {
                    this.sortDirection = 'asc'

                }

            }
        }
    }
}
</script>
