<template>
  <component :is="comp" :item="item" v-bind="$attrs" />
</template>
<script>
import { TokenType } from "@/config/token-types";
import { computed, defineComponent, toRefs } from "vue-demi";
import CollectionCardApeVue from "./CollectionCardApe.vue";
import CollectionCardBotbSpecialVue from "./CollectionCardBotbSpecial.vue";
import CollectionCardBreedingReplenishmentVue from "./CollectionCardBreedingReplenishment.vue";
import CollectionCardBull from "./CollectionCardBull.vue";
import CollectionCardForgeBlockVue from "./CollectionCardForgeBlock.vue";
import CollectionCardGodBullVue from "./CollectionCardGodBull.vue";
import CollectionCardIncubatorVue from "./CollectionCardIncubator.vue";
import CollectionCardMergerOrbVue from "./CollectionCardMergerOrb.vue";
import CollectionCardMethMachineVue from "./CollectionCardMethMachine.vue";
import CollectionCardPartnerVue from "./CollectionCardPartner.vue";
import CollectionCardTeenVue from "./CollectionCardTeen.vue";
import CollectionCardTeenResurrectionVue from "./CollectionCardTeenResurrection.vue";
import CollectionCardYellowIncubatorVue from "./CollectionCardYellowIncubator.vue";
import CollectionCreatorPassportVue from "./CollectionCreatorPassport.vue";

export default defineComponent({
  props: {
    item: Object,
  },
  setup(props) {
    const comp = computed(() => {
      const item = props.item;
      if (item.type === TokenType.GENESIS) {
        return CollectionCardBull;
      }
      if (item.type === TokenType.TEEN) {
        return CollectionCardTeenVue;
      }
      if (item.type == TokenType.GOD) {
        return CollectionCardGodBullVue;
      }
      if (
        item.type === TokenType.BOTBOG ||
        item.type === TokenType.BOTBEVO ||
        item.type === TokenType.BOTBBEARS ||
        item.type === TokenType.PARTNER
      ) {
        return CollectionCardPartnerVue;
      }
      if (item.type === TokenType.INCUBATOR) {
        return CollectionCardIncubatorVue;
      }
      if (item.type === TokenType.TEEN_RESURRECTION) {
        return CollectionCardTeenResurrectionVue;
      }
      if (item.type === TokenType.BREEDING_REPLENISHMENT) {
        return CollectionCardBreedingReplenishmentVue;
      }
      if (item.type === TokenType.METH_MACHINE) {
        return CollectionCardMethMachineVue;
      }
      if (item.type === TokenType.MERGER_ORB) {
        return CollectionCardMergerOrbVue;
      }
      if (item.type === TokenType.APE) {
        return CollectionCardApeVue;
      }
      if (item.type === TokenType.GOD_APE) {
        return CollectionCardApeVue;
      }
      if (item.type === TokenType.FORGE_BLOCK) {
        return CollectionCardForgeBlockVue;
      }
      if (item.type === TokenType.BOTB_SPECIAL) {
        return CollectionCardBotbSpecialVue;
      }
      if (item.type === TokenType.YELLOW_INCUBATOR) {
        return CollectionCardYellowIncubatorVue;
      }
      if (item.type === TokenType.CREATOR_PASSPORT) {
        return CollectionCreatorPassportVue;
      }
      if (item.type === TokenType.APE_TICKET) {
        return CollectionCreatorPassportVue;
      }
    });
    return { comp };
  },
});
</script>
