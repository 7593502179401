import { TokenType } from "./token-types";

// export const HarvestLimit = {
//   DEFAULT: 1,
//   [TokenType.GOD]: 1,
//   [TokenType.GENESIS]: 1,
//   [TokenType.APE]: 1,
//   [TokenType.BOTBOG]: 1,
//   [TokenType.BOTBEVO]: 1,
//   [TokenType.BOTBBEARS]: 1,
//   [TokenType.TEEN]: 1,
// };
export const HarvestLimit = {
  DEFAULT: 0,
  [TokenType.GOD]: 20,
  [TokenType.GOD_APE]: 20,
  [TokenType.GENESIS]: 10,
  [TokenType.APE]: 5,
  [TokenType.BOTBOG]: 5,
  [TokenType.BOTBEVO]: 1,
  [TokenType.BOTBBEARS]: 1,
  [TokenType.TEEN]: 1,
};
