import { useHRApi } from "@/api/useHRApi";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { getTokenContractAddress } from "@/helpers/get-token-contract-address";
import { signMessage } from "@/helpers/sign-message";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";

export const claimOffChainMeth = async (tokens) => {
  try {
    const assets = {};
    const wallet = useWeb3Store().collectionWallet;
    const signature = await signMessage(
      "I'm signing to claim METH to METH Bank"
    );
    if(!signature) return
    tokens.forEach((token) => {
      const contractAddress = getTokenContractAddress(token);
      if (!assets[contractAddress]) assets[contractAddress] = [];
      assets[contractAddress].push(token.id.toString());
    });
    const result = await useHRApi({ throw: true, toast: { error: true } }).exec(
      {
        url: "/v1/bap/claim-assets-meth",
        method: "POST",
        data: {
          signature,
          assets: JSON.stringify(assets),
          wallet_address: wallet,
          network: Config.network.name,
        },
      }
    );
    useToast().success({
      title: `Congrats! Your wallet now has +${result.meth_amount} METH`,
    });
    useMethBankStore().fetchMethBank();
    useWalletCollectionStore().fetchCollection(true);
    return result;
  } catch (err) {
    captureError(err);
  }
};
