<template>
  <div class="harvest-dashboard-stats">
    <h3>Harvest Statistics</h3>
    <div class="harvest-dashboard-stats-items">
      <div class="harvest-dashboard-stats-group">
        <div class="harvest-dashboard-stats-group-title">
          Total Statistics
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Cumulative Harvest days</span>
          <span>{{ cumulativeDays }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Current Consecutive Harvest days</span>
          <span>{{ consecutiveDays }}</span>
        </div>
        <!-- <div class="harvest-dashboard-stats-item">
                    <span>Best Consecutive Harvest days</span>
                    <span>20</span>
                </div>-->
        <div class="harvest-dashboard-stats-item">
          <span>Total Harvestable METH assets</span>
          <span>{{ totalHarvestableMethAssets }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Harvestable METH</span>
          <span>{{ totalHarvestableMeth }}</span>
        </div>
      </div>
      <div class="harvest-dashboard-stats-group">
        <div class="harvest-dashboard-stats-group-title">
          Meth Machine
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Teens in Meth Machine</span>
          <span>{{ methMakerTeens.length }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Duplicate Meth Machines</span>
          <span>{{ duplicateMethMakers }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Generate METH per day</span>
          <span>{{ methMakerGenerate }}</span>
        </div>

        <div class="harvest-dashboard-stats-item">
          <span>Harvestable METH</span>
          <span>{{ methMakerHarvestable }}</span>
        </div>
      </div>
      <div class="harvest-dashboard-stats-group">
        <div class="harvest-dashboard-stats-group-title">
          Teens
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Count</span>
          <span>{{ teensCount }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Generate METH per day</span>
          <span>{{ teensGenerate }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Harvestable METH</span>
          <span>{{ teensHarvestable }}</span>
        </div>
      </div>
      <div class="harvest-dashboard-stats-group">
        <div class="harvest-dashboard-stats-group-title">
          Community Tokenization
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Count</span>
          <span>{{ partnersCount }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Generate METH per day</span>
          <span>{{ partnersGenerate }}</span>
        </div>
        <div class="harvest-dashboard-stats-item">
          <span>Harvestable METH per day</span>
          <span>{{ partnersHarvestable }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";

export default {
    props: {
        cumulativeDays: Number,
        consecutiveDays: Number
    },
    computed: {

        teensCount() {
            return useWalletCollectionStore().teens.filter(teen => !teen.isResurrected).length
        },
        uniqueMethMakers() {
            const methMakers = useWalletCollectionStore().onChainMethMachines;
            const uniqueMethMakers = methMakers.filter((item, index) => {
                return (
                    methMakers.findIndex((maker) => maker.guild === item.guild) === index
                );
            });
            return uniqueMethMakers
        },
        duplicateMethMakers() {
            return useWalletCollectionStore().onChainMethMachines.length - this.uniqueMethMakers.length
        },
        methMakerTeens() {
            let methMakerTeens = []

            this.uniqueMethMakers.forEach((methMaker) => {
                const teens = useWalletCollectionStore().teens
                    .filter((item) => {
                        return item.guild === methMaker.guild && !item.isResurrected;
                    })
                    .map((teen) => teen.id);
                methMakerTeens = methMakerTeens.concat(teens);
            });
            return methMakerTeens
        },
        teensGenerate() {
            const methMakerTeensTotal = this.methMakerTeens.length * 5;
            let levelTeensTotal = 0;
            useWalletCollectionStore().teens.forEach((teen) => {
                if (this.methMakerTeens.includes(teen.id) || teen.isResurrected) return;
                levelTeensTotal += (teen.harvestInfo && teen.harvestInfo.harvestPerDay) || 0;
            });
            return methMakerTeensTotal + levelTeensTotal;
        },
        methMakerGenerate() {
            let methMakerTeens = [];
            this.uniqueMethMakers.forEach((methMaker) => {
                const teens = useWalletCollectionStore().teens
                    .filter((item) => {
                        return item.guild === methMaker.guild && !item.isResurrected;
                    })
                    .map((teen) => teen.id);
                methMakerTeens = methMakerTeens.concat(teens);
            });
            const methMakerTeensTotal = methMakerTeens.length * 5;
            return methMakerTeensTotal
        },
        teensHarvestable() {

            if (this.isHarvestedToday) return 0
            return this.teensGenerate
        },
        isHarvestedToday() {
            return useMethBankStore().isHarvestedToday
        },
        methMakerHarvestable() {
            if (this.isHarvestedToday) return 0
            return this.methMakerGenerate
        },
        teens() {
            return useWalletCollectionStore().teens
        },
        totalHarvestableMethAssets() {
            if (!this.teens) return 0
            const nonResurrectedTeens = this.teens.filter(teen => !teen.isResurrected)
            return this.uniqueMethMakers.length + nonResurrectedTeens.length
        },
        totalHarvestableMeth() {
            return this.teensHarvestable + this.partnersHarvestable
        },
        partnersCount() {
            const partners = useWalletCollectionStore().partners
            return Object.keys(partners).reduce((sum, key) => sum + partners[key].length, 0)
        },
        partnersHarvestable() {
            if (this.isHarvestedToday) return 0
            return this.partnersGenerate
        },
        partnersGenerate() {
            const { partners } = useWalletCollectionStore()
            const keys = Object.keys(partners)
            if (!keys.length) return 0
            let partnersSum = 0
            keys.forEach(partner => {
                partners[partner].forEach(item => {
                    partnersSum += item.harvestInfo.harvestPerDay || 0
                })
            })
            return partnersSum
        }
    }
}
</script>
