<template>
    <div class="collection-card-select-count">
        <div class="collection-card-select-count-title">Select Count</div>
        <ACounter v-model:value="count" :max="max" />
        <div class="collection-card-select-count-actions">
            <a
                href="javascript:void(0)"
                class="collection-card-select-count-action collection-card-select-count-action-cancel"
                @click.prevent="$emit('cancel')"
            >Cancel</a>
            <a
                href="javascript:void(0)"
                class="collection-card-select-count-action collection-card-select-count-action-success"
                :class="{ disabled: isLoading }"
                @click.prevent="$emit('approve', count)"
            >{{ approveText || 'Approve' }}</a>
        </div>
    </div>
</template>
<script setup>import { ref } from "vue-demi";
import ACounter from "@/components/ACounter.vue";

defineProps({ max: Number, isLoading: Boolean, approveText: String })
defineEmits(['cancel', 'approve'])
const count = ref(1)
</script>
<style lang="scss">
.collection-card-select-count {
    &-title {
        text-transform: uppercase;
        opacity: 0.5;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
    }
    &-action {
        flex: 1;
        margin-right: 10px;
        padding: 8px 15px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        transition: 0.2s;
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &::last-child {
            margin-right: 0;
        }
        &:hover {
            filter: brightness(0.8);
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        &-success {
            background-color: var(--green);

            &.loading {
                pointer-events: none;
                opacity: 0.5;
            }
            img {
                width: 16px;
                margin-left: 5px;
                display: block;
            }
        }
        &-cancel {
            background-color: var(--red);
        }
    }
}
</style>