<template>
  <div class="collection-boxes">
    <div class="collection-boxes-row">
      <CollectionUtilsAnalytics class="collection-boxes-item" />
      <CollectionLevelReset class="collection-boxes-item" />
      <CollectionDailyMeth class="collection-boxes-item" />
    </div>
  </div>
</template>
<script>
import CollectionUtilsAnalytics from "./CollectionUtilsAnalytics.vue"
import CollectionLevelReset from "../CollectionLevelReset.vue"
import CollectionDailyMeth from "../CollectionDailyMeth.vue";
export default {
    components: { CollectionUtilsAnalytics, CollectionLevelReset, CollectionDailyMeth }
}
</script>