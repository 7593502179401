<template>
  <div class="meth-activity">
    <div class="meth-activity-header">
      <h2>Meth Activity</h2>
    </div>
    <div class="meth-activity-content">
      <div class="meth-activity-tabs">
        <a
          v-for="t in tabs"
          :key="t.value"
          href="javascript:void(0)"
          class="meth-activity-tab"
          :class="{ active: t.value === tab }"
          @click.prevent="selectTab(t.value)"
        >{{ t.name }}</a>
      </div>

      <div class="meth-activity-date">
        <div class="meth-activity-date-title">
          Select Dates
        </div>
        <Datepicker
          v-model="date"
          class="meth-activity-date-picker"
          range
          :dark="true"
          :enable-time-picker="false"
          auto-apply
          :clearable="true"
          :disabled-dates="disabledDates"
          @update:model-value="onDateChange"
        />
      </div>
      <template v-if="!isLoading">
        <MethActivityTable
          v-if="tab == 'teens'"
          :fields="teensFields"
          :data="teensData"
          :row-class="rowClass"
        >
          <template #col-claimed="{ data: claimed }">
            <div class="meth-activity-claimed">
              <svgCheckmark
                v-if="claimed"
                class="meth-activity-claimed-success"
                width="20"
              />
              <svgCross
                v-else
                width="15"
              />
            </div>
          </template>
        </MethActivityTable>
        <MethActivityTable
          v-else-if="tab === 'botb'"
          :fields="partnersFields"
          :data="logs"
          :row-class="rowClass"
        >
          <template #col-contract_address="{ data }">
            <a
              target="_blank"
              :href="'https://etherscan.io/address/' + data"
            >{{ data }}</a>
          </template>
          <template #col-claimed="{ data: claimed }">
            <div class="meth-activity-claimed">
              <svgCheckmark
                v-if="claimed"
                class="meth-activity-claimed-success"
                width="20"
              />
              <svgCross
                v-else
                width="15"
              />
            </div>
          </template>
        </MethActivityTable>
        <MethActivityTable
          v-else-if="tab === 'partners'"
          :fields="partnersFields"
          :data="partnersData"
          :row-class="rowClass"
        >
          <template #col-contract_address="{ data }">
            <a
              target="_blank"
              :href="'https://etherscan.io/address/' + data"
            >{{ data }}</a>
          </template>
          <template #col-claimed="{ data: claimed }">
            <div class="meth-activity-claimed">
              <svgCheckmark
                v-if="claimed"
                class="meth-activity-claimed-success"
                width="20"
              />
              <svgCross
                v-else
                width="15"
              />
            </div>
          </template>
        </MethActivityTable>
        <MethActivityTable
          v-if="tab === 'rewards'"
          :fields="rewardsFields"
          :data="rewardsData"
          :row-class="rowClass"
        >
          <template #col-claimed="{ data: claimed }">
            <div class="meth-activity-claimed">
              <svgCheckmark
                v-if="claimed"
                class="meth-activity-claimed-success"
                width="20"
              />
              <svgCross
                v-else
                width="20"
              />
            </div>
          </template>
        </MethActivityTable>
        <MethActivityTable
          v-if="tab === 'apeMeth'"
          :fields="rewardsFields"
          :data="apeMethData"
          :row-class="rowClass"
        >
          <template #col-claimed="{ data: claimed }">
            <div class="meth-activity-claimed">
              <svgCheckmark
                v-if="claimed"
                class="meth-activity-claimed-success"
                width="20"
              />
              <svgCross
                v-else
                width="20"
              />
            </div>
          </template>
        </MethActivityTable>
      </template>
      <div v-if="isLoading">
        <img
          src="../../../assets/img/spinner-clear.svg"
          class="loader"
          width="100"
        >
      </div>
      <div class="meth-activity-per-page">
        <div class="meth-activity-per-page-title">
          Per Page
        </div>
        <ASelect
          v-model:value="perPage"
          :items="perPageItems"
          @update:value="fetchData(true)"
        />
      </div>
      <APagination
        class="meth-activity-pagination"
        :page="page"
        :page-size="perPage"
        :total-items="totalCount"
        @update:page="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios"
import IArrowLeft from "../../../assets/icons/arrow-left-long.svg?inline";
import MethActivityTable from "./CollectionMethActivityTable"
import APagination from "../../APagination.vue"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment"
import svgCheckmark from "../../../assets/icons/checkmark.svg?inline"
import svgCross from "../../../assets/icons/cross.svg?inline"
import ASelect from "../../ASelect.vue"
import { useWeb3Store } from "@/store/web3";
import { useHRApi } from "@/api/useHRApi";
import { captureError } from "@/helpers/captureError";
export default {
  components: {
    svgCheckmark,
    svgCross, Datepicker, APagination, IArrowLeft, MethActivityTable, ASelect
  },
  props: {

  },
  data: () => ({
    isLoading: false,
    date: null,
    logs: [],
    totalCount: 0,
    perPage: 10,
    page: 1,
    tab: 'teens',
    tabs: [
      {
        name: "Teens",
        value: 'teens'
      },
      {
        name: "BOTB",
        value: "botb"
      },
      {
        name: "Community",
        value: "partners"
      },
      {
        name: "Other",
        value: "rewards"
      },
      {
        name: "Ape",
        value: "apeMeth"
      }
    ]
  }),
  computed: {
    perPageItems() {
      return [
        {
          name: "10", value: 10
        },
        {
          name: '30',
          value: 30
        },
        {
          name: '50',
          value: 50
        },
      ]
    },
    rewardsData() {
      return this.logs
    },
    apeMethData() {
      return this.logs
    },
    rewardsFields() {
      return [
        {
          name: "Reward type",
          value: "reward_type_pretty"
        },
        {
          name: "METH amount",
          value: "meth_amount"
        },
        {
          name: "Date Granted (CST)",
          value: "date_granted_cst_pretty"
        },
        {
          name: "Date claimed (CST)",
          value: "date_claimed_cst_pretty"
        },
        {
          name: "Claimed",
          value: "claimed"
        }
      ]
    },
    partnersData() {
      return this.logs
    },
    partnersFields() {
      return [
        {
          name: "Contract address",
          value: "contract_address"
        },
        {
          name: "Token ID",
          value: "token_id"
        },
        {
          name: "METH amount",
          value: "meth_amount"
        },
        {
          name: "Date harvested (CST)",
          value: "date_harvested_cst_pretty"
        },
        {
          name: "Date claimed (CST)",
          value: "date_claimed_cst_pretty"
        },
        {
          name: "Claimed",
          value: "claimed"
        }
      ]
    },
    teensData() {
      return this.logs
    },
    teensFields() {
      return [
        {
          name: "Token ID",
          value: "token_id"
        },
        {
          name: "METH amount",
          value: "meth_amount"
        },
        {
          name: "Date harvested (CST)",
          value: "date_harvested_cst_pretty"
        },
        {
          name: "Date claimed (CST)",
          value: "date_claimed_cst_pretty"
        },
        {
          name: "Claimed",
          value: "claimed"
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    disabledDates(date) {
      return moment().diff(moment(date)) < 0
    },
    onPageChange(newPage) {
      this.page = newPage
      this.fetchData(true)
    },
    onDateChange() {
      this.fetchData(true)
      console.log(this.date)
    },
    selectTab(tab) {
      this.tab = tab
      this.page = 1
      this.fetchData()
    },
    rowClass(row) {
      return row.claimed ? 'active' : ''
    },
    async fetchData(silent) {
      if (!silent) {
        this.isLoading = true
      }
      const offset = (this.page - 1) * this.perPage
      const limit = this.perPage
      const collectionNames = {
        teens: 'teen_harvests',
        partners: 'partner_harvests',
        rewards: 'rewards',
        botb: 'botb_harvests',
        apeMeth: 'ape_meth_generation'
      }
      try {
        const collectionName = collectionNames[this.tab]
        const data = await useHRApi({ throw: true, toast: { error: true } }).exec({
          url: '/v1/bap/meth-log',
          params: {
            wallet_address: useWeb3Store().collectionWallet,
            network: 'mainnet',
            offset,
            limit,
            collection: collectionName,
            from_date_cst: this.date && moment(this.date[0]).format('YYYY-MM-DD'),
            to_date_cst: this.date && moment(this.date[1]).format('YYYY-MM-DD')
          }
        })

        const items = data[this.tab === 'apeMeth' ? 'rewards' : collectionName]
        this.logs = items
        this.totalCount = data.total_count

      } catch (err) {
        captureError(err)
      }

      this.isLoading = false
    }
  }
}
</script>