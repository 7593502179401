<template>
  <div class="open-chest-prize">
    <div class="open-chest-prize__image">
      <img
        :src="winningItem.image"
        alt="image"
      >
    </div>
    <div class="open-chest-prize__text">
      {{ winningItem.name }}
    </div>
    <div
      v-if="canOpenAgain"
      class="open-chest-prize-again"
    >
      <button
        class="open-chest-prize-again__button"
        @click.prevent.stop="$emit('reopen')"
      >
        Re-open God Box for {{ openAgainPrice }} METH
      </button>
      <div class="open-chest-prize-again__timer">
        {{ timerText }}
      </div>
      <div class="open-chest-prize-again__text">
        Otherwise you have to wait until God Power reaches 100%
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    winningItem: {
      type: Object,
      default: () => ({}),
    },
    openAgainPrice: Number,
    timer: Object,
    canOpenAgain: Boolean,
  },
  computed: {
    timerText() {
      const { sec, min } = this.timer;
      return `${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : sec}`;
    },
  },
};
</script>
