import { useWalletCollectionStore } from "@/store/wallet-collection";
import { Config } from "@/config";
import { web3 } from "@/web3";

export const getHarvestTokens = () => {
  const walletCollectionStore = useWalletCollectionStore();

  const methMakerIds = [];
  let teenIds = [];
  let allTeenIds = [];

  const methMachines = walletCollectionStore.onChainMethMachines;
  const uniquemethMachines = methMachines.filter((item, index) => {
    return (
      methMachines.findIndex((maker) => maker.guild === item.guild) === index
    );
  });

  uniquemethMachines.forEach((methMaker) => {
    const teens = walletCollectionStore.teens
      .filter((item) => {
        return item.guild === methMaker.guild && !item.isResurrected && !item.isBurned;
      })
      .map((teen) => teen.id + "");
    if (!teens.length) return;
    teenIds = teenIds.concat(teens);
    methMakerIds.push(methMaker.id + "");
  });
  allTeenIds = walletCollectionStore.teens
    .filter((teen) => !teenIds.includes(teen.id + "") && !teen.isResurrected && !teen.isBurned)
    .map((teen) => teen.id + "");
  let botbTokens = {};
  const { botbOG, botbBears, botbEVO, partners } = walletCollectionStore;
  if (botbOG.length) {
    botbTokens[Config.BOTBAddress.OG] = botbOG.map((token) => token.id + "");
  }
  if (botbBears.length) {
    botbTokens[Config.BOTBAddress.Bears] = botbBears.map(
      (token) => token.id + ""
    );
  }
  if (botbEVO.length) {
    botbTokens[Config.BOTBAddress.EVO] = botbEVO.map((token) => token.id + "");
  }

  const partnersTokens = {};
  Object.keys(partners).forEach((partnerName) => {
    partners[partnerName].forEach((partnerItem) => {
      const partnerAddress = web3.utils.toChecksumAddress(
        partnerItem.token_address
      );
      if (!partnersTokens[partnerAddress]) partnersTokens[partnerAddress] = [];
      partnersTokens[partnerAddress].push(partnerItem);
    });
  });

  return { partnersTokens, botbTokens, allTeenIds, teenIds, methMakerIds };
};
