<template>
  <div class="collection-topbar-meth">
    <div class="collection-topbar-meth-balance-wrapper">
      <!-- <div class="collection-topbar-meth-burn">METH will be burned in </div> -->
      <div class="collection-topbar-meth-title">
        Accumulated METH
      </div>
      <div class="collection-topbar-meth-balance">
        {{ formattedTotalClaimableMeth }} METH
      </div>
    </div>

    <div v-if="totalClaimableMeth > 0" class="collection-topbar-meth-claim-wrapper">

      <!-- <div>Move Meth before it's too late</div> -->
      <div class="collection-topbar-meth-timer" v-if="!isMethMoved && moveMethTimer">
        <IFire width="13" /> {{ moveMethTimer }}
        <IInfo width="12"
          v-tooltip="{ content: 'Unmoved METH aged more than 60 days will no longer be eligible to Claim once timer hits 00:00:00.', distance: 12 }" />
      </div>
      <button class="collection-topbar-meth-claim"
        :class="{ disabled: claimDisabled, hot: !isMethMoved && moveMethTimer }" @click="claimMethAll">
        <span class="collection-topbar-meth-claim-row">
          Move Meth
        </span>

      </button>


      <div class="collection-topbar-meth-claim-info">
        OG/God Bulls & Rez teens to METH Bank
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "@/composables/useToast";
import { claimAllTokensMeth } from "@/functions/claimAllTokensMeth";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import IInfo from "../../assets/img/info.svg?inline";
import IFire from "@/assets/icons/fire.svg?inline";
import { useTimer } from "vue-timer-hook";
import moment from "moment"
import { computed } from "vue";

export default {
  components: { IInfo, IFire },
  data() {
    return {};
  },
  setup() {

    const moveMethTimer = computed(() => {
      const items = [...useWalletCollectionStore().genesis]
        .concat(useWalletCollectionStore().gods)
        .concat(useWalletCollectionStore().teens.filter(teen => teen.isResurrected))
      const burnOutDay = moment().subtract(60, 'days').hours(0).minutes(0).seconds(0)
      const smallestDate = items.reduce((smallest, bull) => {
        const lastClaimed = moment(bull.lastClaimed)
        const difference = burnOutDay.diff(lastClaimed)
        if (difference > 0 && difference < burnOutDay.diff(moment(smallest))) return bull.lastClaimed
        // if (new Date(smallest).getTime() > new Date(bull.lastClaimed).getTime()) return bull.lastClaimed
        return smallest
      }, items[0].lastClaimed)
      const nextBurnDate = moment(smallestDate).tz('America/Chicago').add(60, 'days').minutes(0).seconds(0).hours(0).toDate()
      if (nextBurnDate.getTime() <= new Date().getTime()) return false
      const { hours, days, minutes, seconds, isExpired, isRunning } = useTimer(nextBurnDate, true)
      if (isExpired.value) return false
      return `${days.value}d ${hours.value < 10 ? "0" + hours.value : hours.value}:${minutes.value < 10 ? "0" + minutes.value : minutes.value}:${seconds.value < 10 ? "0" + seconds.value : seconds.value}`;

    })
    return { moveMethTimer }
  },
  computed: {
    isMethMoved() {
      return this.totalClaimableMeth <= 0
    },

    totalClaimableMeth() {
      const totalMeth = useWalletCollectionStore().totalClaimableMeth
      return totalMeth > 0 ? `+${totalMeth}` : 0;
    },
    formattedTotalClaimableMeth() {
      return formatNumberSpaces(this.totalClaimableMeth)
    },
    includeMethBank() {
      return useMethBankStore().canClaimNow
    },
    claimDisabled() {
      if (!this.totalClaimableMeth) return true
      return false
    }
  },
  methods: {
    async claimMethAll() {
      await claimAllTokensMeth()
    }
  },
};
</script>
