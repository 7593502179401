<template>
  <div
    class="open-chest-wrapper"
    :class="{ spinning: state === 1, prize: state === 2 }"
  >
    <OpenChestStart
      ref="start"
      :disabled="openDisabled"
      :timer="timerTime"
      :price="boxPrice"
      :show-timer="
        (timerTime.min > 0 || timerTime.sec > 0) && state === 0
      "
      @open="openChest"
    />
    <transition name="t-open-chest">
      <OpenChestRoulette
        v-if="state === 1"
        ref="roulette"
        :winning-id="winningId"
        @end="onEnd"
      />
    </transition>
    <transition name="t-open-chest-prize">
      <OpenChestPrize
        v-if="state === 2"
        :winning-item="winningItem"
        :can-open-again="canOpenAgain && !timerEnd"
        :open-again-price="boxPrice"
        :timer="timerTime"
        @reset="resetChest"
        @reopen="reopenChest"
      />
    </transition>
    <div
      class="open-chest-overlay"
      @click="resetChest"
    />
  </div>
</template>
<script>
import OpenChestRoulette from "./OpenChestRoulette.vue";
import OpenChestPrize from "./OpenChestPrize.vue";
import OpenChestStart from "./OpenChestStart.vue";
import utilitiesMetadata from "../../config/utilities-metadata";
import IArrowLeft from "../../assets/icons/arrow-left-long.svg?inline";
import { useWeb3Store } from "@/store/web3";
import { openGodBoxOffchain } from "@/functions/openGodBoxOffchain";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
export default {
  components: { OpenChestRoulette, OpenChestStart, OpenChestPrize, IArrowLeft },
  props: {
    token: Object,
  },
  data() {
    // const openUntil = new Date();
    // openUntil.setMinutes(openUntil.getMinutes() + 1);
    return {
      state: 0,
      winningId: 0,
      transactionPending: false,
      openUntil: null,
      timerEnd: false,
      timerTime: {
        min: 0,
        sec: 0,
      },
    };
  },
  computed: {
    currentToken() {
      return useWalletCollectionStore().gods.find(item => item.id === this.token.id)
    },
    availableMeth() {
      return useMethBankStore().balance;
    },
    canOpenAgain() {
      return this.currentToken.godBox?.canOpenAgain && this.availableMeth >= this.currentToken.godBox?.price
    },
    boxPrice() {
      return this.currentToken.godBox?.price
    },
    openDisabled() {
      return (
        this.transactionPending ||
        this.state !== 0 ||
        this.availableMeth <= this.boxPrice ||
        this.timerEnd
      );
    },
    winningItem() {
      return utilitiesMetadata[this.winningId];
    },
  },
  watch: {},
  created() {
    if (this.currentToken.godPower >= 100) {
      return;
    }
    if (this.canOpenAgain) {
      this.openUntil = this.currentToken.godBox?.openUntil;
    } else {
      this.timerEnd = true;
    }
  },
  mounted() {
    if (this.openUntil) {
      this.startOpenChestTimer();
    }
  },
  methods: {
    async openGodChest() {
      return await openGodBoxOffchain(this.currentToken)
    },
    startOpenChestTimer() {
      if (this.timer) clearInterval(this.timer);
      const nowDate = new Date();
      const second = 1000,
        minute = second * 60,
        hour = minute * 60;
      const d = this.openUntil.getTime() - nowDate.getTime();
      const m = Math.floor((d % hour) / minute);
      const s = Math.floor((d % minute) / second);
      this.timerTime = {
        min: m,
        sec: s,
      };
      this.timer = null;
      const updateTimer = () => {
        if (this.timerTime.sec <= 0) {
          if (this.timerTime.min <= 0) {
            this.onTimerEnd();
            clearInterval(this.timer);
            return;
          }
          this.timerTime.min--;
          this.timerTime.sec = 59;
        } else {
          this.timerTime.sec--;
        }
      };
      this.timer = setInterval(updateTimer, 1000);
    },
    reopenChest() {
      this.resetChest();
      this.openChest();
    },
    async openChest() {
      // this.winningId = 40;
      // this.$refs.start.play();
      // this.state = 1;
      if (this.openDisabled) return;
      this.transactionPending = true;
      const winningId = await this.openGodChest(this.currentToken);
      if (!winningId) {
        this.transactionPending = false;
        return;
      }
      this.winningId = winningId;
      this.$refs.start.play();
      this.state = 1;
      this.transactionPending = false;
    },
    resetChest() {
      if (this.state !== 2) return;
      this.state = 0;
      this.$refs.start.reset();
      this.winningId = 0;
    },
    onEnd() {
      this.state = 2;
      if (!this.canOpenAgain) {
        this.timerEnd = true;
        this.timerTime = { min: 0, sec: 0 };
      } else {
        this.timerEnd = false
        this.openUntil = new Date();
        this.openUntil.setMinutes(this.openUntil.getMinutes() + 3);
        this.startOpenChestTimer();
      }
    },
    onTimerEnd() {
      this.timerEnd = true;
    },
  },
};
</script>
