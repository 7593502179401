import { useHRApi } from "@/api/useHRApi";
import { useToast } from "@/composables/useToast";
import { TokensContracts } from "@/config/tokens-contracts";
import { captureError } from "@/helpers/captureError";
import { getTokenContractAddress } from "@/helpers/get-token-contract-address";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";
const messageToSign = "I'm attaching a BAP Ape";
const cachedSignatures = {};

export const attachApe = async ({ ape, token }) => {
  try {
    const tokenContractAddress = getTokenContractAddress(token);
    const tokenId = token.id;
    const tokenName = TokensContracts[token.type]?.name || `Token #${tokenId}`;
    const apeId = ape.id;
    const wallet = useWeb3Store().walletAddress;
    let signature = cachedSignatures[wallet];
    if (!signature) {
      try {
        signature = await web3.eth.personal.sign(messageToSign, wallet);
        cachedSignatures[wallet] = signature;
      } catch (err) {
        useToast().error({ title: "Couldn't sign the message" });
        console.error(err);
      }
    }
    if (!signature) return false;

    const res = await useHRApi({ throw: true, toast: { error: true } }).exec({
      url: "/v1/bap/attach-ape",
      method: "POST",
      data: {
        signature,
        ape_id: apeId,
        asset: {
          contract_address: web3.utils.toChecksumAddress(tokenContractAddress),
          token_id: tokenId.toString(),
        },
      },
    });
    useToast().success({
      text: `You successfully attached Ape #${ape.originalId} to Token #${tokenId}`,
      title: `Congrats! Your Ape and ${tokenName} are working together to earn you more METH!`,
    });
    useWalletCollectionStore().fetchCollection(true);
    return true;
  } catch (err) {
    captureError(err);
  }
};
