<template>
  <CollectionCard>
    <CollectionCardImage :on-chain-count="item.onChainCount" :off-chain-count="item.offChainCount" :image="item.image">
      <template #back>
        <div class="collection-card-utility-description">
          {{ item.description }}
        </div>
      </template>
      <template #default>
        <div
          v-if="isLoading"
          class="collection-card-incubator-loading"
        >
          <img
            src="@/assets/img/spinner-clear.svg"
            class="loader"
            width="80"
          >
        </div>
      </template>
    </CollectionCardImage>
    <CollectionCardHeader :name="item.name" />

    <CollectionCardActions
      :items="actions"
      @generateTeen="onGenerateTeen"
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import { TokenType } from "@/config/token-types";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWeb3Store } from "@/store/web3";
import { generateTeen } from "@/functions/generateTeen";
import { useConfirmModal } from "@/composables/useConfirmModal";

export default defineComponent({
    components: { CollectionCardActions, CollectionCardImage, CollectionCardHeader, CollectionCard },
    props: {
        item: Object,
    },
    setup(props) {
        const isLoading = ref(false)
        const isUsed = ref(false)
        const actions = computed(() => {
            return [
                // {
                //     name: "Generate Teen Bull",
                //     event: "generateTeen",
                //     disabled: useWeb3Store().meth < 600 || isLoading.value || isUsed.value
                // }
            ]
        })
        const onGenerateTeen = async () => {
            try {
                isLoading.value = true
                const confirmed = await useConfirmModal({
                    title: "Generating Teen Bull...",
                    text: "This Incubator uses 600 METH to generate a Teen Bull",
                    confirmText: "Generate Teen",
                })
                if (!confirmed) {
                    isLoading.value = false
                    return
                }
                await generateTeen()
                isUsed.value = true
            } catch (err) {
                console.log(err)
            }
            isLoading.value = false


        }
        return { TokenType, actions, onGenerateTeen, isLoading, isUsed }
    }
})
</script>
<style lang="scss">
.collection-card-incubator-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.8);
}
</style>