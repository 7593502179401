<template>
  <div
    v-if="showTimer"
    class="collection-page-level-reset"
  >
    <div class="collection-page-level-reset-title">
      Daily Level Reset
    </div>
    <template v-if="isHarvestedToday">
      <div class="collection-page-level-reset-harvested">
        <span>Already Harvested Today</span>
      </div>
    </template>
    <template v-else>
      <div
        class="collection-page-level-reset-subtitle"
      >
        Harvest your METH to not lose your progress.
        <br>
        Levels reset at 12 AM CST every day.
      </div>
      <div class="collection-page-level-reset-timer">
        {{ timerText }}
      </div>
    </template>
  </div>
</template>
<script>
import { useMethBankStore } from "@/store/meth-bank";
import { computed, defineComponent, toRef, watch } from "vue";
import { useTimer } from "vue-timer-hook"
export default defineComponent({
    setup() {
        const methBankStore = useMethBankStore()
        const isHarvestedToday = computed(() => methBankStore.isHarvestedToday)
        const timer = useTimer(methBankStore.nextHarvestDate, true)
        const timerText = computed(() => {
            const { hours, seconds, minutes } = timer
            return `${hours.value < 10 ? "0" + hours.value : hours.value}:${minutes.value < 10 ? "0" + minutes.value : minutes.value}:${seconds.value < 10 ? "0" + seconds.value : seconds.value}`;
        })
        const showTimer = computed(() => {
            return !timer.isExpired.value
        })
        return {
            isHarvestedToday, timer, timerText, showTimer
        }
    },
})
</script>