import { HarvestLimit } from "@/config/harvest-limit";
import { TokenType } from "@/config/token-types";
import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { useWalletCollectionStore } from "./wallet-collection";
import { useWeb3Store } from "./web3";

export const useHarvestLimitStore = defineStore("harvest-limit", {
  state: () => ({}),
  getters: {
    COLLECTION_SNAPSHOT_KEY() {
      return "bap-collection-snapshot-" + useWeb3Store().collectionWallet;
    },
    HARVEST_ITEMS_KEY() {
      return "bap-harvest-items-" + useWeb3Store().collectionWallet;
    },
    collectionSnapshot() {
      return useStorage(this.COLLECTION_SNAPSHOT_KEY, {}, localStorage).value;
    },
    currentCollectionSnapshot() {
      const items = useWalletCollectionStore().allTokens;

      return Object.keys(items).reduce((result, key) => {
        if (key === TokenType.PARTNER) {
          result[key] = Object.keys(items[key]).reduce(
            (partners, partnerCollection) => {
              partners[partnerCollection] = items[key][partnerCollection]
                .map((item) => ({
                  id: item.id,
                  token_address: item.token_address,
                }))
                .sort((a, b) => a.id - b.id);
              return partners;
            },
            {}
          );
        } else {
          result[key] = items[key].map((item) => item.id).sort((a, b) => a - b);
        }
        return result;
      }, {});
    },
    isSameLimits() {
      const savedTokensCount = Object.values(this.harvestItemsPopulated).reduce(
        (sum, arr) => (sum += arr.length),
        0
      );
      return savedTokensCount <= this.allowedHarvestPartnersCount;
    },
    isCollectionSame() {
      const currentSnapshot = this.currentCollectionSnapshot;
      const boolMap = Object.keys(this.collectionSnapshot).map((key) => {
        if (key === TokenType.PARTNER) {
          const boolMap = Object.keys(this.collectionSnapshot[key]).map(
            (partnerKey) => {
              const boolMap = this.collectionSnapshot[key][partnerKey].map(
                (item) => {
                  return (
                    currentSnapshot[key]?.[partnerKey]?.findIndex(
                      (p) =>
                        p.id == item.id &&
                        p.token_address === item.token_address
                    ) > -1
                  );
                }
              );
              const hasAll = !boolMap.includes(false);
              return (
                hasAll &&
                this.collectionSnapshot[key][partnerKey].length ===
                  currentSnapshot[key][partnerKey].length
              );
            }
          );
          return (
            !boolMap.includes(false) &&
            Object.keys(this.collectionSnapshot[key]).length ===
              Object.keys(currentSnapshot[key]).length
          );
        } else {
          const boolMap = this.collectionSnapshot[key].map((item) => {
            return currentSnapshot[key].includes(item);
          });
          const hasAll = !boolMap.includes(false);
          return (
            hasAll &&
            currentSnapshot[key].length === this.collectionSnapshot[key].length
          );
        }
      });

      return !boolMap.includes(false);
    },
    harvestPartnersCount() {
      return Object.values(useWalletCollectionStore().partners).reduce(
        (sum, arr) => (sum += arr.length),
        0
      );
    },
    allowedHarvestPartnersCount() {
      let canHarvestTotal = HarvestLimit.DEFAULT;
      Object.keys(HarvestLimit).forEach((tokenType) => {
        if (tokenType === "DEFAULT") return;
        canHarvestTotal +=
          useWalletCollectionStore().allTokens[tokenType].length *
          HarvestLimit[tokenType];
      });
      return canHarvestTotal;
    },
    harvestItems() {
      return useStorage(this.HARVEST_ITEMS_KEY, {}, localStorage).value;
    },
    harvestItemsPopulated() {
      const partnerTokens = useWalletCollectionStore().partnersSorted;
      try {
        return Object.keys(this.harvestItems).reduce((result, key) => {
          result[key] = this.harvestItems[key].map((item) => {
            // if it's not found in fetched collection then something wen't wrong
            if (!partnerTokens[key])
              throw new Error(`Couldn't find ${key} address in partners`);
            return partnerTokens[key].find((itm) => itm.id == item.id);
          });
          return result;
        }, {});
      } catch (err) {
        console.error(err);
        return {};
      }
    },
  },
  actions: {
    saveHarvestItems(items) {
      useStorage(this.HARVEST_ITEMS_KEY, {}, localStorage).value = items;
    },
    saveCollectionSnapshot() {
      const store = useStorage(this.COLLECTION_SNAPSHOT_KEY, {}, localStorage);

      /**
       {
           [TokenType]: [id, id]
           [TokenType.PARTNER]: {
               [TokenType]: [
                   {
                       id,
                       token_address
                   }
               ]
           }
       }
       */
      store.value = this.currentCollectionSnapshot;
    },
  },
});
