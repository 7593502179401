import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { signMessage } from "@/helpers/sign-message";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";

export const replenishBreedingOffChain = async (bull, utilityId) => {
  try {
    const wallet = useWeb3Store().collectionWallet;
    const signature = await signMessage("I'm using Breeding Replenish");
    if (!signature) return;
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/godBox/replenish-breedings",
      method: "POST",
      data: {
        wallet, // wallet using the replenishment (can be source or controller)
        tokenId: bull.id, // ID of the Bull to replenish
        utilityId, // ID of the utility to use
        signature,
        network: Config.network.name,
      },
    });
    useToast().success({
      title: "Success",
      text: "Breed Replenishment is complete. <br> Your Bull has 3 breedings now"
    })
    useWalletCollectionStore().fetchCollection(true);
    return true;
  } catch (err) {
    captureError(err);
  }
};
