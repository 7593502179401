<template>
    <CollectionCard class="collection-card-forge-block">
        <CollectionCardImage
            :image="metadata.image"
            :on-chain-count="item.onChainCount"
            :off-chain-count="item.offChainCount"
            v-bind="$attrs"
        >
            <template #back>
                <div class="collection-card-utility-description">{{ metadata.description }}</div>
            </template>
        </CollectionCardImage>

        <CollectionCardHeader :name="metadata.name" />
        <CollectionCardActions
            :items="actions"
            v-bind="$attrs"
            @send="sendItem"
            @mint="initAction('mint')"
            @moveOffChain="initAction('move-off-chain')"
            v-if="!isMinting"
        />
        <CollectionCardSelectCount
            @cancel="cancel"
            :max="actionType === 'mint' ? offChainCount : onChainCount"
            :is-loading="isLoading"
            :approve-text="actionType === 'mint' ? 'Mint' : 'Approve'"
            @approve="approveAction"
            v-if="isMinting"
        />
    </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";

import IOffChain from "@/assets/icons/off-chain.svg?inline"

import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import utilitiesMetadata from "@/config/utilities-metadata";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { mintOffChainUtilities } from "@/functions/mintOffChainUtilities";
import { moveUtilitiesOffChain } from "@/functions/moveUtilitiesOffChain";
import CollectionCardSelectCount from "../CollectionCard/CollectionCardSelectCount.vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";

export default defineComponent({
    components: { CollectionCardImage, CollectionCardSelectCount,  IOffChain,  CollectionCardActions, CollectionCard, CollectionCardHeader, },
    props: {
        item: Object,

    },
    setup(props) {
        const offChainCount = computed(() => props.item.offChainCount)
        const onChainCount = computed(() => props.item.onChainCount)

        const actions = computed(() => {
            const items = []
            if (offChainCount.value > 0) {
                items.push(
                    {
                        name: "Mint",
                        event: "mint"
                    }
                )
            }
            if (onChainCount.value > 0) {
                items.push(
                    {
                        name: "Move off-chain",
                        event: "moveOffChain"
                    }
                )
            }
            if (offChainCount.value > 0) {
                items.push({
                    name: "Transfer",
                    event: "send"
                })
            }

            return items
        })
        const sendItem = () => {
            useModal().open({
                name: ModalName.SendUtilityModal,
                props: {
                    item: props.item, maxAmount: props.item.offChainCount
                }
            })
        }
        const isLoading = ref(false)
        const metadata = computed(() => utilitiesMetadata[3])
        const actionType = ref('')
        const isMinting = computed(() => !!actionType.value)
        const initAction = async (type) => {
            actionType.value = type
        }

        const cancel = () => {
            actionType.value = ''
        }

        const approveAction = async (count) => {
            isLoading.value = true
            const data = [
                {
                    id: props.item.id,
                    amount: count
                }
            ]
            let success;
            if (actionType.value === 'mint') {
                success = await mintOffChainUtilities(data)
            } else {
                success = await moveUtilitiesOffChain(data)
            }
            isLoading.value = false
            if (success) {
                actionType.value = ''
            }
            setTimeout(() => {
                useWalletCollectionStore().fetchCollection(true)
            }, 500)



        }
        return { metadata, sendItem, isLoading, onChainCount, actionType, approveAction, cancel, actions, initAction, isMinting, offChainCount }
    },
})
</script>
<style lang="scss">
.collection-card-forge-block {
    &-count {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        background-color: var(--gray-light);
        border: 1px solid var(--border);
        min-width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        font-weight: bold;
        padding: 0 4px;
        box-sizing: border-box;
    }
    &-mint-count {
        &-title {
            text-transform: uppercase;
            opacity: 0.5;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
    &__counts {
        position: absolute;
        top: 6px;
        right: 6px;
        display: flex;
        align-items: center;
    }
    &__count {
        background-color: var(--gray-light);
        border: var(--border);
        border-radius: 5px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 3px 5px;
        text-align: center;
        min-width: 26px;
        font-size: 16px;
        font-weight: bold;
        svg {
            margin-right: 2px;
        }
        &-onchain {
            color: var(--green);
        }

        &-offchain {
            color: var(--primary);
            margin-right: 8px;
        }
    }
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
    }
    &-action {
        flex: 1;
        margin-right: 10px;
        padding: 8px 15px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        transition: 0.2s;
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &::last-child {
            margin-right: 0;
        }
        &:hover {
            filter: brightness(0.8);
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        &-success {
            background-color: var(--green);

            &.loading {
                pointer-events: none;
                opacity: 0.5;
            }
            img {
                width: 16px;
                margin-left: 5px;
                display: block;
            }
        }
        &-cancel {
            background-color: var(--red);
        }
    }
}
</style>