<template>
  <div class="open-chest-start">
    <video ref="video" class="open-chest-start-video" @click.prevent="$emit('open')">
      <source src="../../assets/video/open_god_chest.mp4">
    </video>
    <button
      class="open-chest-start-button"
      :class="{ disabled }"
      @click.prevent="$emit('open')"
    >
Open God Box
</button>
    <div class="open-chest-start-timer-wrapper">
      <div v-if="showTimer" class="open-chest-start-timer">
{{ timerText }}
</div>
      <div v-if="price" class="open-chest-start-timer-text">
Open for {{ price }} METH
</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    timer: Object,
    showTimer: Boolean,
    price: Number
  },
  data() {
    return {};
  },
  computed: {
    timerText() {
      const { sec, min } = this.timer;
      return `${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : sec}`;
    },
  },

  methods: {
    play() {
      this.$refs.video.play().catch(err => {
        console.log(err)
      });
    },
    reset() {
      this.$refs.video.load();
    },
  },
};
</script>
