<template>
  <div class="collection-resurrect">
    <CollectionResurrectPreview
      :sacrifice="sacrificeItem"
      :resurrect="resurrectItem"
      @approve="onApprove"
      @changeItem="changeItem"
      @cancel="goBack"
    />
    <div class="collection-resurrect-row">
      <div
        ref="collectionResurrectSacrifice"
        class="collection-resurrect-wallet collection-resurrect-col"
      >
        <h2>Sacrifice teen</h2>
        <CollectionResurrectList
          v-model:activeItem="sacrificeItem"
          v-model:searchId="walletTokensSearchId"
          v-model:page="walletTokensPage"

          :page-size="pageSize"
          :page="walletTokensPage"
          :total-items="walletTeens.length"
          :items="pagedWalletTeens"
        />
      </div>
      <div
        ref="collectionResurrectResurrect"
        class="collection-resurrect-burned collection-resurrect-col"
      >
        <h2>Resurrect teen</h2>

        <CollectionResurrectList
          v-model:activeItem="resurrectItem"
          v-model:page="burnedItemsPage"
          v-model:searchId="burnedSearchId"
          :total-items="totalBurnedItems"
          :items="burnedItems"
          :page-size="pageSize"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CollectionResurrectList from "./CollectionResurrectList.vue";
import CollectionResurrectPreview from "./CollectionResurrectPreview.vue";
import IArrowLeft from "../../../assets/icons/arrow-left-long.svg?inline";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { resurrectTeen } from "@/functions/resurrectTeen"
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { useBapApi } from "@/api/useBapApi";

export default {
  components: {
    CollectionResurrectList,
    CollectionResurrectPreview,
    IArrowLeft,
  },
  props: {
    utilityToken: Object,
  },
  data() {
    return {
      resurrectItem: undefined,
      sacrificeItem: undefined,
      burnedItems: [],
      totalBurnedItems: 0,
      burnedItemsPage: 1,
      pageSize: 8,
      walletTokensPage: 1,
      burnedSearchId: '',
      walletTokensSearchId: ''
    };
  },

  computed: {
    activeGuild() {
      return this.utilityToken.guild;
    },
    walletTeens() {
      return useWalletCollectionStore().teens.filter(
        (teen) => teen.guild === this.activeGuild && !teen.isResurrected
      );
    },
    pagedWalletTeens() {
      const skip = (this.walletTokensPage - 1) * this.pageSize;
      const searchTokenIds = this.walletTokensSearchId
        .split(/[,\s;]/)
        .map((item) => {
          return parseInt(item);
        })
        .filter((item) => !isNaN(item));
      const filteredTeens = this.walletTeens.filter(teen => searchTokenIds.length ? searchTokenIds.includes(teen.id) : true)
      return filteredTeens.slice(skip, this.pageSize * this.walletTokensPage)
    },
  },
  mounted() {
    this.fetchBurnedTeens()

  },

  methods: {
    async fetchBurnedTeens() {
      const skip = (this.burnedItemsPage - 1) * this.pageSize;
      const searchTokenIds = this.burnedSearchId
        .split(/[,\s;]/)
        .map((item) => {
          return parseInt(item);
        })
        .filter((item) => !isNaN(item));
      const data = await useBapApi().exec({
        url: "/collection/list",
        params: {
          type: "Burned Teen",
          sort: 'rank',
          trait: !searchTokenIds.length ? "Guild" : undefined,
          value: !searchTokenIds.length ? this.activeGuild : undefined,
          skip: skip || undefined,
          limit: this.pageSize,
          id: searchTokenIds,
        }
      })
      this.burnedItems = data.result.result
      this.totalBurnedItems = data.result.count
    },
    goBack() {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.main)
    },
    async onApprove() {
      try {
        await resurrectTeen(this.utilityToken, this.resurrectItem.id, this.sacrificeItem.id)
        useCollectionLayout().switchCollectionLayout(CollectionLayouts.main)
      } catch (err) {
        console.error("Couldn't resurrect", err)
      }
    },

    changeItem(type) {
      const el =
        type === "sacrifice"
          ? this.$refs.collectionResurrectSacrifice
          : this.$refs.collectionResurrectResurrect;
      const y = el.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },
  },
  watch: {
    burnedSearchId() {
      this.fetchBurnedTeens()
    },
    burnedItemsPage() {
      this.fetchBurnedTeens()
    }
  },
};

</script>
