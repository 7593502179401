import { TokenType } from "@/config/token-types";
import { claimOffChainMeth } from "@/functions/claimOffChainMeth";

const claimMethSelection = (token, selection) => {
  if (
    token.type !== TokenType.GENESIS &&
    token.type !== TokenType.GOD &&
    token.type !== TokenType.TEEN
  )
    return false;
  if (token.type === TokenType.TEEN && !token.isResurrected) return false;
  if (token.claimableMeth <= 0) return false;
  return true;
};

const claimMethSelectionText = (selection) => {
  return `Item${selection.length > 1 ? "s" : ""} ${selection
    .map((item) => item.id)
    .join(", ")} ${
    selection.length > 1 ? "are" : "is"
  } selected to claim ${selection.reduce(
    (sum, item) => sum + (item.claimableMeth || 0),
    0
  )} METH`;
};

export const claimMethSelectionOptions = (item) => ({
  filter: claimMethSelection,
  defaultSelection: [item],
  actionText: claimMethSelectionText,
  approveText: () => "Claim",
  canApprove: (selection) => selection.length > 0,
  onApprove: async (selection) => {
    await claimOffChainMeth(selection)
  },
});
