<template>
  <div class="harvest-dashboard-content">
    <div class="harvest-dashboard-content-items">
      <div class="harvest-dashboard-content-item">
        <CollectionLevelReset />
      </div>
      <!-- <div class="harvest-dashboard-content-item">
                <div class="harvest-dashboard-content-item-title">Cumulative Harvest Counter</div>
                <div class="harvest-dashboard-content-item-value">20</div>
            </div>
            <div class="harvest-dashboard-content-item">
                <div class="harvest-dashboard-content-item-title">Consecutive Harvest Counter</div>
                <div class="harvest-dashboard-content-item-value">10</div>
            </div> -->
    </div>
  </div>
</template>
<script>
import CollectionLevelReset from "../CollectionLevelReset.vue"
export default {
    components: { CollectionLevelReset }
}
</script>