import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { approveTeens } from "./approveTeens";
import { teenGeneratedAnimation } from "./teenGeneratedAnimation";

const {
  useToastTransaction,
} = require("@/composables/useToast/useToastTransaction");
const { useWeb3Store } = require("@/store/web3");
const { useAssetsInteractionsContract } = require("@/web3/contracts");

export const resurrectTeen = async function (
  utilityToken,
  resurrectId,
  sacrificeId
) {
  return;
  await approveTeens("resurrect the teen");
  const godBull = useWalletCollectionStore().gods.find(
    (god) => god.guild === utilityToken.guild
  );
  if (!godBull) {
    useToast().error({ title: "You don't have God Bull of the same Guild" });
    return;
  }
  const godId = godBull.id;
  const isOffChain = !!utilityToken.offChainCount;
  const walletAddress = useWeb3Store().walletAddress;
  const { signature, timeOut } = await useApesStorageApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/godBox/resurrection-signature",
    params: {
      wallet: walletAddress,
      utility: utilityToken.id, // ID of the utility to use
      godId, // ID of the god to use (can be a god owned by the source)
      sacrificed: sacrificeId, // ID of the teen to sacrifice
      resurrected: resurrectId, // ID of the teen to resurrect
      offChainUtility: isOffChain, // true - burn an utility from off-chain, false - it burn the utility on-chain
      network: Config.network.name,
    },
  });

  const contract = useAssetsInteractionsContract(true);

  const response = await useToastTransaction(
    contract.methods.teenResurrect(
      utilityToken.id,
      sacrificeId,
      resurrectId,
      timeOut,
      Number(isOffChain),
      signature
    ),
    { text: "Teen Resurrection" }
  );

  console.log(response);
  // const teenId = +response.events.TEEN_RESURRECTED.returnValues.newlyMinted;
  await teenGeneratedAnimation(
    resurrectId,
    response.transactionHash,
    "Resurrected Teen"
  );
  useWalletCollectionStore().fetchCollection(true);
  useWeb3Store().update();
};
