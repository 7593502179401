import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { signMessage } from "@/helpers/sign-message";
import { useMethBankStore } from "@/store/meth-bank";

import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";

export const openGodBoxOffchain = async (token) => {
  // opening chest
  const web3Store = useWeb3Store();
  const walletAddress = web3Store.collectionWallet;

  const signature = await signMessage("I'm opening God Boxes");
  if (!signature) return;
  try {
    const { winners } = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/godBox/offchain/open",
      method: "POST",
      data: {
        wallet: walletAddress,
        tokenId: token.id,
        amount: 1,
        signature,
        network: Config.network.name,
      },
    });

    const winningId = +winners[0];

    setTimeout(() => {
      useWalletCollectionStore().fetchCollection(true);
      useMethBankStore().fetchMethBank(true);
    }, 500);
    return winningId;
  } catch (err) {
    captureError(err);
  }
};
