<template>
  <div class="collection-layout-select">
    <h2>Select Tokens</h2>
    <CollectionTokensGroup
      v-if="items[TokenType.GOD].length"
      title="God Bulls"
      :items="items[TokenType.GOD]"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="items[TokenType.GENESIS].length"
      title="Genesis Bulls"
      :items="items[TokenType.GENESIS]"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="items[TokenType.TEEN].length"
      title="Teen Bulls"
      :items="items[TokenType.TEEN]"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="items['utilities'].length"
      title="Utilities"
      :items="items['utilities']"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="items[TokenType.BOTBOG].length"
      title="BOTB OG Bulls"
      :items="items[TokenType.BOTBOG]"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="items[TokenType.BOTBEVO].length"
      title="BOTB EVO"
      :items="items[TokenType.BOTBEVO]"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="items[TokenType.BOTBBEARS].length"
      title="BOTB Bears"
      :items="items[TokenType.BOTBBEARS]"
    >
      <template #item="{ item }">
        <CollectionCardAny
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </template>
    </CollectionTokensGroup>
    <div v-if="items[TokenType.PARTNER].length">
      <CollectionTokensGroup
        v-for="(partnerList, idx) in partnersList"
        :key="idx"
        :title="partnerList[0].name"
        :items="partnerList"
      >
        <template #item="{ item }">
          <CollectionCardAny
            :item="item"
            :is-selection-mode="true"
            :is-selected="selection.includes(item)"
            @select="selectToken(item)"
          />
        </template>
      </CollectionTokensGroup>
    </div>
  </div>
</template>
<script>import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, defineComponent, onBeforeUnmount, ref, watch } from "vue-demi";
import CollectionTokensGroup from "../CollectionTokens/CollectionTokensGroup.vue";
import CollectionCardAny from "../CollectionCards/CollectionCardAny.vue";
import { useActionBar } from "@/components/AActionBar/useActionBar";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { captureError } from "@/helpers/captureError";
import { TokenType } from "@/config/token-types";

export default defineComponent({
  components: {
    CollectionTokensGroup,
    CollectionCardAny
  },
  props: {
    filter: Function,
    defaultSelection: {
      type: Array,
      default: () => []
    },
    onSelection: Function,
    actionText: Function,
    onApprove: Function,
    onCancel: Function,
    canApprove: Function,
    selectLimit: Number,
    approveText: Function
  },
  setup(props) {
    const selection = ref(props.defaultSelection)
    const walletCollectionStore = useWalletCollectionStore()
    const partnersList = computed(() => {
      return Object.values(walletCollectionStore.partners).reduce((result, collection) => {
        const filtered = collection.filter((token) => props.filter(token, selection.value))
        if (filtered.length) {
          result.push(filtered)
        }
        return result
      }, [])

    })
    const items = computed(() => {
      const tokens = {}
      Object.keys(walletCollectionStore.allTokens).forEach(collectionName => {
        const collectionTokens = []
        const items = walletCollectionStore.allTokens[collectionName]
        if (collectionName === TokenType.PARTNER) {
          Object.keys(items).forEach(key => {
            const partnerTokens = items[key]
            partnerTokens.forEach(partnerToken => {
              const isOkay = props.filter(partnerToken, selection.value)
              if (!isOkay) return
              collectionTokens.push(partnerToken)
            })
          })
        } else {
          items.forEach(item => {
            // skip utilities
            if (item.isUtility) return
            const isOkay = props.filter(item, selection.value)
            if (!isOkay) return
            collectionTokens.push(item)
          })
        }

        tokens[collectionName] = collectionTokens
      })
      // utilities
      const utilities = []
      walletCollectionStore.utilities.forEach(utility => {
        const isOkay = props.filter(utility, selection.value)
        if (!isOkay) return
        utilities.push(utility)
      })
      tokens['utilities'] = utilities
      return tokens

    })
    const selectToken = (item) => {
      const idx = selection.value.findIndex((token) => token == item)
      if (idx < 0) {
        if (props.selectLimit === 1) {
          selection.value = []
        } else if (selection.value.length >= props.selectLimit) {
          return
        }

        selection.value.push(item)
      } else {
        selection.value.splice(idx, 1)
      }
    }
    watch(selection, () => {
      if (props.onSelection) {
        props.onSelection(selection.value)
      }
    })
    useActionBar().show({
      text: computed(() => props.actionText && props.actionText(selection.value)),
      canApprove: computed(() => props.canApprove ? props.canApprove(selection.value) : true),
      approveText: computed(() => props.approveText && props.approveText(selection.value)),
      approve: async () => {
        // approve
        useActionBar().isLoading.value = true
        try {
          await props.onApprove(selection.value)
          useCollectionLayout().switchCollectionLayout(CollectionLayouts.main)
        } catch (err) {
          captureError(err)
        }
        useActionBar().isLoading.value = false
      },

      cancel: () => {
        useCollectionLayout().switchCollectionLayout(CollectionLayouts.main)
        useActionBar().hide()
        props.onCancel && props.onCancel(selection.value)
      }
    })
    onBeforeUnmount(() => {
      useActionBar().hide()
    })
    return { selection, TokenType, partnersList, items, selectToken }
  }
})
</script>