import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useHarvestLimitStore } from "@/store/harvest-limit";

export const limitPartnerTokensHarvest = async (partnerTokens) => {
  const harvestLimitStore = useHarvestLimitStore();

  const harvestTokensCount = harvestLimitStore.harvestPartnersCount;
  const canHarvestTotal = harvestLimitStore.allowedHarvestPartnersCount;
  console.log(
    "try harvest: " + harvestTokensCount,
    "allow harvest: " + canHarvestTotal
  );
  if (harvestTokensCount <= canHarvestTotal) return partnerTokens;
  // reducing harvestable items
  let reducedItems;
  
  if (
    harvestLimitStore.isCollectionSame &&
    Object.keys(harvestLimitStore.harvestItemsPopulated).length &&
    // saved tokens count compare to allowed count (in case of change of token limit)
    harvestLimitStore.isSameLimits
  ) {
    // replacing localstorage object to collection objects
    reducedItems = harvestLimitStore.harvestItemsPopulated;
  } else {
    reducedItems = reducePartners(partnerTokens);
  }
  // ask user if it's okay
  const approvedItems = await new Promise((resolve, reject) => {
    useModal().open({
      name: ModalName.HarvestLimitModal,
      props: {
        items: reducedItems,
        onApprove: (items) => {
          resolve(items);
        },
        onCancel: () => {
          reject("Harvest canceled");
        },
      },
    });
  });
  harvestLimitStore.saveHarvestItems(approvedItems);
  return approvedItems;
};

const reducePartners = (partnerTokens) => {
  // deep clone
  const filteredPartnerTokens = Object.keys(partnerTokens).reduce(
    (obj, key) => {
      // Sort level 3 first
      obj[key] = [...partnerTokens[key]].sort(
        (a, b) => b.harvestInfo?.level - a.harvestInfo?.level
      );
      return obj;
    },
    {}
  );
  const harvestTokensCount = useHarvestLimitStore().harvestPartnersCount;
  const canHarvestTotal = useHarvestLimitStore().allowedHarvestPartnersCount;

  const unharvestableTokensCount = harvestTokensCount - canHarvestTotal;
  // Coefficients
  const ks = {};
  Object.keys(filteredPartnerTokens).forEach((partnerCollection) => {
    const collectionItems = filteredPartnerTokens[partnerCollection];
    const k = collectionItems.length / harvestTokensCount;
    ks[partnerCollection] = Math.round(unharvestableTokensCount * k);
  });
  const ksSum = Object.values(ks).reduce((sum, item) => sum + item, 0);
  // spread appears because of Math.round
  const numberSpread = ksSum - unharvestableTokensCount;
  // removing spread
  if (numberSpread !== 0) {
    let tempNumberSpread = Math.abs(numberSpread);
    const spreadSign = -numberSpread / Math.abs(numberSpread);
    Object.keys(ks).forEach((key) => {
      if (
        tempNumberSpread <= 0 ||
        ks[key] <= 0 ||
        (spreadSign > 0 && ks[key] >= filteredPartnerTokens[key].length)
      )
        return;

      ks[key] += spreadSign;
      tempNumberSpread--;
    });
  }
  const ksSum2 = Object.values(ks).reduce((sum, item) => sum + item, 0);
  console.log("spread ", numberSpread);
  console.log("k sum", ksSum2);

  Object.keys(filteredPartnerTokens).forEach((partnerCollection) => {
    const collectionItems = filteredPartnerTokens[partnerCollection];
    filteredPartnerTokens[partnerCollection] = collectionItems.slice(
      0,
      collectionItems.length - ks[partnerCollection]
    );
  });
  console.log(
    "reduced partners",
    Object.keys(filteredPartnerTokens).reduce(
      (sum, key) => sum + filteredPartnerTokens[key].length,
      0
    )
  );
  return filteredPartnerTokens;
};
