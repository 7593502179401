<template>
    <CollectionCard class="collection-card-ape">
        <CollectionCardImage
            v-bind="$attrs"
            ref="cardImage"
            :image="item.image"
            :traits="apeTraits"
            :attached-token="attachedToken"
            :attached-token-meth="apeBonusMeth"
        >
            <template #front>
                <div class="collection-card-ape-menu-trigger" @click.stop.prevent="toggleMenu">
                    <IMenu width="14" />
                </div>
            </template>
            <template #default>
                <div v-if="isPending" class="collection-card-ape-pending">
                    <ISettings width="16" />Processing metadata
                </div>

                <div
                    v-if="menuOpen"
                    class="collection-card-ape-menu"
                    @click.stop.prevent="toggleMenu"
                >
                    <a
                        :href="OSLink"
                        class="collection-card-ape-menu-item collection-card-ape-menu-opensea"
                        target="_blank"
                        @click.stop
                    >
                        <IOpenSea width="24" />
                    </a>
                    <a
                        href="javascript:void(0)"
                        class="collection-card-ape-menu-item"
                        @click.stop.prevent="openTraits"
                    >See Traits</a>
                </div>
            </template>
        </CollectionCardImage>
        <CollectionCardHeader
            :id="originalId"
            :guild="item.guild"
            :rank="!isGod ? item.rank : undefined"
        />

        <GodBullPowerProgress
            v-if="!item.isBurned"
            :days-left="powerDaysLeft"
            :recharge-per-day="powerPerDay"
            :hours-left="powerHoursLeft"
            :progress="item.power"
        />
        <CollectionCardActions
            v-if="!item.isBurned"
            :items="actions"
            v-bind="$attrs"
            @attachApe="pairApe"
            @customize="customizeApe"
            @openCommonLootbox="openCommonLootbox"
        />
    </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardMethInfo from "../CollectionCard/CollectionCardMethInfo.vue";
import { useWeb3Store } from "@/store/web3";
import GodBullPowerProgress from "../GodBullPowerProgress.vue";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { TokenType } from "@/config/token-types";
import { useRouter } from "vue-router";
import { refundApe } from "@/functions/refundApe"
import ISettings from "@/assets/icons/settings.svg?inline"
import IMenu from "@/assets/img/token-menu.svg?inline"
import IOpenSea from "@/assets/icons/opensea.svg?inline"
import { Config } from "@/config";
import { attachApe } from "@/functions/attachApe"
import { TokensContracts } from "@/config/tokens-contracts";
import { getAttachedApe } from "@/helpers/get-attached-ape";
import { getApeBonus } from "@/helpers/get-ape-bonus";
import moment from "moment"
export default defineComponent({
    components: { ISettings, IOpenSea, IMenu, GodBullPowerProgress, CollectionCardImage, CollectionCard, CollectionCardHeader, CollectionCardActions },
    props: {
        item: Object,
    },
    setup(props) {
        const collectionStore = useWalletCollectionStore()
        const router = useRouter()
        const isLoading = ref(false)
        const isPending = computed(() => props.item.pending)
        const disableCustomization = computed(() => props.item.disableCustomization)
        const isGod = computed(() => props.item.type === TokenType.GOD_APE)
        const actions = computed(() => {
            return [
                {
                    name: "Customize this Ape",
                    event: "customize",
                    disabled: isPending.value || isLoading.value || disableCustomization.value || props.item.type === TokenType.GOD_APE
                },
                {
                    name: "Open Lootbox",
                    event: "openCommonLootbox",
                    disabled: isPending.value || isLoading.value
                },
                {
                    name: attachedToken.value ? 'Change Methane Pairing' : 'Activate Methane Pairing',
                    event: "attachApe",
                    disabled: isPending.value || isLoading.value
                },

            ]
        })
        const pairApe = () => {
            useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, {
                filter: (item, selection) => {
                    if (item.type === TokenType.GOD && props.item.type === TokenType.GOD_APE) return true
                    if (item.type === TokenType.GENESIS) return true
                    if (item.type === TokenType.TEEN) {
                        if (item.hasActiveMethMachine) return false
                        return true
                    }
                    if (item.type === TokenType.PARTNER) {
                        // Exclude ERC-1155
                        if (item.token_address.toLowerCase() === '0x495f947276749Ce646f68AC8c248420045cb7b5e'.toLowerCase()) return false
                        return true
                    }
                    if ([TokenType.BOTBOG, TokenType.BOTBEVO, TokenType.BOTBBEARS].includes(item.type)) return true
                    return false
                },
                selectLimit: 1,
                approveText: () => "Attach",
                canApprove: selection => selection.length == 1,
                actionText: (selection) => {
                    const selectedItem = selection[0]
                    let message = selectedItem ? `If you proceed, Ape #${props.item.originalId} will be attached only to Token #${selectedItem?.id}` : ''
                    // if (selectedItem && getAttachedApe(selectedItem, selectedItem.type)) {
                    //     message = `Token already attached. Proceeding with de-attach it, then attach to another Ape. <br>` + message
                    // }
                    if (attachedToken.value) {
                        message = ` Ape #${props.item.originalId} is already attached to Token #${attachedToken.value.id}. <br>` + message
                    }


                    return message

                },
                onApprove: async (selection) => {
                    const item = selection[0]
                    await attachApe({ ape: props.item, token: item })
                }
            })
        }
        const customizeApe = () => {
            router.push({ name: "ApeConfigurator", params: { id: props.item.id } })
        }
        const openCommonLootbox = () => {
            router.push({ name: "LootBox", query: { ape: props.item.id } })
        }
        const requestRefundApe = async () => {
            if (isLoading.value || !isRefundable.value) return
            isLoading.value = true
            await refundApe(props.item.id)
            isLoading.value = false
        }
        const originalId = computed(() => {
            const oId = props.item.id % 10000
            if (oId === 0) return 10000
            return oId
        })

        const apeTraits = computed(() => {
            return { ...props.item.traits, 'Token ID': props.item.id, }
        })
        const menuOpen = ref(false)
        const toggleMenu = () => {
            menuOpen.value = !menuOpen.value
        }
        const OSLink = computed(() => Config.OSUrl.apes + props.item.id)
        const cardImage = ref()
        const openTraits = () => {
            cardImage.value.toggleTraits()
            menuOpen.value = false
        }
        const attachedToken = computed(() => {
            if (!props.item.attachment) return null
            const { contract_address, token_id } = props.item.attachment
            const tokenType = Object.keys(TokensContracts).find(type => TokensContracts[type].contractAddress.toLowerCase() === contract_address.toLowerCase()) || TokenType.PARTNER
            if (tokenType === TokenType.PARTNER) {
                const partners = collectionStore.allTokens[tokenType] || {}
                return Object.values(partners).reduce((res, partnerCollection) => {
                    if (res) return res
                    res = partnerCollection.find(token => contract_address.toLowerCase() === token.token_address.toLowerCase() && token.id == token_id) || null
                    return res
                }, null)

            }
            if(tokenType === TokenType.GENESIS || tokenType === TokenType.GOD) {
                return [...(collectionStore.allTokens[TokenType.GENESIS] || []), ...(collectionStore.allTokens[TokenType.GOD] || [])]?.find(token => token.id == token_id) || null

            }
            return collectionStore.allTokens[tokenType]?.find(token => token.id == token_id) || null
        })
        const isRefundable = computed(() => {
            return props.item.isRefundable && !attachedToken.value
        })
        const apeBonusMeth = computed(() => attachedToken.value ? getApeBonus(attachedToken.value) : 0)
        const powerHoursLeft = computed(() => {
            return Math.ceil(moment(props.item.powerInfo.fullPowerDate).diff(moment(), 'hours'))
        })
        const powerDaysLeft = computed(() => {
            return Math.ceil(powerHoursLeft.value / 24)
        })
        const powerPerDay = computed(() => {
            return (props.item.powerInfo.powerPerHour * 24).toFixed(2)
        })
        return { actions, isGod, isRefundable, powerPerDay, cardImage, powerHoursLeft, powerDaysLeft, apeBonusMeth, isLoading, attachedToken, apeTraits, openTraits, OSLink, requestRefundApe, toggleMenu, menuOpen, originalId, isPending, openCommonLootbox, pairApe, customizeApe }
    },
})
</script>

<style lang="scss">
.collection-card-ape {
    &-pending {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        text-transform: uppercase;
        font-weight: bold;
        svg {
            margin-right: 5px;
        }
    }
    &-menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;

        &-trigger {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        &-item {
            height: 34px;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            border: 1px solid var(--border);
            border-radius: 5px;
            background-color: var(--gray-light);
            color: #fff;
            text-decoration: none;
            transition: 0.2s;
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
            &:hover {
                filter: brightness(0.8);
            }
            &.disabled {
                color: rgba(255, 255, 255, 0.5);
                cursor: default;
                &:hover {
                    filter: none;
                }
            }
        }
        &-opensea {
            background: #2081e2;
        }
    }
}
</style>