import { useConfirmModal } from "@/composables/useConfirmModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import {  useUtilitiesInteractionsContract } from "@/web3/contracts";
import { approveTeens } from "./approveTeens";

export const generateOrb = async function (teen) {
  try {
    await useWeb3Store().fetchMergerOrbPurchased();
    if (useWeb3Store().mergerOrbsPurchasedCount >= 1490) return;
    const teensApproved = await approveTeens(
      "convert a Teen Bull into a Merger Orb"
    );
    if (!teensApproved) return;
    const contract = useUtilitiesInteractionsContract(true);
    const generateOrbResponse = await useToastTransaction(
      contract.methods.buyMergeOrb(teen.id),
      {
        text: "Generating Merger Orb",
      }
    );
    useWalletCollectionStore().fetchCollection(true);
    useWeb3Store().update();
    return generateOrbResponse;
  } catch (err) {
    captureError(err);
  }
};
