<template>
    <CollectionCard>
        <CollectionCardImage
            :image="item.image"
            :claimable-meth="item.claimableMeth"
            :guild="item.guild"
            :traits="item.traits"
            :attached-token="attachedApe"
            :attached-token-meth="apeBonusMeth"
            v-bind="$attrs"
        />
        <CollectionCardHeader
            :id="item.id"
            :guild="item.guild"
            :rank="item.rank"
            :claimable-meth="item.claimableMeth"
        />
        <GodBullPowerProgress
            class="collection-card-power-progress"
            :recharge-per-day="rechargePowerPerDay"
            :days-left="daysPowerRounded"
            :hours-left="hoursLeftRounded"
            :progress="godPower"
        />
        <CollectionCardMethInfo token-name="God Bull" :meth="methPerDay"  :ape-bonus-meth="apeBonusMeth" />

        <!-- <CollectionCardTraits /> -->
        <CollectionCardActions :items="actions" v-bind="$attrs" @claimMeth="onClaimMeth">
            <a
                href="#"
                class="collection-card-action"
                :class="{ disabled: !canOpenChest }"
                @click.prevent="openGodBox"
            >
                Open God Box
                <span
                    v-if="canOpenChestWithMeth && !openChestTimeExceed"
                    class="collection-card-god-bull-action-timer"
                >{{ timerText }}</span>
            </a>
        </CollectionCardActions>
    </CollectionCard>
</template>
<script>
import { computed, defineComponent } from "vue";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import CollectionCardTraits from "../CollectionCard/CollectionCardTraits.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import GodBullPowerProgress from "../GodBullPowerProgress.vue";
import CollectionCardMethInfo from "../CollectionCard/CollectionCardMethInfo.vue";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { useMethBankStore } from "@/store/meth-bank";
import { useWeb3Store } from "@/store/web3";
import { useTimer } from "vue-timer-hook";
import { claimMethSelectionOptions } from "@/helpers/selections/claimMethSelection";
import { getAttachedApe } from "@/helpers/get-attached-ape";
import { getApeBonus } from "@/helpers/get-ape-bonus";
import { getMethGeneration } from "@/helpers/get-meth-generation";


export default defineComponent({
    components: { GodBullPowerProgress, CollectionCardMethInfo, CollectionCardImage, CollectionCard, CollectionCardHeader, CollectionCardTraits, CollectionCardActions },

    props: {
        item: Object,
    },
    setup(props) {
        const collectionStore = useWalletCollectionStore()
        const actions = computed(() => {
            return [
                {
                    name: "Claim Meth",
                    event: "claimMeth",
                    disabled: props.item.claimableMeth <= 0
                },
            ]
        })
        const openGodBox = () => {
            if (!canOpenChest.value) return
            useCollectionLayout().switchCollectionLayout(CollectionLayouts.openGodBox, {
                token: props.item
            })
        }
        const attachedApe = computed(() => {
            return getAttachedApe(props.item)
        })
        const apeBonusMeth = computed(() => {
            if (!attachedApe.value) return 0
            return getApeBonus(props.item)
        })
        const onClaimMeth = () => {
            useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, claimMethSelectionOptions(props.item))
        }
        const methPerDay = computed(() => {
            return getMethGeneration(props.item)
        })
        const rechargePowerPerDay = computed(() => {
            return (100 / daysPower.value).toFixed(2)
        })
        const rechangePowerPerHour = computed(() => {
            return 100 / daysPower.value / 24
        })
        const hoursLeft = computed(() => {
            const hoursLeft = Math.ceil((100 - godPower.value) / rechangePowerPerHour.value)
            return hoursLeft
        })
        const hoursLeftRounded = computed(() => {
            return Math.ceil(hoursLeft.value)
        })
        const daysPower = computed(() => {
            const totalGodBulls = 500;
            const rank = props.item.rank
            const days_to_reach_100 = 15 + ((rank - 1) / (totalGodBulls - 1)) * 15
            return days_to_reach_100
        })
        const daysPowerRounded = computed(() => {
            return Math.ceil(hoursLeft.value / 24).toString()
        })
        const godPower = computed(() => {
            return props.item.godPower;
        })
        const canOpenChest = computed(() => {
            return (
                canOpenChestWithPower.value ||
                (canOpenChestWithMeth.value && !openChestTimeExceed.value)
            );
        })
        const availableMeth = computed(() => {
            return useMethBankStore().balance
        })
        const canOpenChestWithMeth = computed(() => {
            const canOpenChestWithMeth = props.item.godBox?.canOpenAgain;
            return canOpenChestWithMeth && availableMeth.value >= props.item.godBox?.price
        })
        const canOpenChestWithPower = computed(() => {
            return godPower.value >= 100 && availableMeth.value >= props.item.godBox?.price
        })
        const timer = useTimer(props.item.godBox?.openUntil, true)
        const timerText = computed(() => {
            const { seconds, minutes } = timer
            return `${minutes.value < 10 ? "0" + minutes.value : minutes.value}:${seconds.value < 10 ? "0" + seconds.value : seconds.value}`;
        })
        const openChestTimeExceed = computed(() => {
            return timer.isExpired.value
        })
        return {
            attachedApe,
            timerText,
            canOpenChestWithMeth,
            openChestTimeExceed,
            canOpenChest,
            openGodBox,
            apeBonusMeth,
            actions, methPerDay,
            daysPowerRounded,
            hoursLeftRounded,
            godPower,
            rechargePowerPerDay,
            onClaimMeth
        }
    },
})
</script>
<style lang="scss">
.collection-card-god-bull-action-timer {
    display: block;
}
</style>