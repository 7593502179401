import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useContract } from "@/composables/useContract";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";

const abi = [
  {
    inputs: [
      {
        indexed: false,
        internalType: "uint256[]",
        name: "traitsOut",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "traitsOutAmounts",
        type: "uint256[]",
      },

      {
        internalType: "uint256",
        name: "timeOut",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "mintTraits",
    stateMutability: "nonpayable",
    type: "function",
  },
];
const contractAddress =
  Config.network.name === "sepolia"
    ? "0x73fe2C8D12cb43548D25014c9C350BB91BB6985f"
    : "0xaABcD2770B7E38b4142f5aE341BB36DD94bD52a5";
const useMintTraitContract = useContract(abi, contractAddress);
export const mintVirtualTraits = async (traits) => {
  const wallet = useWeb3Store().walletAddress;
  const traitsIds = traits.map((trait) => trait.id);
  const traitsAmounts = traits.map((trait) => trait.count);
  const { signature, timeOut } = await useApesStorageApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/constructor/traits-mint",
    method: "POST",
    data: {
      wallet: wallet,
      traits: traitsIds,
      amounts: traitsAmounts,
      network: Config.network.name,
    },
  });
  const result = await useToastTransaction(
    useMintTraitContract(true).methods.mintTraits(
      traitsIds,
      traitsAmounts,
      timeOut,
      signature
    ),
    {
      text: "Minting Traits",
    }
  );
  useWeb3Store().update();
  return true;
};
