import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useWeb3Store } from "@/store/web3";
import { Config } from "@/config";
export const traitMarketplaceGetList = async () => {
  const res = await useApesStorageApi({
    throw: true,
    toast: { error: () => "Couldn't get wallet's sale orders" },
  }).exec({
    url: "/trading/list",
    params: {
      wallet: useWeb3Store().collectionWallet,
      network: Config.network.name,
    },
  });
  return res;
};
