<template>
  <div class="collection-layout-harvest-limit">
    <h2>Select Tokens</h2>
    <div
      v-for="(partnerList, idx) in partnersList"
      :key="idx"
      class="collection-tokens-group collection-layout-harvest-limit-group"
      :class="{ open: showCollection(idx) }"
    >
      <div class="collection-layout-harvest-limit-group-header">
        <div
          class="collection-layout-harvest-limit-group-title"
          @click.prevent="toggleCollection(idx)"
        >
          {{ partnerList[0].name }}
          <span>({{ selectedCollectionCount(partnerList) }}/{{ partnerList.length }})</span>

          <IArrowRight width="8" />
        </div>
        <div class="collection-layout-harvest-limit-group-actions">
          <a
            href="javascript:void(0)"
            class="collection-layout-harvest-limit-group-button collection-layout-harvest-limit-group-button-select"
            :class="{ disabled: isSelectedAllCollection(partnerList) }"
            @click.prevent="selectAllCollection(partnerList)"
          >
            <ICheckmark width="16" />Select All
          </a>
          <a
            href="javascript:void(0)"
            class="collection-layout-harvest-limit-group-button collection-layout-harvest-limit-group-button-deselect"
            :class="{ disabled: selectedCollectionCount(partnerList) == 0 }"
            @click.prevent="deselectAllCollection(partnerList)"
          >
            <ICheckmark width="16" />De-Select All
          </a>
        </div>
      </div>
      <div
        v-if="showCollection(idx)"
        class="collection-tokens-group-items"
      >
        <CollectionCardAny
          v-for="(item, index) in partnerList"
          :key="index"
          :item="item"
          :is-selection-mode="true"
          :is-selected="selection.includes(item)"
          @select="selectToken(item)"
        />
      </div>
      <!-- <div v-else>
        <slot name="empty">
          {{ emptyText }}
        </slot>
      </div>-->
    </div>
  </div>
</template>
<script>import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, defineComponent, onBeforeUnmount, ref, watch } from "vue-demi";
import CollectionTokensGroup from "../CollectionTokens/CollectionTokensGroup.vue";
import CollectionCardAny from "../CollectionCards/CollectionCardAny.vue";
import { useActionBar } from "@/components/AActionBar/useActionBar";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { captureError } from "@/helpers/captureError";
import { TokenType } from "@/config/token-types";
import { useHarvestLimitStore } from "@/store/harvest-limit";
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
import { useConfirmModal } from "@/composables/useConfirmModal";
import IArrowRight from "@/assets/icons/arrow-right.svg?inline"
import { useToast } from "@/composables/useToast";
export default defineComponent({
  components: {
    CollectionTokensGroup,
    CollectionCardAny,
    ICheckmark,
    IArrowRight
  },
  props: {
    defaultSelection: {
      type: Array,
      default: () => []
    },
    onApprove: Function,
    onCancel: Function,
    selectLimit: Number,

  },
  setup(props) {
    const selection = ref(props.defaultSelection)
    const walletCollectionStore = useWalletCollectionStore()
    const harvestLimitStore = useHarvestLimitStore()
    const partnersList = computed(() => Object.values(walletCollectionStore.partners))
    const selectToken = (item) => {
      const idx = selection.value.findIndex((token) => token == item)
      if (idx < 0) {
        if (selection.value.length >= props.selectLimit) {
          useToast().error({
            title: "Maximum assets already selected",
            text: `De-select other assets or unlock full harvesting <a href="https://www.bullsandapesproject.com/get-started#guardrails" target="_blank">here</a>.`,
          })
          return
        }
        selection.value.push(item)
      } else {
        selection.value.splice(idx, 1)
      }
    }
    const selectAllCollection = async (items) => {

      let itemsToSelect = items.filter(item => !selection.value.includes(item))
      if (itemsToSelect.length > harvestLimitStore.allowedHarvestPartnersCount) {
        itemsToSelect = items.slice(0, harvestLimitStore.allowedHarvestPartnersCount)
        selection.value = itemsToSelect
        return
      }
      const selectionPlacesLeft = harvestLimitStore.allowedHarvestPartnersCount - selection.value.length
      const itemsToRemoveCount = itemsToSelect.length - selectionPlacesLeft
      console.log('select items', itemsToSelect)
      console.log('remove items count', itemsToRemoveCount)
      console.log('places left', selectionPlacesLeft)
      if (itemsToRemoveCount > 0) {
        const confirmed = await useConfirmModal({
          title: "It may cause deselect of other tokens",
          text: "Any tokens you manually selected might be unintentionally deselected when using Select All",
          confirmText: "Continue"
        })
        if (!confirmed) return
        const currentSymbol = items[0].symbol
        // removing items
        const removeSequence = [...selection.value]
          .sort((a, b) => a.harvestInfo?.level - b.harvestInfo?.level)
          .filter(item => item.symbol !== currentSymbol)
        let itemsToRemove = removeSequence
        if (removeSequence.length > itemsToRemoveCount) {
          itemsToRemove = itemsToRemove.slice(0, itemsToRemoveCount)
        }
        selection.value = selection.value.filter(item => !itemsToRemove.includes(item))
        console.log('remove items', itemsToRemove)
      }
      selection.value = [...selection.value, ...itemsToSelect]
    }
    const deselectAllCollection = (items) => {
      selection.value = selection.value.filter(item => !items.includes(item))
    }
    useActionBar().show({
      text: computed(() => {
        const harvestableMeth = selection.value.reduce((sum, item) => sum + (item.harvestInfo.harvestPerDay || 0), 0)
        return `Selected ${selection.value.length} / ${harvestLimitStore.allowedHarvestPartnersCount}. You will harvest ${harvestableMeth} METH`
      }),
      canApprove: computed(() => selection.value.length == harvestLimitStore.allowedHarvestPartnersCount),
      approveText: computed(() => "Harvest"),
      approve: async () => {
        // approve
        useActionBar().isLoading.value = true
        try {
          await props.onApprove(selection.value)
          useCollectionLayout().switchCollectionLayout(CollectionLayouts.main)
        } catch (err) {
          captureError(err)
        }
        useActionBar().isLoading.value = false
      },

      cancel: () => {
        useCollectionLayout().switchCollectionLayout(CollectionLayouts.main)
        useActionBar().hide()
      }
    })
    onBeforeUnmount(() => {
      props.onCancel && props.onCancel(selection.value)
      useActionBar().hide()
    })
    const isSelectedAllCollection = (items) => {
      return items.filter(item => !selection.value.includes(item)).length === 0
    }
    const selectedCollectionCount = (items) => {
      return items.filter(item => selection.value.includes(item)).length
    }
    const openCollections = ref({})
    const toggleCollection = (idx) => {
      if (openCollections.value[idx] === false) {
        openCollections.value[idx] = true
      } else {
        openCollections.value[idx] = false
      }
    }
    const showCollection = (idx) => {
      return openCollections.value[idx] !== false
    }
    return { selectAllCollection, showCollection, toggleCollection, selectedCollectionCount, deselectAllCollection, isSelectedAllCollection, selection, TokenType, partnersList, selectToken }
  }
})
</script>
<style lang="scss">
.collection-layout-harvest-limit {
  h2 {
    margin: 0;
  }
  &-group {
    margin: 40px 0;
    &-title {
      display: flex;
      align-items: baseline;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      font-size: 24px;
      font-weight: bold;
      padding: 0 8px;

      span {
        font-size: 16px;
        margin: 0 8px;
        color: var(--primary);
      }
      svg {
        transition: 0.3s;
        margin-left: 10px;
        flex-shrink: 0;
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
    &-count {
      margin-top: 5px;
    }
    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    &-button {
      padding: 0 8px;
      height: 24px;
      font-size: 14px;
      border: 1px solid var(--border);
      border-radius: 5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      background-color: var(--gray-light);
      transition: 0.2s;
      &:hover {
        background-color: transparent;
      }
      &-deselect {
        margin-left: 5px;
      }

      svg {
        margin-right: 5px;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &-header {
    }
    &.open & {
      &-title {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>