<template>
  <div class="meth-activity">
    <div class="meth-activity-table-wrapper">
      <table class="meth-activity-table">
        <thead>
          <tr class="meth-activity-table-header">
            <td
              v-for="item in fields"
              :key="item.name"
              class="meth-activity-table-col"
            >
              {{ item.name }}
            </td>
          </tr>
        </thead>
        <tr
          v-for="(dataItem, idx) in data"
          :key="idx"
          class="meth-activity-table-row"
          :class="rowClass && rowClass(dataItem, idx)"
        >
          <td
            v-for="headerItem in fields"
            :key="headerItem.value"
            class="meth-activity-table-col"
          >
            <slot
              :name="'col-' + headerItem.value"
              :data="dataItem[headerItem.value]"
            >
              {{ dataItem[headerItem.value] }}
            </slot>
          </td>
        </tr>
      </table>
    </div>
    <!-- <APagination v-model:page="page" :pageSize="pageSize" :totalItems="totalItems" /> -->
  </div>
</template>
<script>
// import APagination from "../../APagination"

export default {
    // components: { APagination, ISort, ITriangle },
    props: {
        fields: {
            type: Array,
            default: () => ([])
        },
        data: {
            type: Array,
            default: () => ([])
        },
        rowClass: Function,
    },
    data: () => ({
        page: 1,
        pageSize: 15,
    }),
    computed: {
        tableHeader() {
            return this.fields
        },


    },
    methods: {

    }
}
</script>
