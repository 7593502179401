<template>
  <CollectionCard
    class="collection-card-partner"
    :class="{ 'has-attached-ape': !!attachedApe }"
  >
    <CollectionCardLevel :harvest-info="item.harvestInfo" />
    <CollectionCardImage
      :traits="item.traits"
      :image="item.image"
      :attached-token="attachedApe"
      :attached-token-meth="apeBonusMeth"
      :claimable-meth="item.claimableMeth"
      v-bind="$attrs"
    />
    <CollectionCardHeader :id="item.id_name || item.id" />
    <CollectionCardMethInfo
      :meth="item.harvestInfo.harvestPerDay"
      token-name="Token"
      :ape-bonus-meth="apeBonusMeth"
      :is-harvestable="true"
    />
    <CollectionCardActions
      :items="[]"
      v-bind="$attrs"
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent } from "vue";
import CollectionCardLevel from "../CollectionCard/CollectionCardLevel.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import { TokenType } from "@/config/token-types";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardMethInfo from "../CollectionCard/CollectionCardMethInfo.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useHarvestLimitStore } from "@/store/harvest-limit";
import { web3 } from "@/web3";
import { getAttachedApe } from "@/helpers/get-attached-ape";
import { getApeBonus } from "@/helpers/get-ape-bonus";

export default defineComponent({
  components: { CollectionCardMethInfo, CollectionCardActions, CollectionCardImage, CollectionCardLevel, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {
    const attachedApe = computed(() => {
      return getAttachedApe(props.item)
    })
    const apeBonusMeth = computed(() => {
      if (!attachedApe.value) return 0
      return getApeBonus(props.item)
    })
    return {
      apeBonusMeth,
      attachedApe,
      TokenType,
      // showSelected,
      //  isSelectedForHarvest 
    }
  }
})
</script>
<style lang="scss">
.collection-card-partner {
  &.has-attached-ape {
    .collection-card-level {
      margin-bottom: 25px;
    }
  }
}
</style>