<template>
  <div class="collection-card-level-progress-outer-wrapper">
    <div class="collection-card-level-progress-wrapper">
      <div class="collection-card-level-progress">
        <div
          class="collection-card-level-progress-active"
          :style="{ '--progress': 100 - progress + '%' }"
        />
        <div class="collection-card-level-progress-markers">
          <span
            v-for="item in 7"
            :key="item"
          />
        </div>
      </div>

      <IInfo
        width="12"
        class="collection-card-level-progress-info-icon"
      />

      <div class="collection-card-level-progress-level">
        Level {{ level + 1 }}
      </div>
    </div>
    <div class="collection-card-level-progress-tooltip">
      <b>{{ daysLeft }}</b>
      consecutive harvest
      {{ daysLeft > 1 ? "days" : "day" }} away from
      <b>Level {{ level + 1 }}</b>
    </div>
  </div>
</template>
<script>
import IInfo from "@/assets/img/info.svg?inline"
export default {
    components: {
        IInfo
    },
    props: {
        daysLeft: Number,
        level: Number
    },
    computed: {
        progress() {
            return (7 - this.daysLeft) / 7 * 100
        }
    }
}
</script>