
import { useStorageApi } from "@/api/useStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";

export const teenGeneratedAnimation = async function (
  teenId,
  tx,
  type = "Teen"
) {
  let teenMetadata = {
    image: require("@/assets/img/Bulls/bull-placeholder.gif"),
    name: `Unknown ${type} #${teenId}`,
  };
  const fetchMetadata = async (times) => {
    try {
      const apiType = type === "Resurrected Teen" ? "burnedteen" : "teens";
      const data = await useStorageApi({ throw: true }).exec({
        method: "GET",
        url: `/${apiType}/${teenId}`,
      });
      teenMetadata = data;
    } catch (err) {
      if (times > 3) {
        useToast().error({ title: "Couldn't load Teen Metadata" });
        return;
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      await fetchMetadata(times + 1);
      console.log("error loading teen metadata", times, err);
    }
  };
  await fetchMetadata(1);

  console.log(teenMetadata);
  let { image, name } = teenMetadata;
  if (type === "Resurrected Teen") {
    image = image.replace("teens", "resurrected-teens");
  }
  const metadata = {
    image,
    name,
    tx,
    type: type.toLowerCase(),
  };
  useModal().open({
    name: ModalName.TeenGeneratedModal,
    props: {
      metadata,
    },
  });
};
