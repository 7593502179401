<template>
  <div class="collection-page-sort">
    <div class="collection-page-sort-title">
      Sort By
    </div>
    <ASelect
      :value="value"
      :items="sortOptions"
      @update:value="$emit('update:value', $event)"
    />
  </div>
</template>
<script>
import ASelect from "../../ASelect.vue";
export default {
  components: { ASelect },
  props: {
    value: String,
  },
  data() {
    return {
      sortOptions: [
        {
          name: "Minted Date",
          value: "date",
        },
        {
          name: "Meth Total",
          value: "meth",
        },
        {
          name: "Rank",
          value: "rank",
        },
      ],
    };
  },
};
</script>
