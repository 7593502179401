<template>
  <CollectionCard class="collection-card-teen" :class="{ 'has-attached-ape': !!attachedApe }">
    <CollectionCardLevel v-if="!item.isResurrected && !item.hasActiveMethMachine && !item.isBurned"
      :harvest-info="item.harvestInfo" />
    <div class="collection-card-teen-level-replacer" v-else>
      <span v-if="item.isResurrected">Resurrected</span>
      <span v-else-if="item.hasActiveMethMachine">In Meth Machine</span>
      <span v-else-if="item.isBurned">Burned</span>
      </div>
    <CollectionCardImage :traits="item.traits" :image="item.image" :attached-token="attachedApe"
      :attached-token-meth="apeBonusMeth" :claimable-meth="item.claimableMeth" v-bind="$attrs">
      <div v-if="item.hasActiveMethMachine && !item.isBurned" class="collection-card-image-meth-wrapper">
        <img src="@/assets/img/utilities/meth_maker.jpg" class="collection-card-image-meth" width="30" />
        <span class="collection-card-image-meth-tooltip">Meth Machine is activated for this teen</span>
      </div>
    </CollectionCardImage>
    <CollectionCardHeader :id="item.id" :guild="item.guild" :rank="item.rank" />
    <CollectionCardMethInfo v-if="!item.isBurned" :meth="methPerDay" token-name="Teen" :ape-bonus-meth="apeBonusMeth"
      :is-harvestable="!item.isResurrected" />
    <CollectionCardActions v-if="!item.isBurned" :items="actions" v-bind="$attrs" @claimMeth="onClaimMeth"
      @createMergeOrb="createMergeOrb" @burnForGAQ="burnForGAQ" />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCardLevel from "../CollectionCard/CollectionCardLevel.vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import { TokenType } from "@/config/token-types";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardMethInfo from "../CollectionCard/CollectionCardMethInfo.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { claimMethSelectionOptions } from "@/helpers/selections/claimMethSelection";
import { useWeb3Store } from "@/store/web3";
import { generateOrb } from "@/functions/generateOrb"
import { useConfirmModal } from "@/composables/useConfirmModal";
import { getAttachedApe } from "@/helpers/get-attached-ape";
import { getApeBonus } from "@/helpers/get-ape-bonus";
import { burnTeens } from "@/functions/burnTeens";
import { getMethGeneration } from "@/helpers/get-meth-generation";

export default defineComponent({
  components: { CollectionCardMethInfo, CollectionCardActions, CollectionCardImage, CollectionCardLevel, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {

    const isLoading = ref(false)
    const actions = computed(() => {
      if (props.item.isResurrected) {
        return [
          {
            name: "Claim Meth",
            event: "claimMeth",
            disabled: props.item.claimableMeth <= 0
          }
        ]
      }
      return [

        {
          name: "Create Merger Orb",
          event: "createMergeOrb",
          disabled: useWeb3Store().meth < 2400 || useWeb3Store().mergerOrbsPurchasedCount >= 1490 || isLoading.value,
          tooltip: {
            placement: 'bottom',
            content: useWeb3Store().mergerOrbsPurchasedCount >= 1490 ? `All Merger Orbs have been minted` :
              useWeb3Store().meth < 2400 ? `You should have at least 2400 METH` : ''
          }
        },
        {
          name: "Burn For GAQ",
          event: "burnForGAQ"
        },
      ]

    })
    const onClaimMeth = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, claimMethSelectionOptions(props.item))
    }
    const createMergeOrb = async () => {
      isLoading.value = true

      try {
        const confirmed = await useConfirmModal({
          title: "Creating Merger Orb...",
          text: "This action will cost 2400 METH",
          confirmText: "Generate Merger Orb",
        })
        if (!confirmed) {
          isLoading.value = false
          return
        }
        await generateOrb(props.item)
      } catch (err) {
        console.error(err)
      }
      isLoading.value = false
    }
    const attachedApe = computed(() => {
      return getAttachedApe(props.item)
    })
    const apeBonusMeth = computed(() => {
      if (!attachedApe.value) return 0
      return getApeBonus(props.item)
    })

    const methPerDay = computed(() => {
      const item = props.item
      if (item.isResurrected) return getMethGeneration(props.item)
      if (item.hasActiveMethMachine) return 5

      return item.harvestInfo.harvestPerDay
    })
    const burnForGAQ = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, {
        filter: (token, selection) => {
          if (token.type === TokenType.TEEN && !token.isBurned && !token.isResurrected) return true;
          return false;
        },
        defaultSelection: [props.item],
        actionText: (selection) => {
          return `Teen${selection.length > 1 ? "s" : ""} ${selection
            .map((item) => '#' + item.id)
            .join(", ")} ${selection.length > 1 ? "are" : "is"} selected to burn for God Ape Quest`;
        },
        approveText: () => "Burn",
        canApprove: (selection) => selection.length > 0,
        onApprove: (selection) => {
          const toMint = [];
          selection.forEach((item) => {
            const idx = toMint.findIndex((t) => t.id === item.id);
            if (idx < 0) {
              toMint.push({ id: item.id, amount: 1 });
            } else {
              toMint[idx].amount++;
            }
          });
          return burnTeens(selection)
        },
      })

    }
    return { TokenType, burnForGAQ, actions, attachedApe, apeBonusMeth, createMergeOrb, methPerDay, onClaimMeth }
  }
})
</script>
<style lang="scss">
.collection-card-teen {
  &-level-replacer {
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--accent);

  }
  &.has-attached-ape {
    .collection-card-level {
      margin-bottom: 25px;
    }
  }
}

.collection-card-image-meth {
  width: 30px;
  border-radius: 50%;

  &-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background-color: var(--gray-light);
    padding: 5px;
    border-radius: 5px;
    width: 120px;
    font-size: 12px;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;
  }

 

  &:hover+.collection-card-image-meth-tooltip {
    opacity: 1;
  }
}
</style>