import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useContract } from "@/composables/useContract";
import { useModal } from "@/composables/useModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { ModalName } from "@/components/Modal/modal-name";
const abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "requestRefund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const useRefundApeContract = useContract(
  abi,
  // sepolia adapted
  Config.network.name === "sepolia"
    ? "0x25878A011F85d2025E8C95A87AcE3FECddE2f642"
    : "0x768fb82224B29b0aaE912E34A6474005cc152c94"
);

export const refundApe = async (apeId) => {
  try {
    // const yes = await useConfirmModal({
    //   title: `Do you want to refund this Ape?`,
    //   text: `Ape #${apeId} will be removed from your wallet and your ETH will be sent back to you`,
    //   confirmText: "Refund",
    // });
    const yes = await new Promise((resolve) => {
      useModal().open({
        name: ModalName.ApeRefundModal,
        props: {
          onConfirm: () => {
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          },
        },
      });
    });
    if (!yes) return;
    const wallet = useWeb3Store().walletAddress;
    const { signature, owner } = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/refund/request-refund",
      params: {
        sender: wallet,
        apeId,
        network: Config.network.name,
      },
    });
    await useToastTransaction(
      useRefundApeContract(true).methods.requestRefund(apeId, owner, signature),
      { text: "Refunding Ape #" + apeId }
    );
    useWeb3Store().update();
    useWalletCollectionStore().fetchCollection(true);
    return true;
  } catch (err) {
    captureError(err);
  }
};
