<template>
  <div class="collection-tokens">
    <CollectionTabs
      v-model:activeItem="activeTab"
      v-model:activePartner="activePartner"
      :partner-collections="partnerCollections"
    />
    <CollectionActions v-model:sort="sort" />
    <CollectionTokensGroup
      v-if="showTab(TokenType.GOD)"
      title="Gods"
      empty-text="No Gods"
      :items="bullsSort(godsTokens)"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab(TokenType.GENESIS)"
      title="Genesis Bulls"
      empty-text="No OG Bulls"
      :items="bullsSort(collection[TokenType.GENESIS])"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab(TokenType.TEEN)"
      title="Teen Bulls"
      empty-text="No Teen Bulls"
      :items="bullsSort(collection[TokenType.TEEN])"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab('utilities')"
      title="Utilities"
      empty-text="No Utilities"
      :items="utilitiesTokens"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab(TokenType.APE)"
      title="Apes"
      empty-text="No Apes"
      :items="apesSort(collection[TokenType.APE])"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
      <template #empty>
        No Apes
        <br />
        <router-link
          class="collection-tokens-empty-link"
          :to="{ name: 'LootBox' }"
        >Visit the Loot Box Store</router-link>
      </template>
    </CollectionTokensGroup>
    <div
      v-if="showTab(TokenType.APE) && collection[TokenType.APE].length"
      class="collection-tokens-group"
    >
      <h2 style="padding-bottom: 0;">Ape Traits</h2>
      <div>
        <a
          href="javascript:void(0)"
          class="collection-tokens-empty-link"
          @click.prevent="openApeTraits"
        >Go to my Ape Traits</a>
      </div>
    </div>
    <CollectionTokensGroup
      v-if="showTab(TokenType.BOTBOG)"
      title="BOTB OG Bulls"
      empty-text="No BOTB OG"
      :items="collection[TokenType.BOTBOG]"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab(TokenType.BOTBEVO)"
      title="BOTB EVO"
      empty-text="No BOTB EVO"
      :items="collection[TokenType.BOTBEVO]"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab(TokenType.BOTBBEARS)"
      title="BOTB Bears"
      empty-text="No BOTB Bears"
      :items="collection[TokenType.BOTBBEARS]"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>
    <CollectionTokensGroup
      v-if="showTab(TokenType.BOTB_SPECIAL)"
      title="BOTB Ext"
      empty-text="No BOTB Ext"
      :items="collection[TokenType.BOTB_SPECIAL]"
    >
      <template #item="{ item }">
        <CollectionCardAny :item="item" />
      </template>
    </CollectionTokensGroup>

    <!-- <div v-if="showTab('botb_sets')">

    </div>-->
    <CollectionTokensGroup
      v-if="showTab('botb_sets')"
      title="BOTB Sets"
      empty-text="No BOTB Sets"
      :items="collection.botbSets"
      class="collection-tokens-group-sets"
    >
      <template #item="{ item }">
        <CollectionTokensSet :item="item" />
      </template>
    </CollectionTokensGroup>
    <div v-if="showTab(TokenType.PARTNER)">
      <CollectionTokensGroup
        v-for="(partnerList, idx) in partnerTokens"
        :key="idx"
        :title="partnerList[0].name"
        :items="partnerList"
      >
        <template #item="{ item }">
          <CollectionCardAny :item="item" />
        </template>
      </CollectionTokensGroup>
    </div>
  </div>
</template>

<script>

import CollectionActions from "../CollectionActions/CollectionActions.vue";
import CollectionTabs from "../CollectionTabs.vue"

import { computed, defineComponent, ref } from "vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionTokensGroup from "./CollectionTokensGroup.vue";
import { TokenType } from "@/config/token-types";
import CollectionCardAny from "../CollectionCards/CollectionCardAny.vue";
import CollectionTokensSet from "../CollectionCards/CollectionTokensSet.vue";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";



export default defineComponent({
  components: { CollectionTokensSet, CollectionTokensGroup, CollectionCardAny, CollectionCard, CollectionActions, CollectionTabs },
  props: {

  },
  setup() {
    const activeTab = ref('all')
    const activePartner = ref('all')
    const flipedItems = ref({})
    const sort = ref('date')
    const walletCollectionStore = useWalletCollectionStore()
    const partnerCollections = computed(() => {
      return Object.keys(walletCollectionStore.partners)
    })

    const godsTokens = computed(() => {
      return [...walletCollectionStore.allTokens[TokenType.GOD_APE], ...walletCollectionStore.allTokens[TokenType.GOD]]
    })
    const bullsSort = (items) => [...items].sort((a, b) => {
      if (sort.value === "date") {
        return b.id - a.id;
      } else if (sort.value === "meth") {
        return (b.claimableMeth || 0) - (a.claimableMeth || 0);
      } else if (sort.value === "rank") {
        return (a.rank || Infinity) - (b.rank || Infinity);
      }
      return b.id - a.id;
    })
    const apesSort = (items) => [...items].sort((a, b) => {
      if (sort.value === "date") {
        return b.originalId - a.originalId;
      } else if (sort.value === "meth") {
        return (b.claimableMeth || 0) - (a.claimableMeth || 0);
      } else if (sort.value === "rank") {
        return (a.rank || Infinity) - (b.rank || Infinity);
      }
      return b.rank - a.rank;
    })

    const collection = computed(() => {
      const tokens = walletCollectionStore.allTokens
      return {
        ...tokens,
        botbSets: walletCollectionStore.botbSetsTokens,
        [TokenType.BOTBOG]: tokens[TokenType.BOTBOG].filter(item => !item.isInSet),
        [TokenType.BOTBEVO]: tokens[TokenType.BOTBEVO].filter(item => !item.isInSet),
        [TokenType.BOTBBEARS]: tokens[TokenType.BOTBBEARS].filter(item => !item.isInSet)
      }
    })
    const utilitiesTokens = computed(() => {
      return walletCollectionStore.utilities
    })
    const partnerTokens = computed(() => {

      return Object.values(walletCollectionStore.partners).filter(partnerList => {
        return partnerList[0].symbol === activePartner.value || activePartner.value === 'all'
      })
    })
    const showTab = (tab) => {
      return activeTab.value === 'all' || activeTab.value === tab
    }

    const openApeTraits = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.apeTraits)
    }
    return { TokenType, godsTokens, openApeTraits, utilitiesTokens, apesSort, bullsSort, partnerTokens, showTab, collection, flipedItems, sort, partnerCollections, activeTab, activePartner }
  },

  data() {
    return {

    };
  },

})
</script>


<style lang="scss">
.collection-tokens {
  &-group {
    &-items {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  &-empty-link {
    color: var(--primary);
    margin-top: 5px;
    display: inline-block;
  }
}
</style>
