<template>
  <div
    class="collection-resurrect-card"
    :class="{ active }"
  >
    <div class="collection-resurrect-card-image">
      <img
        :src="image"
        width="512"
        height="512"
      >
    </div>
    <div class="collection-resurrect-card-info">
      <div class="collection-resurrect-card-rank">
        RANK {{ token.rank }}
      </div>
      <div clsas="collection-resurrect-card-id">
        #{{ token.id }}
      </div>
    </div>
  </div>
</template>
<script>
import { getTeenImageStaging } from "../../../helpers/utils";
import { Config } from "../../../config";
export default {
  props: {
    token: Object,
    active: Boolean,
  },
  computed: {
    image() {
      return Config.network.name === "sepolia"
        ? getTeenImageStaging(this.token.id)
        : this.token.image;
    },
  },
};
</script>
