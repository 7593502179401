import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useContract } from "@/composables/useContract";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { captureError } from "@/helpers/captureError";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";


const abi = [
  {
    inputs: [
      { internalType: "uint256[]", name: "traitsOut", type: "uint256[]" },
      {
        internalType: "uint256[]",
        name: "traitsOutAmounts",
        type: "uint256[]",
      },
      { internalType: "uint256", name: "timeOut", type: "uint256" },
      { internalType: "bytes", name: "signature", type: "bytes" },
    ],
    name: "mintOffchain",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

// TODO add mainnet address
const contractAddress =
  Config.network.name === "sepolia"
    ? "0xf95a96f7bb15943faae78d1c4d9e2e50f89f62b7"
    : "0x2e32b5A1d10B978f285721979E31b35cb483961c";
const useBotbSpecialMintContract = useContract(abi, contractAddress);
export const botbSpecialMint = async (amount) => {
  try {
    const wallet = useWeb3Store().walletAddress;
    const itemsIds = [1];
    const amounts = [amount];
    const { signature, timeOut } = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/wallet/mint-special",
      method: "POST",
      data: {
        wallet,
        ids: itemsIds,
        amounts, // array of amounts
        network: Config.network.name,
      },
    });
    const contract = useBotbSpecialMintContract(true);
    const result = await useToastTransaction(
      contract.methods.mintOffchain(itemsIds, amounts, timeOut, signature),
      {
        text: `Minting token${itemsIds.length > 1 || amounts[0] > 1 ? "s" : ""}`,
      }
    );
    setTimeout(() => {
      useWalletCollectionStore().fetchCollection(true)
    }, 500);
    return result;
  } catch (err) {
    captureError(err);
  }
};
