<template>
  <div
    v-if="items.length"
    class="collection-tokens-group"
  >
    <slot name="title">
      <h2>{{ title }}</h2>
    </slot>
    <div class="collection-tokens-group-items">
      <slot
        v-for="(item, idx) in items"
        :key="idx"
        name="item"
        :item="item"
      />
    </div>
    <!-- <div v-else>
      <slot name="empty">
        {{ emptyText }}
      </slot>
    </div>-->
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    emptyText: String,
    items: {
      type: Array,
      default: () => []
    }
  }
})
</script>
<style lang="scss">
</style>