<template>
  <div>
    <CollectionBoxes />
    <CollectionTopActions />
    <h1>My Collection</h1>


    <div
      class
    >
      <CollectionTokens />
    </div>
    <CollectionEmpty v-if="isCollectionEmpty" />
</div>
</template>
<script>import { computed, defineComponent } from "vue-demi";
import CollectionTokens from "@/components/Collection/CollectionTokens/CollectionTokens.vue";
import CollectionBoxes from "@/components/Collection/CollectionBoxes/CollectionBoxes.vue";

import CollectionEmpty from "@/components/Collection/CollectionEmpty.vue";
import CollectionTopActions from "../CollectionTopActions/CollectionTopActions.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";
export default defineComponent({
    components: { CollectionEmpty, CollectionTopActions, CollectionTokens, CollectionBoxes },
    setup() {
        const isCollectionEmpty = computed(() => useWalletCollectionStore().isCollectionEmpty)
        return { isCollectionEmpty }
    },
})
</script>
