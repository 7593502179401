import { getHarvestTokens } from "./get-harvest-tokens";
import { limitPartnerTokensHarvest } from "./limit-partner-tokens-harvest";

export const buildTokensToHarvest = async () => {
  const tokens = getHarvestTokens();
  const partnersTokens = await limitPartnerTokensHarvest(tokens.partnersTokens);
  tokens.partnersTokens = Object.keys(partnersTokens).reduce(
    (obj, collectionName) => {
      obj[collectionName] = partnersTokens[collectionName].map(
        (item) => item.id + ""
      );
      return obj;
    },
    {}
  );
  return tokens;
};
