<template>
  <div class="collection-page">
    <div class="collection-page-banner">
      <RouterLink :to="{ name: 'GodApeQuest' }">
        <img
          src="@/assets/img/god-ape-quest-banner.jpg"
          alt="god-ape-quest"
        >
      </RouterLink>
    </div>
    <CollectionTopBar />
    <CollectionButtons @back="goBack" :collection-layout="collectionLayout" />
    <component :is="collectionLayoutComponent" v-bind="collectionLayoutProps" v-if="!isLoading" />
    <CollectionLoading v-if="isLoading" />
  </div>
</template>

<script>
import CollectionResurrectTeen from "../components/Collection/CollectionResurrect/CollectionResurrectTeen.vue";
import CollectionTopBar from "../components/Collection/CollectionTopBar.vue";
import OpenGodChest from "../components/OpenChest/OpenChest.vue";

import IDashboard from "../assets/icons/dashboard.svg?inline";
import ILock from "../assets/icons/lock.svg?inline";
import HarvestDashboard from "../components/Collection/HarvestDashboard/HarvestDashboard.vue";

import { useWeb3Store } from "@/store/web3";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, toRef, watch } from "vue-demi";
import { useMethBankStore } from "@/store/meth-bank";
import CollectionLoading from "../components/Collection/CollectionLoading.vue";
import CollectionLayoutMain from "@/components/Collection/CollectionLayout/CollectionLayoutMain.vue"
import { useCollectionLayout, CollectionLayouts } from "@/components/Collection/useCollectionLayout";
import CollectionLayoutSelectVue from "@/components/Collection/CollectionLayout/CollectionLayoutSelect.vue";
import CollectionMethActivity from "@/components/Collection/CollectionMethActivity/CollectionMethActivity.vue"
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { useToast } from "@/composables/useToast";
import { inited } from "@/web3";
import { watchOnce } from "@vueuse/shared";
import { useRouter } from "vue-router";
import CollectionApeTraitsVue from "@/components/Collection/CollectionApeTraits/CollectionApeTraits.vue";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import CollectionLayoutHarvestLimitVue from "@/components/Collection/CollectionLayout/CollectionLayoutHarvestLimit.vue";
import CollectionButtons from "@/components/Collection/CollectionButtons.vue";
export default defineComponent({
  components: {
    CollectionLayoutMain,
    ILock,
    IDashboard,
    HarvestDashboard,
    OpenGodChest,
    CollectionTopBar,
    CollectionResurrectTeen,
    CollectionLoading,
    CollectionButtons
  },
  setup(props) {
    const walletCollectionStore = useWalletCollectionStore()
    const methBankStore = useMethBankStore()
    const web3Store = useWeb3Store()

    const isLoading = computed(() => walletCollectionStore.isLoading || methBankStore.isLoading || web3Store.isWrongNetwork)
    const router = useRouter()

    const fetchData = async () => {
      if (!web3Store.isConnected) return
      walletCollectionStore.$reset()
      await Promise.all([walletCollectionStore.fetchCollection(), methBankStore.fetchMethBank()])
      web3Store.fetchMergerOrbPurchased()
    }


    usePageWithWallet({
      onInit: () => {
        if (!web3Store.isConnected) {
          router.push({ name: "Home" })
          useToast().show({ type: "info", title: "Connect the wallet" })
          return
        }
        fetchData()
      },
      onWalletChange: () => {
        fetchData()
      }
    })
    const { collectionLayout, switchCollectionLayout, collectionLayoutProps } = useCollectionLayout()
    const collectionLayoutComponent = computed(() => {
      const layouts = {
        [CollectionLayouts.openGodBox]: OpenGodChest,
        [CollectionLayouts.harvestDashboard]: HarvestDashboard,
        [CollectionLayouts.main]: CollectionLayoutMain,
        [CollectionLayouts.ressurrection]: CollectionResurrectTeen,
        [CollectionLayouts.methActivity]: CollectionMethActivity,
        [CollectionLayouts.select]: CollectionLayoutSelectVue,
        [CollectionLayouts.apeTraits]: CollectionApeTraitsVue,
        [CollectionLayouts.harvestLimit]: CollectionLayoutHarvestLimitVue,
      }
      return layouts[collectionLayout.value]
    })
    const goBack = () => {
      if (collectionLayout.value === CollectionLayouts.main) {
        router.push({ name: "Home" })
        return
      }
      switchCollectionLayout(CollectionLayouts.main)
    }
    // const toast = useToast().show({
    //   type: "error",
    //   // title: "Success",
    //   title: "Confirming Transaction",
    //   text: "Claiming Meth",
    //   tx: {
    //     hash: "0x76351ef2b0b8978257ba596d01c9bb716e84af5f8954f82fa457de4cb1e18342"
    //   },
    //   duration: -1
    // })


    watch(() => web3Store.isConnected, () => {
      if (!web3Store.isConnected) {
        router.push({ name: "Home" })
      }
    })
    onBeforeUnmount(() => {
      useModal().close()
    })

    return { goBack, CollectionLayouts, collectionLayoutProps, isLoading, collectionLayout, collectionLayoutComponent }
  },


})
</script>
