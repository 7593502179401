import { ref } from "vue-demi";
const collectionLayout = ref("main");
const collectionLayoutProps = ref({});
export const useCollectionLayout = () => {
  const switchCollectionLayout = (layout, props = {}) => {
    collectionLayout.value = layout;
    collectionLayoutProps.value = props;
  };
  return { collectionLayout, collectionLayoutProps, switchCollectionLayout };
};

export const CollectionLayouts = {
  main: "main",
  harvestDashboard: "harvestDashboard",
  ressurrection: "ressurrection",
  openGodBox: "openGodBox",
  select: "select",
  methActivity: "methActivity",
  apeTraits: "apeTraits",
  harvestLimit: 'harvestLimit',
};
