import { useWalletCollectionStore } from "@/store/wallet-collection";
import { claimOffChainMeth } from "./claimOffChainMeth";

export const claimAllTokensMeth = async () => {
  const { genesis, teens, gods } = useWalletCollectionStore();
  return claimOffChainMeth([
    ...gods.filter((item) => item.claimableMeth > 0),
    ...genesis.filter((item) => item.claimableMeth > 0),
    ...teens.filter((teen) => teen.isResurrected && teen.claimableMeth > 0),
  ]);
};
