<template>
  <div class="collection-card">
    <slot />
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    setup() {

    }
})
</script>
<style lang="scss">
.collection-card {
    width: 16rem;
    // width: calc(100% / 4 - 3rem);
    margin: 2rem 1rem;
    border-radius: 10px;
    transition: 0.2s;
    position: relative;
    &:hover {
        // box-shadow: 0 0 10px 2px rgb(225, 109, 45);
    }

    &.highlighted {
        border-color: rgb(140, 200, 90) !important;
        box-shadow: 0 0 10px var(--primary);
    }
    &-breedings-left {
        text-align: left;
        font-size: 14px;
        b {
            color: var(--primary);
            font-weight: bold;
        }
    }
    &-utility-description {
        padding: 15px;
    }

    &-meth-info {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        margin-top: 5px;
        min-height: 28px;
        b {
            color: var(--primary);
            font-weight: bold;
        }
        &-text {
          display: flex;
          align-items: center;
          text-align: left;
        }
        &-tooltip {
            color: red !important;
        }
        svg {
            margin-left: 4px;
            transform: translateY(2px);
            opacity: 0.5;
        }
        img {
            width: 20px !important;
            flex-shrink: 0;
            margin-right: 3px;
        }
    }

    &-utility-info {
        font-size: 14px;
        padding-top: 4px;
        display: flex;
        align-items: center;

        span:first-child {
            color: rgba(255, 255, 255, 0.8);
            margin-right: 5px;
        }
        span:last-child {
            color: var(--primary);
            font-weight: bold;
        }
    }
    &-utility-meth-info {
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 14px;
        padding: 5px 0;
        b {
            font-weight: bold;
            color: var(--primary);
        }
        img {
            flex-shrink: 0;
            width: 20px !important;
            margin-right: 5px;
        }
    }
    &-utility-meth-maker-alert {
        font-size: 14px;
        padding: 5px 0;

        &-icon {
            display: flex;
            justify-content: center;
            margin-bottom: -15px;
            position: relative;
            z-index: 2;
            svg {
                background-color: black;
                padding: 5px;
            }
        }
        span {
            display: inline-block;
            padding: 8px;
            padding-top: 12px;
            border: 1px solid;
            border-radius: 5px;
        }
        &.error {
            color: #ff0000;

            span {
                border-color: rgba(255, 0, 0, 0.5);
            }
        }
        &.info {
            color: #98d8ff;

            span {
                border-color: rgba(152, 216, 255, 0.5);
            }
        }
    }

    &-god-bull-power {
        margin: 0 15px;
        margin-top: 10px;
    }
}
</style>